// eslint-disable-next-line
import React, { useEffect, useState, memo, useRef, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as THREE from 'three';
import { ClipLoader } from 'react-spinners';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { ReactComponent as Delete } from '../assets/Close Icon.svg';

import { ReactComponent as Smooth } from '../assets/new icons/border-corner-pill.svg';
import { ReactComponent as Brush } from '../assets/new icons/brush.svg';
import { ReactComponent as Margin } from '../assets/new icons/vector-spline.svg';
import { ReactComponent as Deformation } from '../assets/new icons/blob.svg';
import { ReactComponent as Thickness } from '../assets/new icons/arrow-autofit-width.svg';

import { ReactComponent as PrepIcon } from '../assets/new icons/letter-p-small.svg';
import { ReactComponent as AntaIcon } from '../assets/new icons/letter-a-small.svg';
import { ReactComponent as Eye } from '../assets/new icons/eye.svg'
import { ReactComponent as EyeOff } from '../assets/new icons/eye-off.svg';
import { ReactComponent as Undo } from '../assets/new icons/undo.svg';
import { ReactComponent as Redo } from '../assets/new icons/redo.svg';
import { ReactComponent as Info } from '../assets/new icons/file-invoice.svg';
import { ReactComponent as Left } from '../assets/new icons/arrow-left.svg';
import { ReactComponent as Right } from '../assets/new icons/arrow-right.svg';




import ThreeJSManager from "./Crown Component/ThreeD"
import KdTree from 'static-kdtree'; // Import the KD-tree library

import { STLExporter } from 'three/examples/jsm/exporters/STLExporter';
import * as U from "./Crown Component/utils"
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader';
import JSZip, { folder } from 'jszip'; 



const Crown = () => {
  const location = useLocation();
  const { file1, file2, crown,marginCurve,uuid_crown,crownFirst, marginCenter,allowed_points ,thickness, position, axis, prepView, antaView, inner_surface, thickness_allowed_points,crown_axis,prepNumber,
    selectedOption, numberingSystem } = location.state || {};
  const [prep, setprep] = useState(file1)
  const [anta, setanta] = useState(file2)
  const navigate = useNavigate();

const [values3D, setValues3D] = useState({
  "Cement gap": 0.03,
  "Adaptive extra gap": 0.03,
  "Minimal thickness": 0.50,
  "Height for minimal gap": 1.00,
  "Margin width": 0.20,
  "Margin angle": 45.0,
});

const [valuesMilling, setValuesMilling] = useState({
  "Tool radius": 0.60,
  "Cement gap": 0.03,
  "Adaptive extra gap": 0.03,
  "Minimal thickness": 0.50,
  "Height for minimal gap": 1.00,
  "Margin width": 0.10,
  "Margin angle": 45.0,
});
  const [brushingPoints, setBrushingPoints] = useState(null);
  const [marginstartpoint, setMarginstartpoint] = useState(null);
  const [marginendpoint, setMarginendpoint] = useState(null);
  const [Adjustmentpanel, setAdjustmentpanel] = useState(true);
  const [crownView, setcrownView] = useState(false);
  const [tool, setTool] = useState(true);
  const [initialCameraPosition, setInitialCameraPosition] = useState(position ? position : new THREE.Vector3(-42.57305524045056, -53.444090713186604, 9.906063101665028));
  const [showForm, setShowForm] = useState(false)
  const [shoeNewcrown, setshoeNewcrown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState('');
  const [brushing, setBrushing] = useState(false);
  const [allowed_points_list, setAllowedPoints] = useState([]);
  const [margin, setMargin] = useState(false);
  const [isRaycasterActive, setIsRaycasterActive] = useState(false);
  const raycasterSphereRef = useRef(null);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [crownforrequest, setCrownforrequest] = useState(null);
  const [indexforrequest, setIndexforrequest] = useState(null);
  const [crownarray, setCrownarray] = useState(crown);
  const primaryColor = '#213F99';
  const [category, setCategory] = useState('molar');
  const [marginIndex, setMarginIndex] = useState(null);
  const [smoothpoints, setSmoothpoints] = useState([]);
  const [crownColor, setCrownColor] = useState('#ffffff');
  const [anatomy, setAnatomy] = useState(false);
  const [distalcenter, setdestialcenter] = useState(false);
  const [mesialcenter, setmesialcenter] = useState(false);
  const [center, setcenter] = useState(false);
  const [smooth, setSmooth] = useState(false);
  const [vectorOfAxis, setVectorOfAxis] = useState(axis);
  const [grooves, setGrooves] = useState([]);
  const [inner_distance, setInner_distance] = useState(null);
  const [infoMode, setInfoMode] = useState("milling");
  const primarycolor = "#213F99";

  const [distanceView, setDistanceView] = useState(false);
  const [addsub, setaddsub] = useState(1);
  const [adjList, setAdjList] = useState(null);
  const [brushingRadius, setBrushingRadius] = useState(2);

  const [deformation, setDeformation] = useState(false);
  const [deformationRadius, setDeformationRadius] = useState(3);

  // const brushingRadius = useRef(2);
  const [brushingStrength, setBrushingStrength] = useState(0.02);
  const [smootheningRadius, setSmootheningRadius] = useState(2);
  const [heatmap, setHeatmap] = useState(true);

  const [smootheningStrength, setSmootheningStrength] = useState(0.5);
  const color = useRef('#ffffff');
  const [renderer, setRenderer] = useState(null);
  const [camera, setCamera] = useState(null);
  const [controls, setControls] = useState(null);
  const[trans_control, setTransControl] = useState(null);
  const [scene, setScene] = useState(null);
  const [allowed_vertices, setAllowedVertices] = useState(null);
  const [index_allowed_map, setIndexMap] = useState([])
  const [mapped_crown_indices, setMappedIncides] = useState([])
  const [sliderData, setSliderData] = useState([
    { name: 'Prep', opacity: 1, visible: prepView },
    { name: 'Anta', opacity: 1, visible: antaView },
    // Add Crown files to the sliderData array
    ...crown.map((crownFile, index) => ({
      name: `Crown_${index}`,  // You can customize the naming convention for each Crown file
      opacity: 1,
      visible: true,
    })),
  ]);
  const [marginManipulationTool, setMarginManipulationTool] = useState(false);
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  const handleChange = (key, delta) => {
    if (infoMode === "milling") {
      setValuesMilling((prev) => ({
        ...prev,
        [key]: Math.max(0, (prev[key] + delta).toFixed(3)),
      }));
    } else {
      setValues3D((prev) => ({
        ...prev,
        [key]: Math.max(0, (prev[key] + delta).toFixed(3)),
      }));
    }
  };

  const splineMaterial = new THREE.MeshStandardMaterial({
      color: !marginManipulationTool ? 0xff0000 : 0x0096FF, // Bright orange for a vibrant appearance
      metalness: 0.5,  // Increase metalness for a slight shine
      roughness: 0.3,  // Moderate roughness for some light reflection
      emissive: !marginManipulationTool ? 0xff0000 : 0x0096FF, // Bright yellow emissive for contrast
      emissiveIntensity: 0.7 // Higher intensity for a more noticeable glow
    });


  const curveRef = useRef(null);
  const raycaster = new THREE.Raycaster();
  const mouseRef = useRef(new THREE.Vector2());
  let isDragging = useRef(false);
  const selectedPoints = useRef([]); // To store the points for the line
  const [meshRef, setMeshRef] = useState(null);
  const [marginPCD, setMarginPCD] = useState(marginCurve);

  const [rotatetool, setRotatetool] = useState(false);
  const [ translatetool, setTranslatetool] = useState(false);
  const [scaletool, setScaletool] = useState(false);
  const [toggleCrownInfo, setToggleCrownInfo] = useState(false);
  const checkIsloggedIn=() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      navigate('/signin');
    }
  }

  // let IdxSet = new Set();

  const handleBrushingRadiusChange = (event) => {
    if (event.target && event.target.value !== undefined) {
      console.log("radius", event.target.value);
      setBrushingRadius(parseFloat(event.target.value));
      // brushingRadius.current = event.target.value;
      console.log(brushingRadius);
    } else {
      console.error('Event target or its value is null/undefined');
    }
  };
  const handleSmootheningStrengthChange = (event) => {
    if (event.target && event.target.value !== undefined) {
      console.log("smooth_strength", event.target.value);
      setSmootheningStrength(event.target.value);
    } else {
      console.error('Event target or its value is null/undefined');
  }}

  // Function to handle brushing strength change
  const handleBrushingStrengthChange = (event) => {
    if (event.target && event.target.value !== undefined) {
      console.log("strength", event.target.value);
      setBrushingStrength(parseFloat(event.target.value));
    } else {
      console.error('Event target or its value is null/undefined');
    }
  };

  const handleSmootheningRadiusChange = (event) => {
    if (event.target && event.target.value !== undefined) {
      console.log("smooth_radius", event.target.value);
      setSmootheningRadius(event.target.value);
    } else {
      console.error('Event target or its value is null/undefined');
    }
  }

  const removeMesh = (name) => {
      const mesh = scene.getObjectByName(name);
      if (mesh) {
        if (mesh.geometry) mesh.geometry.dispose();
        if (mesh.material) mesh.material.dispose();
        scene.remove(mesh);
      }
    };

  const containerRef = useRef(null);
  const [threeJSManager, setThreeJSManager] = useState(null);

  // const [object, setObject] = useState(null);
  const [kdtree, setKdtree] = useState(null);
  // const [positions, setPositions] = useState(null);

  // useEffect(() => {
  //     if(threeJSManager && scene){
      
  //       let newSplinecurve  
  //       let marginRadius = 0.04
        
  //       const updateTubeGeometry = () => {
  //         if (selectedPoints.current.length >= 2) {
  //           if(scene.getObjectByName('redSpline')){
  //             removeMesh('redSpline');
  //           }
  //           const beizer = createCubicBezierSegments(selectedPoints.current);
  //           newSplinecurve = new THREE.CurvePath();
  //           beizer.forEach((curve) => newSplinecurve.add(curve));
  //           const tubeGeometry = new THREE.TubeGeometry(newSplinecurve, selectedPoints.current.length * 20, 0.03, 8, false);
  //           const splineMaterial1 = new THREE.MeshBasicMaterial({ color: 0x000000 });
  //           const tubeMesh = new THREE.Mesh(tubeGeometry, splineMaterial1);
  //           tubeMesh.name = 'redSpline';
  //           scene.add(tubeMesh);
  //         }
  //       };
  
  //       const closedCurve = (pointArray, numControlPoints) => {
  //         const controlPoints = [];
  //         const stepSize = (pointArray.length - 1) / (numControlPoints - 1);
      
  //         for (let i = 0; i < numControlPoints; i++) {
  //             const index = i * stepSize;
  //             const lowIndex = Math.min(Math.floor(index), pointArray.length - 1);
  //             const highIndex = Math.min(Math.ceil(index), pointArray.length - 1);
      
  //             if (lowIndex === highIndex) {
  //                 controlPoints.push(pointArray[lowIndex]);
  //             } else {
  //                 // Interpolate between lowIndex and highIndex
  //                 const t = Math.max(0, Math.min(1, index - lowIndex));
  //                 const interpolated = pointArray[lowIndex].clone().lerp(pointArray[highIndex], t);
  //                 controlPoints.push(interpolated);
  //             }
  //         }
      
  //         // Close the curve by adding the first point at the end
  //         controlPoints.push(controlPoints[0].clone());
      
  //         return controlPoints;
  //     };
      
      
  
  //       const update = (maincurve, newcurve) => {
  //         if (!newcurve) return;
      
  //         // Get evenly spaced points from the entire main curve
  //         const getEvenlySpacedPoints = (curve, numPoints) => {
  //             const points = [];
  //             for (let i = 0; i <= numPoints; i++) {
  //                 const t = i / numPoints; // Normalized interval
  //                 points.push(curve.getPointAt(t));
  //             }
  //             return points;
  //         };
      
  //         const numMainPoints = 300; // Adjust as needed for smoothness
  //         const maincurvePoints = getEvenlySpacedPoints(maincurve, numMainPoints);
      
  //         const start = newcurve.getPoint(0);
  //         const end = newcurve.getPoint(1);
      
  //         let startind = 0,
  //             endind = 0;
  //         let minstartDist = Infinity,
  //             minendDist = Infinity;
      
  //         // Find the closest indices for the start and end points of the new curve
  //         maincurvePoints.forEach((point, index) => {
  //             const startDist = point.distanceTo(start);
  //             const endDist = point.distanceTo(end);
  //             if (startDist < minstartDist) {
  //                 minstartDist = startDist;
  //                 startind = index;
  //             }
  //             if (endDist < minendDist) {
  //                 minendDist = endDist;
  //                 endind = index;
  //             }
  //         });
      
  //         // Reverse the curve if start index is greater than end index
  //         const reverseCurve = (curve) => {
  //           // Get evenly spaced points and reverse them
  //           const reversedPoints = getEvenlySpacedPoints(curve, 150).reverse();
        
  //           // Create new curve segments from the reversed points
  //           const reversedSegments = createCubicBezierSegments(reversedPoints);
        
  //           // Construct a new CurvePath from the reversed segments
  //           const reversedCurve = new THREE.CurvePath();
  //           reversedSegments.forEach((segment) => reversedCurve.add(segment));
        
  //           return reversedCurve;
  //       };
        
      
  //         if (startind > endind) {
  //             [startind, endind] = [endind, startind];
  //             newcurve = reverseCurve(newcurve);
  //         }
  //         const smoothfactor = 1;
  //         startind = Math.max(0, startind - smoothfactor);
  //         endind = Math.min(maincurvePoints.length - 1, endind + smoothfactor);
      
  //         // Determine if first segment is smaller
  //         let mid = startind + Math.floor((endind - startind) / 2);
  //         const firstsegdist = newcurve.getPoint(0.5).distanceTo(maincurvePoints[mid]);
  //         const lastsegdist = newcurve.getPoint(0.5).distanceTo(maincurvePoints[0]);
  //         const isFirstSegmentSmaller = firstsegdist < lastsegdist;
  //         const numPoint = isFirstSegmentSmaller ? endind - startind : maincurve.curves.length - (endind - startind);
  //         const newcurvePoints = getEvenlySpacedPoints(newcurve, numPoint);
  
  //         const isSmaller = reverseCurve(newcurve)
  //         let isSmallerPoints = getEvenlySpacedPoints(isSmaller,numPoint);
      
  //         // Create new curve by replacing points in the smaller segment
  //         let newmaincurve = isFirstSegmentSmaller
  //             ? [
  //                   ...maincurvePoints.slice(0, startind),
  //                   ...newcurvePoints,
  //                   ...maincurvePoints.slice(endind),
  //               ]
  //             : [
  //                   ...isSmallerPoints,
  //                   ...maincurvePoints.slice(startind+(2*smoothfactor), endind-(2*smoothfactor)),
                    
  //               ];
  
  //           newmaincurve = closedCurve(newmaincurve, 300);
          
  //         return newmaincurve;
  //     };
      
      
        
  //     const onMouseDown = (event) => {
  //       if(event.button === 2){
  //         return;
  //       }
  //       event.preventDefault();
  //       isDragging.current = true;
  //       selectedPoints.current = []; // Reset points for a new spline
  //     };
  
  //     // Mouse move event to add points while dragging
  //     const onMouseMove = (event) => {
  //       if (event.button === 0 && isDragging.current) {
  //         // Update mouse vector with normalized device coordinates
  //         const rect = renderer.domElement.getBoundingClientRect();
  //         mouseRef.current.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
  //         mouseRef.current.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
      
  //         // Cast ray and check for intersections with mesh
  //         raycaster.setFromCamera(mouseRef.current, camera);
  //         const prepMesh = scene.getObjectByName('prep');
  //         const intersects = raycaster.intersectObject(prepMesh);
      
  //         if (intersects.length > 0) {
  //           const point = intersects[0].point.clone();
  //           selectedPoints.current.push(point);
  //           updateTubeGeometry();
  //         }
  //       }
  //     };
      
  //     const onMouseUp = () => {
  //       try {
  //         // Ensure a valid newSplinecurve exists before proceeding
  //         if (!newSplinecurve || selectedPoints.current.length < 4) {
  //           return;
  //         }
      
  //         const newmaincurve = update(curveRef.current, newSplinecurve);
  
  //         // newmaincurve.forEach((point) => {
  //         //   const sphereGeometry = new THREE.SphereGeometry(0.06, 32, 32);
  //         //   const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
  //         //   const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
  //         //   sphere.position.copy(point);
  //         //   scene.add(sphere);
  //         // })
  //         if (!newmaincurve) return;
      
  //         const segments = createCubicBezierSegments(newmaincurve);
  //         const pnts = combineBezierPoints(segments);
  //         const curv = new THREE.CurvePath();
  //         segments.forEach((curve) => curv.add(curve));
  //         curveRef.current = curv
      
  //         // Remove old splines and add the updated one
  //         const orignalSpline = scene.getObjectByName('blackSpline');
  //         if (orignalSpline) {
  //           removeMesh('blackSpline');
  //         }
      
  //         const newSpline = scene.getObjectByName('redSpline');
  //         if (newSpline) {
  //           removeMesh('redSpline');
  //         }
      
  //         const tube = new THREE.TubeGeometry(curv, 1000, 0.04, 1000, false);
  //         const newCombinedSpline = new THREE.Mesh(tube, splineMaterial);
  //         newCombinedSpline.name = 'blackSpline';
  //         scene.add(newCombinedSpline);
  //         isDragging.current = false;
  //       } catch (error) {
  //         isDragging.current = false;
  //         console.error('Error updating spline:', error);
  //       }
  //       finally{
  //         isDragging.current = false;
  //       }
  //     };
  
  //     const createCubicBezierSegments = (points) => {
  //       const curves = [];
  //   for (let i = 0; i < points.length - 1; i++) {
  //     const p0 = points[i];
  //     const p3 = points[i + 1];
      
  //     // Tangent alignment for smooth transitions
  //     const tangent = p3.clone().sub(p0).multiplyScalar(0.33); 
  //     const p1 = p0.clone().add(tangent);
  //     const p2 = p3.clone().sub(tangent);
  
  //     curves.push(new THREE.CubicBezierCurve3(p0, p1, p2, p3));
  //   }
  //   return curves;
  //     };
  
  //     const combineBezierPoints = (bezierCurves, divisions = 25) => {
  //       const combinedPoints = [];
  //       bezierCurves.forEach((curve) => {
  //         const points = curve.getPoints(divisions);
  //         combinedPoints.push(...points);
  //       });
  //       return combinedPoints;
  //     };
  
      
  
  //     if (threeJSManager && marginPCD && !curveRef.current) {
  //       const pcdLoader = new PCDLoader();
  //       const pcdBlob = new Blob([marginPCD], { type: 'application/octet-stream' });
  //       const pcdUrl = URL.createObjectURL(pcdBlob);
        
  //       pcdLoader.load(pcdUrl, (points) => {
  //         const positions = points.geometry.attributes.position.array;
      
  //         // Sample control points evenly
  //         const generateControlPoints = (positions, numControlPoints) => {
  //           // Convert flat position array into THREE.Vector3 objects
  //           const pointArray = [];
  //           for (let i = 0; i < positions.length; i += 3) {
  //               pointArray.push(new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2]));
  //           }
        
  //           // Ensure there are enough points
  //           if (pointArray.length <= numControlPoints) {
  //               console.warn('Insufficient points. Returning available points.');
  //               return pointArray.slice(); // Return all points if less than requested
  //           }
        
  //           // Calculate step size for even distribution
  //           const controlPoints = [];
  //           const stepSize = (pointArray.length - 1) / (numControlPoints - 1);
        
  //           for (let i = 0; i < numControlPoints; i++) {
  //               const index = i * stepSize;
  //               const lowIndex = Math.floor(index);
  //               const highIndex = Math.ceil(index);
        
  //               if (lowIndex === highIndex) {
  //                   controlPoints.push(pointArray[lowIndex]);
  //               } else {
  //                   // Interpolate between lowIndex and highIndex
  //                   const t = index - lowIndex;
  //                   const interpolated = pointArray[lowIndex].clone().lerp(pointArray[highIndex], t);
  //                   controlPoints.push(interpolated);
  //               }
  //           }
        
  //           return controlPoints;
  //       };
        
  //       const numControlPoints = 400;
  //       const controlPoints = generateControlPoints(positions, numControlPoints);
        
        
      
  //         // Generate cubic Bézier segments
  //         const bezierSegments = createCubicBezierSegments(controlPoints);
      
  //         // Create a continuous geometry using CurvePath
  //         const fullCurve = new THREE.CurvePath();
  //         bezierSegments.forEach((curve) => fullCurve.add(curve));
      
  //         curveRef.current = fullCurve;
      
  //         // Generate TubeGeometry
  //         const tube = new THREE.TubeGeometry(fullCurve, 1000, marginRadius, 100, false); // Set 'closed' to true
  //         const originalMesh = new THREE.Mesh(tube, splineMaterial);
  //         originalMesh.name = 'blackSpline';
      
  //         scene.add(originalMesh);
  //         // controlPoints.forEach((point) => {
  //         //   const sphereGeometry = new THREE.SphereGeometry(0.07, 32, 32);
  //         //   const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
  //         //   const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
  //         //   sphere.position.copy(point);
  //         //   scene.add(sphere);
  //         // });
  //       });
  //     }
      
  // if(!marginManipulationTool){
  //       controls.noPan = false;
  //       controls.enablePan = true;
  // }
  //     if(marginManipulationTool){
  //       controls.noPan = true;
  //       controls.enablePan = false;

  //       const marginSpline=scene.getObjectByName('blackSpline');
  //       if(marginSpline){
  //         marginSpline.material.color.set(0x0096FF);
  //         marginSpline.material.emissive.set(0x0096FF); 
  //         marginSpline.material.transparent = true;
  //       }
  //       renderer.domElement.addEventListener('mousedown', onMouseDown);
  //     renderer.domElement.addEventListener('mousemove', onMouseMove);
  //     renderer.domElement.addEventListener('mouseup', onMouseUp);
  //   }
  
  //     return () => {
  //       if(marginManipulationTool){
  
  //         const marginSpline=scene.getObjectByName('blackSpline');
  //       if(marginSpline){
  //         marginSpline.material.color.set(0xff0000);
  //         marginSpline.material.emissive.set(0xff0000); 
  //         marginSpline.material.transparent = true;
  //       }
  
  //         renderer.domElement.removeEventListener('mousedown', onMouseDown);
  //       renderer.domElement.removeEventListener('mousemove', onMouseMove);
  //       renderer.domElement.removeEventListener('mouseup', onMouseUp);
  // }
  //     }}
  //   }, [threeJSManager,scene,meshRef,marginManipulationTool,marginPCD]);
  
    useEffect(() => {
      checkIsloggedIn();
      if (!threeJSManager || !scene || !camera || !renderer || deformation) return;
      let isSmoothing = false;
      // const raycaster = new THREE.Raycaster();
      const mouse = new THREE.Vector2();
      const object = scene.getObjectByName('crown_0');
      let meshCenter = new THREE.Vector3(0, 0, 0);
      if(object && object.geometry){
        meshCenter = object.geometry.boundingSphere.center;
      }
      const domElement = renderer.domElement;
    
      // Event Handlers
      const startSmoothing = (event) => {
        if(object && object.geometry && event.ctrlKey){
        setUndoStack((prevStack) => [...prevStack, object.geometry.clone()]);
        setRedoStack([]);
        }
        isSmoothing = true;
        updateSmoothing(event);
      };
    
      const updateSmoothing = (event) => {
        if (!isSmoothing) return;
    
        if (event.button === 0 && event.ctrlKey && (brushing || smooth)) {
          controls.noPan = true;
          controls.enablePan = false;
    
          // Calculate mouse position in normalized device coordinates
          const rect = domElement.getBoundingClientRect();
          mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
          mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    
          // Update the picking ray with the camera and mouse position
          raycaster.setFromCamera(mouse, camera);
    
          // Find intersecting objects
          const intersects = raycaster.intersectObjects(scene.children);
          const crownIntersect = intersects.find(intersect => intersect.object.name === 'crown_0');
          if (crownIntersect) {

            const intersectionPoint = crownIntersect.point;
            const intersectNormal = crownIntersect.face.normal;
    
            if (brushing && kdtree) {
              // console.log(object.geometry);
              // deformMesh(addsub, intersectionPoint, intersectNormal, object.geometry);
              const directionVector = new THREE.Vector3().subVectors(intersectionPoint, meshCenter);

              // Step 2: Normalize the vectors
              const normalizedNormal = intersectNormal.clone().normalize();
              const normalizedDirection = directionVector.clone().normalize();

              // Step 3: Compute the dot product
              const dotProduct = normalizedNormal.dot(normalizedDirection);

              // Step 4: Calculate angle in degrees
              const angleRadians = Math.acos(dotProduct);
              const angleDegrees = THREE.MathUtils.radToDeg(angleRadians);
              if (angleDegrees >= -90 && angleDegrees <= 90) {
                // console.log(angleDegrees, meshCenter);
                addFace(addsub, intersectionPoint, intersectNormal, kdtree, object.geometry);
              }
            }
            if (smooth) {
              smoothenFrontend(intersectionPoint, kdtree, object.geometry);
            }
            
          }
        } else if(!marginManipulationTool) {
          controls.noPan = false;
          controls.enablePan = true;
        }
      };
    
      const onMouseMove = (event) => {
        const rect = domElement.getBoundingClientRect();
        mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    
        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(scene.children);
    
        const crownIntersect = intersects.find(intersect => intersect.object.name === 'crown_0');
        if (crownIntersect && (brushing || smooth)) {
          const currentHoverPoint = crownIntersect.point;
          // const currentHoverNormal = crownIntersect.face.normal;
          if (object && object.material) {
            if(brushing){
              object.material.uniforms.brushradius.value = parseFloat(brushingRadius);
            }
            else if(smooth){
              object.material.uniforms.brushradius.value = parseFloat(smootheningRadius);
            }
            // const offset = currentHoverNormal.clone().multiplyScalar(0.5); // Small offset to prevent collision
            // ring.position.copy(currentHoverPoint);
            // const axis = new THREE.Vector3(0, 0, 1); // Default orientation of the ring
            // const quaternion = new THREE.Quaternion().setFromUnitVectors(axis, currentHoverNormal);
            // ring.setRotationFromQuaternion(quaternion);
            // ring.visible = true;
            object.material.uniforms.uHoverPosition.value.copy(currentHoverPoint);
          }
        } else if (object && object.material) {
          object.material.uniforms.uHoverPosition.value.set(-1000, -1000, -1000);
          // ring.visible = false;
          if(brushing){
            object.material.uniforms.brushradius.value = parseFloat(brushingRadius);
          }
          else if(smooth){
            object.material.uniforms.brushradius.value = parseFloat(smootheningRadius);
          }
        }
      };
    
    
      function onMouseUp(event) {

        if(!(brushing || smooth) || (!object || !object.geometry)) return;
        //   const startTime = performance.now();
          
        //   // if (brushingRadius < 1) {
        //   //   smoothening();
        //   // }
    
        //   // Batch geometry updates
          const geometries = U.parseGeometry(object.geometry);
          const vertices = geometries[0];
          
          let allowed_crown_vertices = [];
          let indexMap = [];
          for (let i =0 ; i < mapped_crown_indices.length; i++ ){
            allowed_crown_vertices.push(vertices[mapped_crown_indices[i]]);
            indexMap.push(mapped_crown_indices[i]);
          }
          setIndexMap(indexMap);
          setKdtree(new KdTree(allowed_crown_vertices));
          object.geometry.computeVertexNormals();
      }
    
      const brushingRadiusSlider = document.getElementById('brushingradius');
      const brushingStrengthSlider = document.getElementById('brushingstrength');
      const smootheningRadiusSlider = document.getElementById('smootheningradius');
      const smootheningStrengthSlider = document.getElementById('smootheningstrength');
    

      
    
      // Add Event Listeners
      domElement.addEventListener('mousemove', onMouseMove);
      domElement.addEventListener('mousedown', startSmoothing);
      domElement.addEventListener('mousemove', updateSmoothing);
      domElement.addEventListener('mouseup', onMouseUp);
    
      brushingRadiusSlider?.addEventListener('input', handleBrushingRadiusChange);
      brushingStrengthSlider?.addEventListener('input', handleBrushingStrengthChange);
      smootheningRadiusSlider?.addEventListener('input', handleSmootheningRadiusChange);
      smootheningStrengthSlider?.addEventListener('input', handleSmootheningStrengthChange);
    
      // Clean up Event Listeners on unmount
      return () => {
        domElement.removeEventListener('mousedown', startSmoothing);
        domElement.removeEventListener('mousemove', onMouseMove);
        domElement.removeEventListener('mousemove', updateSmoothing);
        domElement.removeEventListener('mouseup', onMouseUp);
        
        // removeMesh('brushing_ring');
    
        brushingRadiusSlider?.removeEventListener('input', handleBrushingRadiusChange);
        brushingStrengthSlider?.removeEventListener('input', handleBrushingStrengthChange);
        smootheningRadiusSlider?.removeEventListener('input', handleSmootheningRadiusChange);
        smootheningStrengthSlider?.removeEventListener('input', handleSmootheningStrengthChange);
      };
    }, [threeJSManager, scene, camera, renderer, brushing,marginManipulationTool ,smooth, brushingRadius, addsub, kdtree, brushingStrength, smootheningRadius, smootheningStrength]);
    
    useEffect(() => {
      if(!threeJSManager || !scene || !camera || !renderer || !deformation) return;
      // let ringGeometry = null;
      const object = scene.getObjectByName('crown_0');
      let old_geometry = null;
      // deformationRadius = brushingRadius;
      console.log("Deformation Radius", deformationRadius);

      if(object && object.geometry){
        old_geometry = object.geometry.clone();
      }
      else{
        return;
      }
      let isDragging = false;
      let startPoint = null;
      let intersectedPoint = null;
      let normal2D = null;
      const mouse = new THREE.Vector2();
      const domElement = renderer.domElement;
    
      function deformMesh(intersectPoint, normal, deformInt){
          const startTime1 = performance.now();
          const positions = object.geometry.attributes.position.array;
          const startTime2 = performance.now(); 
          const brushRadiusSquared = deformationRadius*deformationRadius;
    
          deformInt = Math.min(deformInt, 1);
    
          // let count = 0;
    
          // Bezier control points for falloff
          const p0 = 1.0;  
          const p1 = 1.0;  
          const p2 = 1.0; 
          const p3 = 0.0; 
          const p4 = 0.0;
          const p5 = 0.0;
    
    
          // KNN approach for making subset of positions
    
          kdtree.rnn([intersectPoint.x,intersectPoint.y,intersectPoint.z], deformationRadius, function(j) {
              let i = index_allowed_map[j];
              const dx = positions[i*3] - intersectPoint.x;
              const dy = positions[i*3 + 1] - intersectPoint.y;
              const dz = positions[i*3 + 2] - intersectPoint.z;
    
    
              const distanceSquared = dx * dx + dy * dy + dz * dz;
    
              const vertex = new THREE.Vector3(positions[i*3], positions[i*3 + 1], positions[i*3 + 2]);
    
              // Deform vertices within brush radius
              if (distanceSquared < brushRadiusSquared) {
                  const distance = Math.sqrt(distanceSquared);
                  const t = distance / deformationRadius; 
                  const falloff = pentBezier(t, p0, p1, p2, p3, p4, p5);
                  vertex.addScaledVector(normal, falloff * deformInt);
                  positions[i*3] = vertex.x;
                  positions[i*3 + 1] = vertex.y;
                  positions[i*3 + 2] = vertex.z;
              }
          })
          const startTime3 = performance.now();
          object.geometry.attributes.position.needsUpdate = true;
          object.geometry.computeVertexNormals();
          const startTime4 = performance.now();
          console.log(startTime4-startTime3, startTime3-startTime2, startTime2-startTime1);
      }
    
      function pentBezier(t, p0, p1, p2, p3, p4, p5){
          const u = 1-t;
          return (u * u * u * u * u * p0) +
                  (5 * u * u * u * u * t * p1) +
                  (10 * u * u * u * t * t * p2) +
                  (10 * u * u * t * t * t * p3) +
                  (5 * u * t * t * t * t * p4) +
                  (t * t * t * t * t * p5);
      }
    
    
      function onMouseDown(event) {
        const rect = domElement.getBoundingClientRect();
        mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
        if(object && object.geometry && event.ctrlKey){
          setUndoStack((prevStack) => [...prevStack, object.geometry.clone()]);
          setRedoStack([]);
        }
      
          raycaster.setFromCamera(mouse, camera);
          const intersects = raycaster.intersectObjects(scene.children);
          const crownIntersect = intersects.find(intersect => intersect.object.name === 'crown_0');
      
          if (crownIntersect && event.button === 0 && event.ctrlKey) {
              if(!isDragging){
                console.log("Deformation Started");
                isDragging = true;
                intersectedPoint = crownIntersect;
                old_geometry = object.geometry.clone();
                startPoint = new THREE.Vector2(event.clientX, event.clientY);
                const normal = intersectedPoint.face.normal.clone();
                // normal.transformDirection(object.matrixWorld); // Transform to world space
                const normalScreen = normal.clone().transformDirection(object.modelViewMatrix).transformDirection(camera.projectionMatrix);
                normal2D = new THREE.Vector2(normalScreen.x, -normalScreen.y);
              }
          }
      }
      
      function onMouseMove(event) {
          if(isDragging && event.button === 0 && event.ctrlKey){ 
            controls.noPan = true;
            controls.enablePan = false;
            const endPoint = new THREE.Vector2(event.clientX, event.clientY);
            const dragVector = new THREE.Vector2().subVectors(endPoint, startPoint); 
            const normal = intersectedPoint.face.normal.clone();   
    
            // Map normal to 2D space    
            console.log("Normal", normal2D);
            console.log("Drag Vector", dragVector);
            console.log("Angle", (dragVector.angle() - normal2D.angle())* (180/Math.PI));
    
    
            // Calculate angle between drag vector and mapped normal
            const angle = dragVector.angle() - normal2D.angle();
            const axis = new THREE.Vector3().crossVectors(normal, new THREE.Vector3(0, 0, 1)).normalize();
            if(normal2D.x < 0) normal.applyAxisAngle(axis, angle);
            if(normal2D.x >= 0) normal.applyAxisAngle(axis, -angle);
    
            // Calculate deformation strength
            const deformationStrength = 0.03* dragVector.length() * Math.abs(Math.cos(angle));
            console.log(deformationStrength);
    
            object.geometry = old_geometry.clone();
    
            deformMesh(intersectedPoint.point, normal, deformationStrength);
          }  
      }
      // function RingVisualization(event){
      //   if (isDragging) return;
      //   const rect = domElement.getBoundingClientRect();
      //     mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      //     mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
      
      //     raycaster.setFromCamera(mouse, camera);
      //     const intersects = raycaster.intersectObjects(scene.children);
      //     const crownIntersect = intersects.find(intersect => intersect.object.name === 'crown_0');
      
      //     if (crownIntersect && ring && ring.geometry) {
      //         const point = crownIntersect.point;
      //         const normal = crownIntersect.face.normal;
      //         // normal.transformDirection(object.matrixWorld); // Transform to world space
          
      //         // Position the hover ring
      //         ring.position.copy(point);
      //         ring.lookAt(point.clone().add(normal));
      //         ring.visible = true;
      //         // console.log("Ring Visible", ring, scene);  
      //     } else {
      //         ring.visible = false;
      //     }
      // }
      
      function RingVisualization(event){
        if (isDragging) return;
        const rect = domElement.getBoundingClientRect();
        mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    
        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(scene.children);
    
        const crownIntersect = intersects.find(intersect => intersect.object.name === 'crown_0');
        if (crownIntersect) {
          const currentHoverPoint = crownIntersect.point;
          if (object && object.material) {
            object.material.uniforms.brushradius.value = parseFloat(deformationRadius);
            object.material.uniforms.uHoverPosition.value.copy(currentHoverPoint);
          }
        } else if (object && object.material) {
          object.material.uniforms.uHoverPosition.value.set(-1000, -1000, -1000);
          object.material.uniforms.brushradius.value = parseFloat(deformationRadius);
        }
      };

      function onMouseUp(event) {
          if (!isDragging || !startPoint) return;
          isDragging = false;
          startPoint = null;
          old_geometry = object.geometry.clone();
          object.geometry.attributes.position.needsUpdate = true;
          const geometries = U.parseGeometry(object.geometry);
          const vertices = geometries[0];
          
          let allowed_crown_vertices = [];
          let indexMap = [];
          for (let i =0 ; i < mapped_crown_indices.length; i ++ ){
            allowed_crown_vertices.push(vertices[mapped_crown_indices[i]]);
            indexMap.push(mapped_crown_indices[i]);
          }
          setIndexMap(indexMap);
          setKdtree(new KdTree(allowed_crown_vertices));
          // Batch normal computations
          object.geometry.computeVertexNormals();
          controls.noPan = false;
          controls.enablePan = true;
          return;
      }   
      
      const handleDeformationRadiusChange = (event) => {
        if (event.target && event.target.value !== undefined) {
          console.log("smooth_radius", event.target.value);
          setDeformationRadius(event.target.value);
        } else {
          console.error('Event target or its value is null/undefined');
        }
      }

      domElement.addEventListener('mousedown', onMouseDown);
      domElement.addEventListener('mousemove', onMouseMove);
      domElement.addEventListener('mousemove', RingVisualization);
      domElement.addEventListener('mouseup', onMouseUp);

      const DeformationRadiusSlider = document.getElementById('deformationradius');
      DeformationRadiusSlider?.addEventListener('input', handleDeformationRadiusChange);

      return () => {
        domElement.removeEventListener('mousedown', onMouseDown);
        domElement.removeEventListener('mousemove', onMouseMove);
        domElement.removeEventListener('mouseup', onMouseUp);
        domElement.removeEventListener('mousemove', RingVisualization);
        DeformationRadiusSlider?.removeEventListener('input', handleDeformationRadiusChange);
        
        // removeMesh('brushing_ring2');
      }

    }, [threeJSManager, scene, camera, renderer,deformation,deformationRadius]);

  useEffect(() => {
    if (containerRef.current) {
      const manager = new ThreeJSManager(
        containerRef.current.id,
        initialCameraPosition, color, sliderData, false, crownarray, file1, file2, crown, prepView, antaView, scene, renderer ,camera, controls,inner_surface,thickness_allowed_points, crown_axis
      );
      manager.initRenderer();
      manager.initScene();
      manager.initCamera();
      manager.initControls();
      manager.animate();
      manager.loadAllSTLs();
      setScene(manager.scene);
      setRenderer(manager.renderer);
      setCamera(manager.camera);
      setControls(manager.controls);
      setThreeJSManager(manager);
      // const mpp = U.map_to_be_removed(removed_indices)
      setAllowedPoints(allowed_points);
      
      return () => {
        manager.cleanup();
      };
    }


  }, []);
  const processSceneData = () => {
    // setffd(new FFD());
    
  
    const handleCrownLoading = () => {
      if (scene.getObjectByName("crown_0")) {
        const object = scene.getObjectByName("crown_0");
        const geometry = object.geometry;
        const geometries = U.parseGeometry(geometry, true);
        console.log("adjacent set");
        const faces = geometries[1];
        const adj = U.build_adjacency(geometry, faces);
        if (!adjList) {
          setAdjList(adj);
          console.log(adj); // Log adj instead of adjList to see immediate value
        }
      }
    };
  
    if (scene) {
      const object = scene.getObjectByName("crown_0");
      if (object && object.geometry) {

        const geometries = U.parseGeometry(object.geometry);
        const vertices = geometries[0];

      let crownTree = new KdTree(vertices);
        let mapped_indices = []
        for (let i = 0; i < allowed_points_list.length; i++){
            mapped_indices.push(crownTree.knn(allowed_points_list[i], 1)[0])
        }
        setMappedIncides(mapped_indices)
        let indexMap = []
        let allowed_crown_vertices = []
        for (let i =0 ; i < mapped_indices.length; i ++ ){
          allowed_crown_vertices.push(vertices[mapped_indices[i]])
          indexMap.push(mapped_indices[i])
        }
        setIndexMap(indexMap)
        setKdtree(new KdTree(allowed_crown_vertices))
        console.log("I am here too");

      }
    }
  
    if (threeJSManager) {
      threeJSManager.loadcrowns(crownarray, handleCrownLoading);
    }
  };
  useEffect(() => {
    processSceneData();
  }, [threeJSManager,adjList ,crownarray, vectorOfAxis]);

  // useEffect(() => {
  //   const createCubicBezierSegments = (points) => {
  //         const curves = [];
  //     for (let i = 0; i < points.length - 1; i++) {
  //       const p0 = points[i];
  //       const p3 = points[i + 1];
        
  //       // Tangent alignment for smooth transitions
  //       const tangent = p3.clone().sub(p0).multiplyScalar(0.33); 
  //       const p1 = p0.clone().add(tangent);
  //       const p2 = p3.clone().sub(tangent);
    
  //       curves.push(new THREE.CubicBezierCurve3(p0, p1, p2, p3));
  //     }
  //     return curves;
  //       };
  //   if (vectorOfAxis.length > 0 && scene) {
  //     const scaleFactor = 2;
  //     const colors = [0xff0000, 0x00ff00, 0x0000ff, 0xffff00, 0xff00ff]; // Array of 5 colors
  //     console.log(vectorOfAxis);
  //     // Iterate through the vectors and create lines
  //     for(let index=0;index<vectorOfAxis.length;index++){
  //       const start = vectorOfAxis[index][0] ;
  //       const end = vectorOfAxis[index][1];
  
  //       // Choose a color based on the index
  //       const color = colors[index % colors.length]; // Use modulo to cycle colors if needed
  //       const material = new THREE.LineBasicMaterial({ color });
  
  //       // Calculate the direction vector and scale it
  //       const direction = new THREE.Vector3(
  //         end[0] - start[0],
  //         end[1] - start[1],
  //         end[2] - start[2]
  //       ).multiplyScalar(scaleFactor);
  
  //       // Create the new scaled end point
  //       const scaledEnd = new THREE.Vector3(
  //         start[0] + direction.x,
  //         start[1] + direction.y,
  //         start[2] + direction.z
  //       );
  
  //       const points = [];
  //       points.push(new THREE.Vector3(start[0], start[1], start[2]));
  //       points.push(scaledEnd);
  
  //       const geometry = new THREE.BufferGeometry().setFromPoints(points);
  //       const line = new THREE.Line(geometry, material);
  
  //       scene.add(line); // Ensure `scene` is defined in your component or context
  //     };
  //   }
  // }, [threeJSManager, vectorOfAxis,scene]);


//   useEffect(() => {
//     if (threeJSManager && scene && camera && renderer) {
//     const crownObject = scene.getObjectByName('crown_0');

//     if (crownObject && inner_surface) {
//       const verts_ind = U.Heatmapverts(inner_surface, crownObject); // Your function to get vertices

//       const color_Attrib = new Float32Array(crownObject.geometry.attributes.position.count * 3);
//       const color = new THREE.Color(0xff0000); // Red color
//       verts_ind.forEach((vert) => {
//         color_Attrib[vert * 3] = color.r;
//         color_Attrib[vert * 3 + 1] = color.g;
//         color_Attrib[vert * 3 + 2] = color.b;
//       });

//       crownObject.geometry.setAttribute('color', new THREE.BufferAttribute(color_Attrib, 3));
//       crownObject.material.vertexColors = true;
//       crownObject.geometry.attributes.color.needsUpdate = true;
//       crownObject.material.needsUpdate = true;
//     }


//         // const crownObject = scene.getObjectByName('crown_0');

//         // if (crownObject && inner_surface) {
//         //     const verts_ind = U.Heatmapverts(inner_surface, crownObject); // Your function to get vertices

//         //     const color_Attrib = new Float32Array(crownObject.geometry.attributes.position.count*3);
//         //     const color = new THREE.Color(0xff0000); // Red color
//         //     verts_ind.forEach((vert, index) => {
//         //       color_Attrib[index*3] = color.r;
//         //       color_Attrib[index*3 + 1] = color.g;
//         //       color_Attrib[index*3 + 2] = color.b;
//         //     })

//         //     crownObject.geometry.setAttribute('color', new THREE.BufferAttribute(color_Attrib, 3));
//         //     crownObject.material.vertexColors = color_Attrib;
//         //     crownObject.geometry.needsUpdate = true;
//         //     crownObject.material.needsUpdate = true;
//         //     // scene.add(sphereGroup);
//         // }
//             }
// }, [threeJSManager, scene, camera, renderer, brushing, inner_surface]);

  useEffect(() => {
    if (threeJSManager && scene && camera && renderer) {
    // const crownObject = scene.getObjectByName('crown_0');
    // const anta_mesh = scene.getObjectByName('anta');
    // if(crownObject && anta_mesh && thickness_allowed_points){
    //   const verts = U.upper_heatmap(crownObject.geometry, anta_mesh.geometry,thickness_allowed_points);
    //   // crownObject.geometry.setAttribute('thickness_anta', new THREE.Float32BufferAttribute(verts[0], 3));

    //   const crownAxis = ;
    //   const arrowHelper = new THREE.ArrowHelper(
    //     new THREE.Vector3(crownAxis[0], crownAxis[1], crownAxis[2]).normalize(),
    //     new THREE.Vector3(0, 0, 0),
    //     10, // Length of the arrow
    //     0xff0f10 // Color of the arrow
    //   );
    //   scene.add(arrowHelper);
      
    // }

    }},[threeJSManager, scene, camera, renderer, brushing, inner_surface]);





  useEffect(() => {
    const shineSlider = document.getElementById('shine');
    const axisButton = document.getElementById('grid');
    const wireframeButton = document.getElementById('wireframe');
    const viewButton = document.getElementById('defaultview');
    const opacitySliders = document.querySelectorAll('[id^="opacitySlider-"]');
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    


    const handleMouseDown = (event) => {
      if (event.button === 2) {
        return;
      }
      const rect = renderer.domElement.getBoundingClientRect();
      mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(scene.children, true);
      const crownIntersect = intersects.find(intersect => intersect.object.name === 'crown_0');
      if (crownIntersect) {
        const point = crownIntersect.point;
        const selectedObject = crownIntersect.object;
        if (selectedObject.name.match(/\d+$/)) {
          const match = selectedObject.name.match(/\d+$/);
          setCrownforrequest(selectedObject);
          setIndexforrequest(parseInt(match[0]));
        }
        setSelectedCoordinates(point);
        if (raycasterSphereRef.current) {
          scene.remove(raycasterSphereRef.current);
        }

        // Create a new raycaster sphere
        if (brushing || smooth) {
          return;
        }

        if (distanceView) {
          if (selectedObject.name === 'crown_0') {
            const raycasterMaterial = new THREE.MeshBasicMaterial({ color: 0x0000ff });
            const raycasterSphereGeometry = new THREE.SphereGeometry(0.16); // Adjust the size as needed
            const newRaycasterSphere = new THREE.Mesh(raycasterSphereGeometry, raycasterMaterial);
            newRaycasterSphere.position.copy(point);
            raycasterSphereRef.current = newRaycasterSphere;

            // Add the new raycaster sphere to the scene
            scene.add(newRaycasterSphere);
            getDist(point);
          }
        }
        if(margin && event.button === 0){
          const raycasterMaterial = new THREE.MeshBasicMaterial({ color: 0x0000ff });
            const raycasterSphereGeometry = new THREE.SphereGeometry(0.16); // Adjust the size as needed
            const newRaycasterSphere = new THREE.Mesh(raycasterSphereGeometry, raycasterMaterial);
            newRaycasterSphere.position.copy(point);
            raycasterSphereRef.current = newRaycasterSphere;

            // Add the new raycaster sphere to the scene
            scene.add(newRaycasterSphere);
        }
      }
    }
    if (isRaycasterActive) {
      renderer.domElement.addEventListener('mousedown', handleMouseDown);
    }

    const changeShineness = () => {
      if (threeJSManager) {
        threeJSManager.changeShineness();
      }
    };

    const toggleAxes = () => {
      if (threeJSManager) {
        threeJSManager.toggleAxes();
      }
    };

    const toggleWireframe = () => {
      if (threeJSManager) {
        threeJSManager.toggleWireframe();
      }
    };

    const defaultView = () => {
      if (threeJSManager) {
        threeJSManager.defaultView();
      }
    };

    const handleOpacityChange = (index) => {
      if (threeJSManager) {
        threeJSManager.handleOpacityChange(index);
      }
    };

    if (shineSlider) {
      shineSlider.addEventListener('input', changeShineness);
    }

    if (axisButton) {
      axisButton.addEventListener('click', toggleAxes);
    }

    if (wireframeButton) {
      wireframeButton.addEventListener('click', toggleWireframe);
    }

    if (viewButton) {
      viewButton.addEventListener('click', defaultView);
    }

    opacitySliders.forEach((slider, index) => {
      slider.addEventListener('input', () => {
        handleOpacityChange(index);
      });
    });

    return () => {
      if (shineSlider) {
        shineSlider.removeEventListener('input', changeShineness);
      }

      if (axisButton) {
        axisButton.removeEventListener('click', toggleAxes);
      }

      if (wireframeButton) {
        wireframeButton.removeEventListener('click', toggleWireframe);
      }

      if (viewButton) {
        viewButton.removeEventListener('click', defaultView);
      }

      opacitySliders.forEach((slider, index) => {
        slider.removeEventListener('input', () => {
          handleOpacityChange(index);
        });
      });
    };
  }, [threeJSManager, isRaycasterActive, brushing,margin, distanceView]);



  /// Tooling useEffect

  function pentBezier(t, p0, p1, p2, p3, p4, p5){
    const u = 1-t;
    return (u * u * u * u * u * p0) +
            (5 * u * u * u * u * t * p1) +
            (10 * u * u * u * t * t * p2) +
            (10 * u * u * t * t * t * p3) +
            (5 * u * t * t * t * t * p4) +
            (t * t * t * t * t * p5);
  }

  function addFace(add, point, intnormal, kdtree, geometry) {
    if (scene) {
      const startTime1 = performance.now();
      // const geometries = U.parseGeometry(geometry);
      // const vertices = geometries[0];
      const positions = geometry.attributes.position.array;
      // const normals = geometry.attributes.normal.array; // Access vertex normals
      // const brushingRadius = object.material.uniforms.brushradius.value;
      // const brushingStrength = object.material.uniforms.brushingStrength.value;
      const startTime2 = performance.now();


      // Define the selected point and deformation parameters
      let strength = brushingStrength ? brushingStrength : 1;
      console.log(brushingRadius, brushingStrength);


      const brushingRadiusSquared = brushingRadius * brushingRadius;

      const vertex = new THREE.Vector3(0,0,0);
      // Bezier control points for falloff
      const p0 = 1.0;  
      const p1 = 1.0;  
      const p2 = 1.0; 
      const p3 = 0.0; 
      const p4 = 0.0;
      const p5 = 0.0;

      // console.log(object);
      

      kdtree.rnn([point.x,point.y,point.z], brushingRadius, function(j) {
        let i = index_allowed_map[j];
        vertex.set(positions[i*3], positions[i*3 + 1], positions[i*3 + 2]);
        
        
        const dx = vertex.x - point.x;
        const dy = vertex.y - point.y;
        const dz = vertex.z - point.z;

    
        // Compute squared distance to avoid expensive sqrt
        const distanceSquared = dx * dx + dy * dy + dz * dz;

        // const distance = vertex.distanceTo(intersectPoint);

        // Deform vertices within brush radius
        if (distanceSquared < brushingRadiusSquared) {
          // IdxSet.add(i);
          const distance = Math.sqrt(distanceSquared);
          const t = distance / brushingRadius; // Normalize distance to range [0, 1]
          // const falloff = cubicBezier(t, p0, p1, p2, p3);
          const falloff = pentBezier(t, p0, p1, p2, p3, p4, p5);
          // count+=1;
          // const distance = Math.sqrt(distanceSquared);
          // const normal = new THREE.Vector3(
          //     normals[i * 3],
          //     normals[i * 3 + 1],
          //     normals[i * 3 + 2]
          // );
          vertex.addScaledVector(intnormal, add * falloff * strength);
            // vertex.addScaledVector(intnormal, 
            //   add * strength * (1 - (3 * distanceSquared / brushingRadiusSquared) + (2 * distanceSquared * Math.sqrt(distanceSquared)/ (brushingRadius * brushingRadiusSquared)))
            // );
            positions[i*3] = vertex.x;
            positions[i*3 + 1] = vertex.y;
            positions[i*3 + 2] = vertex.z;
        }
      })
      const startTime3 = performance.now();

      // Mark the position attribute as needing an update
      geometry.attributes.position.needsUpdate = true;

      // Recompute normals and bounding sphere for smooth rendering
      geometry.computeVertexNormals();
      // geometry.computeBoundingSphere();
      const startTime4 = performance.now();

      console.log('Mesh modification completed in ', startTime4- startTime3, startTime3-startTime2, startTime2-startTime1);
      if (renderer && camera) {
        renderer.render(scene, camera);
      } else {
        console.warn('Renderer or camera not available');
      }

    } else {
      console.error('Scene not available');
    }
  };
  function smoothenFrontend(point, kdtree, geometry) {
    if (scene) {
      // const object = scene.getObjectByName('crown_0');
      // if (object && object.geometry) {
        // const geometry = object.geometry;
        // const geometries = U.parseGeometry(geometry, false)
        // const vertices = geometries[0]
        const startTime1 = performance.now();
        const position = geometry.attributes.position.array;

        // Set parameters
        let strength = 0.2; // Default strengt
        const iterations = parseInt((smootheningStrength * 10 + 0.99999));
        console.log(iterations);
        // const neighborsCount = smootheningRadius;

        // Derive start point and index
        // const T0 = new KdTree(vertices)
        // const startInd = T0.knn([point.x, point.y, point.z], 1)[0];

        // Derive only connected points to localize the effect of deformation
        // const result = U.knn_bfsbrushing(geometry,adjList ,smootheningRadius, startInd, geometries[1],vertices,false,true);
        // const connectedNeighbours = result[0];
        const adjacency = adjList;

        // Iterate
        for (let iter = 0; iter < iterations; iter++) {
        kdtree.rnn([point.x,point.y,point.z], smootheningRadius, function(j) {
          let i = index_allowed_map[j];
          const currentPos = new THREE.Vector3(
            position[i*3], position[i*3 + 1], position[i*3 + 2]
          );
          
          // const neighbors = U.nRingNeighbors(adjacency, i, 1);
          const neighbors = adjacency[i];
          const avgPos = new THREE.Vector3();
          neighbors.forEach((neighborIndex) => {
            avgPos.add(new THREE.Vector3(
              position[neighborIndex*3], position[neighborIndex*3 + 1], position[neighborIndex*3 + 2]
            ));
          });
          avgPos.divideScalar(neighbors.size);

          // Calculate new position as weighted average
          const newPos = new THREE.Vector3().lerpVectors(currentPos, avgPos, strength);

          // Set the new position
          // position.setXYZ(i, newPos.x, newPos.y, newPos.z);
          position[i*3] = newPos.x;
          position[i*3 + 1] = newPos.y;
          position[i*3 + 2] = newPos.z;
        });
        }


        // for (let iter = 0; iter < iterations; iter++) {
        //   connectedNeighbours.forEach((i) => {
        //     const currentPos = new THREE.Vector3(
        //       position.getX(i),
        //       position.getY(i),
        //       position.getZ(i)
        //     );
            
        //     // const neighbors = U.nRingNeighbors(adjacency, i, 1);
        //     const neighbors = adjacency[i];
        //     const avgPos = new THREE.Vector3();
        //     neighbors.forEach((neighborIndex) => {
        //       avgPos.add(new THREE.Vector3(
        //         position.getX(neighborIndex),
        //         position.getY(neighborIndex),
        //         position.getZ(neighborIndex)
        //       ));
        //     });
        //     avgPos.divideScalar(neighbors.size);

        //     // Calculate new position as weighted average
        //     const newPos = new THREE.Vector3().lerpVectors(currentPos, avgPos, strength);

        //     // Set the new position
        //     position.setXYZ(i, newPos.x, newPos.y, newPos.z);
        //   });
        // }

        // Mark the position attribute as needing an update
        geometry.attributes.position.needsUpdate = true;

        // Update geometry to recalculate normals if necessary
        geometry.computeVertexNormals();
        const startTime2 = performance.now();
        // toCreasedNormals(geometry, (90 / 180) * Math.PI);

        // Ensure object matrices are up to date
        // object.updateMatrix();
        // object.updateMatrixWorld();

        // console.log('Smoothing completed');
        // setSmoothpoints([]);
        console.log("Mesh Smootheining in:", startTime2-startTime1);
        // Force a re-render
        if (renderer && camera) {
          renderer.render(scene, camera);
        } else {
          console.warn('Renderer or camera not available');
        }
    } else {
      console.error('Scene not available');
    }
  }
  const undoTooling = () => {
    if (scene) {
        const object = scene.getObjectByName('crown_0');
        if (object && object.geometry && undoStack.length > 0) {
            // Retrieve the previous geometry from the undo stack
            const prevGeo = undoStack[undoStack.length - 1];
            setRedoStack([...redoStack, object.geometry]);
            object.geometry = prevGeo;

            // Update the undo stack
            setUndoStack(undoStack.slice(0, undoStack.length - 1));

            // Mark position attribute for update
            object.geometry.needsUpdate = true;

            // Recompute bounding sphere if necessary
            object.geometry.computeBoundingSphere();

            // Trigger scene re-render
            renderer.render(scene, camera); // Assuming `renderer` and `camera` are in scope
        }
    }
};

const redoTooling = () => {
    if (scene) {
        const object = scene.getObjectByName('crown_0');
        if (object && object.geometry && redoStack.length > 0) {
            // Retrieve the next geometry from the redo stack
            const nextGeo = redoStack[redoStack.length - 1];
            setUndoStack([...undoStack, object.geometry]);
            object.geometry = nextGeo;

            // Update the redo stack
            setRedoStack(redoStack.slice(0, redoStack.length - 1));

            // Mark position attribute for update
            object.geometry.needsUpdate = true;

            // Recompute bounding sphere if necessary
            object.geometry.computeBoundingSphere();

            // Trigger scene re-render
            renderer.render(scene, camera); // Assuming `renderer` and `camera` are in scope
        }
    }
}


  function getDist(point) {
    if (inner_surface) {
      const dist = U.distance([point.x, point.y, point.z], inner_surface);
      setInner_distance(dist);
    }
  }

  // const ToggleHeatmap= () => {
  //   setHeatmap(!heatmap);
  //   if(scene){
  //     const crownObject = scene.getObjectByName('crown_0');
  //     if(crownObject){
  //       crownObject.material.uniforms.isThick.value = heatmap;
  //     }
  //   }
  // }

  const handleToggleVisibility = (index) => {
    if (threeJSManager) {
      const updatedSliderData = [...sliderData];
      updatedSliderData[index].visible = !updatedSliderData[index].visible;
      setSliderData(updatedSliderData);
      if(index===0){
        const object = scene.getObjectByName(`prep`);
        object.visible = !object.visible;
      }
      else if(index===1){
        threeJSManager.handleToggleVisibilityClick(index);
      }
      else{
        const object = scene.getObjectByName(`crown_${index-2}`);
        object.visible = !object.visible;
      }
      // if(index>=2){
      //   const object = scene.getObjectByName(`crown_${index-2}`);
      //   object.visible = !object.visible;
      // }
      // else{
      //   threeJSManager.handleToggleVisibilityClick(index);
      // }
    }
  };

  const updateCrownArrayBeforeExport = (callback) => {
    if (scene) {
      const exporter = new STLExporter();
      const newCrownArray = crownarray.map((crown, index) => {
        const object = scene.getObjectByName(`crown_${index}`);
        if (object) {
          const stlString = exporter.parse(object);
          const blob = new Blob([stlString], { type: 'text/plain' });
          return new File([blob], `crown_${index}.stl`);
        }
        return crown;
      });
      setCrownarray(newCrownArray);
      console.log('Crown array updated before export:', newCrownArray);
      if (callback) callback(newCrownArray);
    }
  };

  const handlesave = () => {
    updateCrownArrayBeforeExport((updatedCrownArray) => {
      navigate("/export", {
        state: {
          file1, file2, crown,marginCurve,uuid_crown,crownFirst, marginCenter,allowed_points ,thickness, position, axis, prepView, antaView, inner_surface,exportelements: updatedCrownArray,thickness_allowed_points,crown_axis,prepNumber,
          selectedOption, numberingSystem
        }
      });
    });
  };

  const handleGoBack= () => {
    navigate("/prep",{
      state: {
        file1, file2,margin_backend:marginCurve,folder_id:uuid_crown,upper_lower:true, marginCenter, selectedOption ,prepView, antaView, position,tooth_number: prepNumber, numberingSystem}
    })
  }
  

  const handleAlertClose = () => {
    setError(false);
  };
  const brushingTool = () => {
    if (!brushing) {
      setIsRaycasterActive(true);
    }
    const object = scene.getObjectByName('crown_0');
    object.material.uniforms.uHoverPosition.value.set(-1000, -1000, -1000);
    setBrushing(!brushing);
    setSmooth(false);
    setDeformation(false);
    setDistanceView(false);
    setMarginManipulationTool(false);
    setToggleCrownInfo(false);
  }

  const marginEdit = () => {
    setMarginManipulationTool(!marginManipulationTool);
    setDistanceView(false);
    setSmooth(false);
    setDeformation(false);
    setBrushing(false);
    setToggleCrownInfo(false);

  }
  const smoothTool = () => {
    if (!smooth) {
      setIsRaycasterActive(true);
    }
    const object = scene.getObjectByName('crown_0');
    object.material.uniforms.uHoverPosition.value.set(-1000, -1000, -1000);
    setSmooth(!smooth);
    setDistanceView(false);
    setDeformation(false);
    setMarginManipulationTool(false);
    setBrushing(false);
    setToggleCrownInfo(false);

  }
  const getNewMargin = () => {
    const numPoints = 1500;
    const points = [];
    const curveLength = curveRef.current.getLength();
    const segmentLength = curveLength / numPoints; 
    let currentLength = 0;
  
    for (let i = 0; i < numPoints; i++) {
      // Find the point at the desired arc length
      const t = curveRef.current.getUtoTmapping(currentLength / curveLength);
      const point = curveRef.current.getPoint(t);
      points.push(point);
      currentLength += segmentLength;
    }
  
    // Add the final point explicitly to close the curve
    points.push(curveRef.current.getPoint(1));
  
    // Create PCD header
    let header = `# .PCD v0.7 - Point Cloud Data\n`;
    header += `VERSION 0.7\n`;
    header += `FIELDS x y z\n`;
    header += `SIZE 4 4 4\n`;
    header += `TYPE F F F\n`;
    header += `COUNT 1 1 1\n`;
    header += `WIDTH ${points.length}\n`;
    header += `HEIGHT 1\n`;
    header += `VIEWPOINT 0 0 0 1 0 0 0\n`;
    header += `POINTS ${points.length}\n`;
    header += `DATA ascii\n`;
  
    // Create points data
    let pointsData = '';
    points.forEach((point) => {
      const x = point.x.toFixed(6);
      const y = point.y.toFixed(6);
      const z = point.z.toFixed(6);
      pointsData += `${x} ${y} ${z}\n`;
    });
  
    // Combine header and points data
    const pcdData = header + pointsData;
    return pcdData;
  };
  const handleRegenerate = async () => {
      const pcdData = getNewMargin();
      const MarginPCDfile = new Blob([pcdData], { type: 'text/plain' });
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append('file1', file1);
        formData.append('file2', file2);
        formData.append('margin', MarginPCDfile);
        formData.append("category", JSON.stringify(selectedOption))
        formData.append('folder_id', JSON.stringify(uuid_crown));
    
        const response = await fetch('https://api.dentalai.ai/gen_crown_anatomical/', {
          method: 'POST',
          body: formData,
        });
    
        if (response.ok) {
          const contentType = response.headers.get('content-type');
    
          if (contentType === 'application/json') {
            // Parse the JSON response
            const responseData = await response.json();
    
            // Extract data from the JSON response
            const {
              zip_file,
              margin_center: marginCenter,
              axis,
              thickness: thicknessData,
              inner_surface
            } = responseData;
    
            const thickness = thicknessData === true;
            const crownFirst = true;
    
            // Convert base64 zip data to a Blob
            const zipBlob = new Blob([Uint8Array.from(atob(zip_file), c => c.charCodeAt(0))], { type: 'application/zip' });
    
            // Process the ZIP blob to create separate File objects for each mesh
            const zip = await JSZip.loadAsync(zipBlob);
    
            const meshFiles = [];
            const promises = [];
    
            zip.forEach((relativePath, file) => {
              if (file.dir) return; // Ignore directories
              const promise = file.async('uint8array').then((data) => {
                const meshBlob = new Blob([data], { type: 'application/octet-stream' });
                const mesh = new File([meshBlob], file.name, { type: 'application/octet-stream' });
                meshFiles.push(mesh);
              });
              promises.push(promise);
            });
    
            // Wait for all promises to resolve
            await Promise.all(promises);

            setCrownarray(meshFiles);
            
            setError(false);
            setLoading(false);
            
    
          
          } else {
            setError(true);
            setLoading(false);
          }
        }
        else {
          setError(true);
          setLoading(false);
        }
      } catch (error) {
        setError(true);
        setLoading(false);
        console.error('Error generating crown:', error);
      }
      
    }

    const defomationTooling = () =>{
      setDeformation(!deformation);
      setMarginManipulationTool(false);
      setSmooth(false);
      setAnatomy(false);
      setBrushing(false);
      setDistanceView(false);
      setToggleCrownInfo(false);
  
    }

    const crownInfotool = () => {
      setToggleCrownInfo(!toggleCrownInfo);
      setMarginManipulationTool(false);
      setSmooth(false);
      setAnatomy(false);
      setBrushing(false);
      setDistanceView(false);
      setDeformation(false);

    }

    const brushingView = (
      <>
        <div
          className="p-3 text-center rounded-4"
          style={{
            display: brushing ? 'block' : 'none',
            background: "rgba(255, 255, 255, 1)",
          }}
        >
          {/* Header Row */}
          <div
            className="mb-1 d-flex justify-content-between align-items-center"
            style={{
              color: 'black',
              fontFamily: "Manrope, sans-serif",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            <div>Brush</div>
            <div
              onClick={brushingTool}
              style={{
                cursor: "pointer",
                color: "#FF4D4F",
                transition: "color 0.3s ease",
              }}
              onMouseEnter={(e) => (e.target.style.color = "#FF6B6B")}
              onMouseLeave={(e) => (e.target.style.color = "#FF4D4F")}
            >
              <Delete />
            </div>
          </div>
    
          {/* Controls Section */}
          <ul
            className="list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3"
            style={{
              background: "rgba(144, 200, 224, 0.3)",
              width: "100%",
            }}
          >
            <div
              className="d-flex flex-column justify-content-between"
              style={{
                color: 'black',
                fontFamily: "Manrope, sans-serif",
                fontSize: "14px",
                width: "100%",
              }}
            >
              {/* Radius Slider */}
              <div className="mb-2">Radius</div>
              <input
                type="range"
                id="brushingradius"
                min="0.1"
                max="4"
                step="0.1"
                defaultValue="2"
                style={{
                  width: "100%",
                  cursor: "pointer",
                  accentColor: "#1F555A",
                  height: "8px",
                  borderRadius: "5px",
                  background: "linear-gradient(to left, #1F555A, #90C8E0)",
                  outline: "none",
                  WebkitAppearance: "none",
                  appearance: "none",
                }}
              />
    
              {/* Strength Slider */}
              <div className="mt-3 mb-2">Strength</div>
              <input
                type="range"
                id="brushingstrength"
                min="0.001"
                max="0.3"
                step="0.001"
                defaultValue="0.05"
                style={{
                  width: "100%",
                  cursor: "pointer",
                  accentColor: "#1F555A",
                  height: "8px",
                  borderRadius: "5px",
                  background: "linear-gradient(to left, #1F555A, #90C8E0)",
                  outline: "none",
                  WebkitAppearance: "none",
                  appearance: "none",
                }}
              />
            </div>
          </ul>
    
          {/* Add Button */}
          <div
            className="mt-3 rounded"
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: addsub === 1 ? primaryColor : "#ffffff",
              color: addsub === 1 ? "#ffffff" : "black",
              border: "1px solid #1F555A",
              fontFamily: "Manrope, sans-serif",
              fontSize: "14px",
              fontWeight: "500",
              padding: "8px 12px",
              transition: "background-color 0.3s ease, color 0.3s ease",
            }}
            onClick={() => setaddsub(1)}
          >
            Add
          </div>
    
          {/* Sub Button */}
          <div
            className="mt-3 rounded"
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: addsub === -1 ? primaryColor : "#ffffff",
              color: addsub === -1 ? "#ffffff" : "black",
              border: "1px solid #1F555A",
              fontFamily: "Manrope, sans-serif",
              fontSize: "14px",
              fontWeight: "500",
              padding: "8px 12px",
              transition: "background-color 0.3s ease, color 0.3s ease",
            }}
            onClick={() => setaddsub(-1)}
          >
            Sub
          </div>
        </div>
      </>
    );
    


    const marginmanipultaionView = (
      <>
        <div
          className="p-3 text-center rounded-4"
          style={{
            display: marginManipulationTool ? "block" : "none",
            zIndex: "4",
            background: "rgba(255, 255, 255, 1)",
            boxShadow: "0 0 8px rgba(144, 200, 224, 0.4)",
          }}
        >
          {/* Header Row */}
          <div
            className="mb-1 d-flex justify-content-between align-items-center"
            style={{
              color: "black",
              fontFamily: "Manrope, sans-serif",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            <div>Margin</div>
            <div
              onClick={() => setMarginManipulationTool(false)}
              style={{
                cursor: "pointer",
                color: "#FF4D4F",
                transition: "color 0.3s ease",
              }}
              onMouseEnter={(e) => (e.target.style.color = "#FF6B6B")}
              onMouseLeave={(e) => (e.target.style.color = "#FF4D4F")}
            >
              <Delete />
            </div>
          </div>
    
          {/* Regenerate Button */}
          <button
            className="btn"
            style={{
              backgroundColor: primarycolor,
              color: "#ffffff",
              fontWeight: "bolder",
              fontFamily: "Manrope, sans-serif",
              fontSize: "14px",
              border: "none",
              padding: "8px 12px",
              cursor: "pointer",
              transition: "background-color 0.3s ease, color 0.3s ease",
            }}
            onClick={handleRegenerate}
          >
            Regenerate
          </button>
        </div>
      </>
    );
    
  const distTool = () => {
    if (!isRaycasterActive) {
      setIsRaycasterActive(true);
    }
    setDistanceView(!distanceView);
    setSmooth(false);
    setDeformation(false);
    setMarginManipulationTool(false);
    setMargin(false);
    setBrushing(false);

  }
  const calculatDistanceView = (
    <>
      <div
        className="p-3 text-center rounded-4"
        style={{
          display: distanceView ? "block" : "none",
          zIndex: "4",
          background: "rgba(255, 255, 255, 1)",
          boxShadow: "0 0 8px rgba(144, 200, 224, 0.4)",
        }}
      >
        {/* Header Row */}
        <div
          className="mb-1 d-flex justify-content-between align-items-center"
          style={{
            color: "black",
            fontFamily: "Manrope, sans-serif",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          <div>Thickness Tool</div>
          <div
            onClick={distTool}
            style={{
              cursor: "pointer",
              color: "#FF4D4F",
              transition: "color 0.3s ease",
            }}
            onMouseEnter={(e) => (e.target.style.color = "#FF6B6B")}
            onMouseLeave={(e) => (e.target.style.color = "#FF4D4F")}
          >
            <Delete />
          </div>
        </div>
  
        {/* Distance Section */}
        <ul
          className="list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3"
          style={{
            background: "rgba(144, 200, 224, 0.3)",
            width: "100%",
          }}
        >
          <div
            className="d-flex justify-content-between flex-column"
            style={{
              color: "black",
              fontFamily: "Manrope, sans-serif",
              fontSize: "14px",
              width: "100%",
            }}
          >
            <div className="d-flex flex-row justify-content-center mt-2">
              Thickness:{" "}
              {inner_distance ? (
                <div>{parseFloat(inner_distance).toFixed(4)} mm</div>
              ) : (
                0
              )}
            </div>
          </div>
        </ul>
  
        {/* Separator */}
        <hr
          style={{
            width: "100%",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            marginTop: "16px",
          }}
        />
      </div>
    </>
  );
  
  // const DeformationView = (
  //   <>
  //     <div className="p-3 text-center position-absolute rounded-4" style={{
  //       display: deformation ? 'block' : 'none',
  //      zIndex: "4", background: "rgba(255, 255, 255, 1)", boxShadow: "0 0 2px 2px rgba(144, 200, 224, 0.4)"
  //     }}>
  //       <div className='mb-3 d-flex justify-content-between align-items-center'>
  //         <div>Deformation</div>
  //         <div onClick={()=>{
  //           setDeformation(false)
  //           setRotatetool(false)
  //           setScaletool(false)
  //           setTranslatetool(false)
            
  //           }}><Delete /></div>
  //       </div>
  //       <div className='mt-3 rounded' style={{
  //         cursor: "pointer",
  //         display: 'flex',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         backgroundColor: primaryColor,
  //         color: '#fff',
  //         border: "1px solid #1F555A",
  //         transition: 'background-color 0.3s ease',
  //       }} onClick={()=>{
  //         setRotatetool(true)
  //         setScaletool(false)
  //         setTranslatetool(false)}
          
  //         } >Rotate</div>

  //       <div className='mt-3 rounded' style={{
  //         cursor: "pointer",
  //         display: 'flex',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         backgroundColor: primaryColor,
  //         color: '#fff',
  //         border: "1px solid #1F555A",
  //         transition: 'background-color 0.3s ease',
  //       }} onClick={()=>{
  //         setRotatetool(false)
  //         setScaletool(true)
  //         setTranslatetool(false)}
          
  //         }  >Scale</div>

  //       <div className='mt-3 rounded' style={{
  //         cursor: "pointer",
  //         display: 'flex',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         backgroundColor: primaryColor,
  //         color: '#fff',
  //         border: "1px solid #1F555A",
  //         transition: 'background-color 0.3s ease',
  //       }} onClick={()=>{
  //         setRotatetool(false)
  //         setScaletool(false)
  //         setTranslatetool(true)}
          
  //         }  >Translate</div>

        

  //     </div>
  //   </>
  // )

  const DeformationView = (
    <>
      <div
        className="p-3 text-center rounded-4"
        style={{
          display: deformation ? "block" : "none",
          zIndex: "4",
          background: "rgba(255, 255, 255, 1)",
          boxShadow: "0 0 8px rgba(144, 200, 224, 0.4)",
        }}
      >
        {/* Header Row */}
        <div
          className="mb-1 d-flex justify-content-between align-items-center"
          style={{
            color: "black",
            fontFamily: "Manrope, sans-serif",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          <div>Deformation</div>
          <div
            onClick={() => setDeformation(false)}
            style={{
              cursor: "pointer",
              color: "#FF4D4F",
              transition: "color 0.3s ease",
            }}
            onMouseEnter={(e) => (e.target.style.color = "#FF6B6B")}
            onMouseLeave={(e) => (e.target.style.color = "#FF4D4F")}
          >
            <Delete />
          </div>
        </div>
  
        {/* Controls Section */}
        <ul
          className="list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3"
          style={{
            background: "rgba(144, 200, 224, 0.3)",
            width: "100%",
          }}
        >
          <div
            className="d-flex flex-column justify-content-between"
            style={{
              color: "black",
              fontFamily: "Manrope, sans-serif",
              fontSize: "14px",
              width: "100%",
            }}
          >
            {/* Radius Slider */}
            <div className="mb-2">Radius</div>
            <input
              type="range"
              id="deformationradius"
              min="0.7"
              max="5.5"
              step="0.1"
              defaultValue="3"
              style={{
                width: "100%",
                cursor: "pointer",
                accentColor: "#1F555A",
                height: "8px",
                borderRadius: "5px",
                background: "linear-gradient(to left, #1F555A, #90C8E0)",
                outline: "none",
                WebkitAppearance: "none",
                appearance: "none",
              }}
            />
          </div>
        </ul>
  
        {/* Separator */}
        <hr
          style={{
            width: "100%",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            marginTop: "16px",
          }}
        />
      </div>
    </>
  );
  

  // const prepselection = (
  //   <div className="p-3 text-center position-absolute rounded-4" style={{
  //     display: crownView ? 'block' : 'none',
  //     minWidth: "15vw", zIndex: "4", background: "rgba(255, 255, 255, 1)", top: "15%", left: "-160%", transform: "translate(-50%, -50%)", boxShadow: "0 0 2px 2px rgba(144, 200, 224, 0.4)"
  //   }}>

  //     <div className='mb-3 d-flex justify-content-between align-items-center'>
  //       <div>Crown Properties</div>
  //       <div onClick={showCrown}><Delete /></div>
  //     </div>

  //     <ul className='list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3' style={{ background: "rgba(144, 200, 224, 0.3)" }}>
  //       {crown.map((item, index) => (
  //         <div key={index} className='d-flex flex-column'>
  //           <div className="d-flex flex-row " style={{ justifyContent: "space-between" }}>
  //             <div className='mx-3'>Crown {index}</div>
  //             <div className='mx-3' id={`toggleVisibilityButton-${index + 2}`}
  //               style={{
  //                 cursor: 'pointer',
  //                 transition: 'background-color 0.3s ease',
  //               }}
  //               onClick={() => handleToggleVisibility(index + 2)}><Visiblity /></div>
  //           </div>
  //           <div className='d-flex flex-row'>
  //             <input
  //               style={{ display: 'flex' }}
  //               type="range"
  //               id={`opacitySlider-${index}`}
  //               min="0.1"
  //               max="1"
  //               step="0.01"
  //               defaultValue="1"
  //             />


  //           </div>
  //           <div className='d-flex flex-row'>
  //             <input
  //               style={{ display: 'none' }}
  //               type="range"
  //               id={`shine`}
  //               min="0.1"
  //               max="1"
  //               step="0.01"
  //               defaultValue="1"
  //             />


  //           </div>
  //           {/* <ChromePicker color={crownColor} ref={color} onChangeComplete={handleColorChange} /> */}
  //         </div>
  //       ))}
  //     </ul>

  //   </div>
  // );
  const smoothView = (
    <>
      <div
        className="p-3 text-center rounded-4"
        style={{
          display: smooth ? "block" : "none",
          zIndex: "4",
          background: "rgba(255, 255, 255, 1)",
          boxShadow: "0 0 8px rgba(144, 200, 224, 0.4)",
        }}
      >
        {/* Header Row */}
        <div
          className="mb-1 d-flex justify-content-between align-items-center"
          style={{
            color: "black",
            fontFamily: "Manrope, sans-serif",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          <div>Smooth</div>
          <div
            onClick={smoothTool}
            style={{
              cursor: "pointer",
              color: "#FF4D4F",
              transition: "color 0.3s ease",
            }}
            onMouseEnter={(e) => (e.target.style.color = "#FF6B6B")}
            onMouseLeave={(e) => (e.target.style.color = "#FF4D4F")}
          >
            <Delete />
          </div>
        </div>
  
        {/* Controls Section */}
        <ul
          className="list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3"
          style={{
            background: "rgba(144, 200, 224, 0.3)",
            width: "100%",
          }}
        >
          <div
            className="d-flex flex-column justify-content-between"
            style={{
              color: "black",
              fontFamily: "Manrope, sans-serif",
              fontSize: "14px",
              width: "100%",
            }}
          >
            {/* Radius Slider */}
            <div className="mb-2">Radius</div>
            <input
              type="range"
              id="smootheningradius"
              min="0.1"
              max="4"
              step="0.1"
              defaultValue="2"
              style={{
                width: "100%",
                cursor: "pointer",
                accentColor: "#1F555A",
                height: "8px",
                borderRadius: "5px",
                background: "linear-gradient(to left, #1F555A, #90C8E0)",
                outline: "none",
                WebkitAppearance: "none",
                appearance: "none",
              }}
            />
  
            {/* Strength Slider */}
            <div className="mt-3 mb-2">Strength</div>
            <input
              type="range"
              id="smootheningstrength"
              min="0.1"
              max="1"
              step="0.1"
              defaultValue="0.3"
              style={{
                width: "100%",
                cursor: "pointer",
                accentColor: "#1F555A",
                height: "8px",
                borderRadius: "5px",
                background: "linear-gradient(to left, #1F555A, #90C8E0)",
                outline: "none",
                WebkitAppearance: "none",
                appearance: "none",
              }}
            />
          </div>
        </ul>
  
        {/* Separator */}
        <hr
          style={{
            width: "100%",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            marginTop: "16px",
          }}
        />
      </div>
    </>
  );
  const tooling = (
    <div
      className="p-2 text-center rounded-4"
      style={{
        display: tool ? 'block' : 'none',
        zIndex: '4',
        background: 'rgba(255, 255, 255, 1)',
      }}
    >
      <ul className="list-group p-2 rounded-3">
        {/* First Row */}
        <div className="row mb-3">
          {/* Brush Tool */}
          <div className="col-3 d-flex flex-column align-items-center mx-2">
            <button
              className="rounded mb-2"
              onClick={brushingTool}
              style={{
            backgroundColor: brushing ? "rgba(144, 200, 224, 0.9)" :"#ffffff",
                border: '1px solid #1F555A',
              }}
            >
              <Brush style={{ width: '20px', height: '20px' }} />
            </button>
            <div className="fw-light" style={{ fontSize: '12px' }}>Brush</div>
          </div>
  
          {/* Margin Tool */}
          {/* <div className="col-3 d-flex flex-column align-items-center mx-2">
            <button
              id="prep"
              className="rounded mb-2"
              onClick={marginEdit}
              style={{
                border: '1px solid #1F555A',
            backgroundColor: marginManipulationTool? "rgba(144, 200, 224, 0.9)" :"#ffffff",

              }}
            >
              <Margin style={{ fill: '#ffffff', width: '20px', height: '20px' }} />
            </button>
            <div className="fw-light" style={{ fontSize: '12px' }}>Margin</div>
          </div> */}
  
          {/* Smooth Tool */}
          <div className="col-3 d-flex flex-column align-items-center mx-2">
            <button
              className="rounded mb-2"
              onClick={smoothTool}
              style={{
            backgroundColor: smooth? "rgba(144, 200, 224, 0.9)" :"#ffffff",

                border: '1px solid #1F555A',
              }}
            >
              <Smooth style={{ fill: '#ffffff', width: '20px', height: '20px' }} />
            </button>
            <div className="fw-light" style={{ fontSize: '12px' }}>Smooth</div>
          </div>

          <div className="col-3 offset-1 d-flex flex-column align-items-center mx-2">
            <button
              className="rounded mb-2"
              onClick={distTool}
              style={{
            backgroundColor: distanceView? "rgba(144, 200, 224, 0.9)" :"#ffffff",

                border: '1px solid #1F555A',
              }}
            >
              <Thickness style={{ width: '20px', height: '20px' }} />
            </button>
            <div className="fw-light" style={{ fontSize: '12px' }}>Thickness</div>
          </div>
        </div>

        
  
        {/* Second Row */}
        <div className="row ">
          {/* Thickness Tool (below top-left icon) */}
          
  
          {/* Deformation Tool (below middle icon) */}
          <div className="col-3 d-flex flex-column align-items-center mx-2">
            <button
              className="rounded mb-2"
              onClick={defomationTooling}
              style={{
            backgroundColor: deformation? "rgba(144, 200, 224, 0.9)" :"#ffffff",

                border: '1px solid #1F555A',
              }}
            >
              <Deformation style={{ width: '20px', height: '20px' }} />
            </button>
            <div className="fw-light" style={{ fontSize: '12px' }}>Deform</div>
          </div>


          {/* Info Tool (below middle icon) */}
          <div className="col-3 d-flex flex-column align-items-center mx-2">
            <button
              className="rounded mb-2"
              onClick={crownInfotool}
              style={{
            backgroundColor: toggleCrownInfo? "rgba(144, 200, 224, 0.9)" :"#ffffff",

                border: '1px solid #1F555A',
              }}
            >
              <Info style={{ width: '20px', height: '20px' }} />
            </button>
            <div className="fw-light" style={{ fontSize: '12px' }}>Info</div>
          </div>
        </div>
      </ul>
    </div>
  );

  const CrownInfoView = (
    <div
      className="p-2 text-center rounded-3"
      style={{
        display: toggleCrownInfo ? "block" : "none",
        zIndex: "4",
        background: "rgba(255, 255, 255, 1)",
        boxShadow: "0 0 6px rgba(144, 200, 224, 0.3)",
        width: "auto",
      }}
    >
      {/* Header Row */}
      <div
        className="mb-1 d-flex"
        style={{
          width: "100%",
          gap: "5px",
        }}
      >
        <button
        onClick={() => {setInfoMode("3d")}}

          style={{
            flex: "1",
            padding: "5px",
            background: infoMode === "3d" ? "rgba(144, 200, 224, 0.9)" :"#ffffff",
            fontWeight: "500",
            borderRadius: "5px",
            borderWidth: "0.5px",
            borderColor: "rgba(144, 200, 224, 0.9)",
            cursor: "pointer",
          }}
        >
          3-D
        </button>
        <button
        onClick={() => {setInfoMode("milling")}}
          style={{
            flex: "1",
            padding: "5px",
            background:  infoMode === "milling" ? "rgba(144, 200, 224, 0.9)" :"#ffffff",
            fontWeight: "500",
            borderRadius: "5px",
            borderWidth: "0.5px",
            borderColor: "rgba(144, 200, 224, 0.9)",
            cursor: "pointer",
          }}
        >
          Milling
        </button>
        <div
          onClick={crownInfotool}
          style={{
            cursor: "pointer",
            color: "#FF4D4F",
            transition: "color 0.3s ease",
          }}
          onMouseEnter={(e) => (e.target.style.color = "#FF6B6B")}
          onMouseLeave={(e) => (e.target.style.color = "#FF4D4F")}
        >
          <Delete />
        </div>
      </div>

  
      {/* Controls Section */}
      <div
        className="p-2 rounded-2"
        style={{
          background: "rgba(144, 200, 224, 0.2)",
          fontSize: "10px",
          width: "100%",
        }}
      >
        {/* Key-Value Pairs with Increment/Decrement Buttons */}
        {Object.entries( infoMode==="milling" ? valuesMilling  : values3D ).map(([key, value], index) => (
          <div className="d-flex justify-content-between align-items-center mb-1" key={index}>
            <div className="d-flex" style={{ width: "100%" }}>
              <span style={{ flex: "1", textAlign: "left" }}>{key}</span>
              <span style={{ flex: "0 0 10px", textAlign: "center" }}>:</span>
              <div className="d-flex align-items-center" style={{ flex: "1", justifyContent: "space-evenly" }}>
                <button
                  onClick={() => handleChange(key, -0.1)}
                  style={{
                    border: "none",
                    background: "#ddd",
                    padding: "2px 5px",
                    cursor: "pointer",
                    borderRadius: "3px",
                    marginRight: "5px",
                  }}
                >
                  -
                </button>
                <span style={{ fontWeight: "bold", minWidth: "40px", textAlign: "center" }}>
                  {value.toFixed(2)} {key.includes("angle") ? "°" : "mm"}
                </span>
                <button
                  onClick={() => handleChange(key, 0.1)}
                  style={{
                    border: "none",
                    background: "#ddd",
                    padding: "2px 5px",
                    cursor: "pointer",
                    borderRadius: "3px",
                    marginLeft: "5px",
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
  
  
  
  
  
  
  
  
  const adjustment = (
    <div>
      <div className="p-3 text-center rounded-4" style={{ zIndex: "2", background: "rgba(144, 200, 224, 0.3)", height:"94vh", boxShadow: "0 2px 4px rgba(144, 200, 224, 0.4)", }}>
        <div className='d-flex flex-column align-items-center justify-content-center p-1 rounded-3' style={
          {
            cursor: 'pointer',
            transition: "transform 1s ease"

          }
        }
        // onClick={showPanel}
        >
          {/* <MySVG className='mb-1' />
          <MySVG className='mb-1' /> */}
        </div>
        {/* Move prepview && prepselection inside the adjustment panel */}
        {/* {prepselection} */}
        {Adjustmentpanel && (<>
          <div className='d-flex flex-column align-items-center justify-content-center p-1 rounded-3' style={{ background: "rgba(255,255,255,1" }}>
            {/* Render prepview */}


            {/* <div>Crown</div> */}
            {/* <OverlayTrigger
              placement="left"
              overlay={<Tooltip >Adjust Crowns Visiblity</Tooltip>}
            >
              <button id="prep" className='rounded' onClick={showCrown} title='Use this button to Change prep selection' style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px',
                backgroundColor: primaryColor,
                color: '#fff',
                border: "1px solid #1F555A",
                transition: 'background-color 0.3s ease',
              }}
              >
                <MySVG4 />
              </button>
            </OverlayTrigger> */}
<ul
  className="list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3"
  style={{ background: "rgba(255, 255, 255, 1)" }}
>
  {crown.map((item, index) => (
    <div
      key={index}
      className="d-flex flex-column align-items-center mb-3"
      style={{
        background: "rgba(255,255,255,0.9)",
      }}
    >
      {/* Header Row: Crown Name and Visibility Toggle */}
      <div
        className="d-flex flex-row justify-content-between align-items-center w-100"
        style={{ marginBottom: "12px" }}
      >
        <div
          style={{
            fontFamily: "Manrope, sans-serif",
            fontSize: "14px",
            fontWeight: "500",
            color: "#1F555A",
          }}
        >
          Crown {index + 1}
        </div>
        <div
          id={`toggleVisibilityButton-${index + 2}`}
          style={{
            cursor: "pointer",
          }}
          onClick={() => handleToggleVisibility(index + 2)}
        >
          {sliderData[index + 2].visible ? <Eye /> : <EyeOff />}
        </div>
      </div>

      {/* Opacity Slider */}
      <div className="d-flex flex-row w-100 align-items-center">
        <input
          type="range"
          id={`opacitySlider-${index}`}
          min="0.1"
          max="1"
          step="0.01"
          defaultValue="1"
          style={{
            width: "100%",
            cursor: "pointer",
            accentColor: "rgba(144, 200, 224, 0.9)",
            height: "8px",
            borderRadius: "5px",
            background: "linear-gradient(to left, #1F555A, #90C8E0)",
            outline: "none",
            WebkitAppearance: "none",
            appearance: "none",
          }}
        />
        {/* Add styling for the slider thumb */}
        <style>
          {`
          input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #1F555A;
            border: 2px solid #90C8E0;
            cursor: pointer;
            transition: background-color 0.3s ease;
          }

          input[type="range"]::-webkit-slider-thumb:hover {
            background: #90C8E0;
          }

          input[type="range"]::-moz-range-thumb {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #1F555A;
            border: 2px solid #90C8E0;
            cursor: pointer;
            transition: background-color 0.3s ease;
          }

          input[type="range"]::-moz-range-thumb:hover {
            background: #90C8E0;
          }
          `}
        </style>
      </div>
    </div>
  ))}
</ul>


          </div>

          <hr style={{ width: "100%", border: "1px solid #000" }} />

          <div
  className="d-flex flex-row align-items-center justify-content-center p-2 rounded-3"
  style={{ background: "rgba(255, 255, 255, 1)" }}
>
  {/* First Row */}
  <div className="row justify-content-center">
    {/* Model 0 */}
    <div className="col-auto d-flex flex-column align-items-center mx-2">
      
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Toggle Prep/Anta visibility</Tooltip>}
      >
        <div
          className="rounded-3 d-flex justify-content-center align-items-center"
          id={"toggleVisibilityButton-0"}
          style={{
            width: "40px",
            height: "40px",
            border: "1px solid #1F555A",
            backgroundColor: sliderData[0].visible? "rgba(144, 200, 224, 0.9)" :"#ffffff",
            cursor: "pointer",
          }}
          onClick={() => handleToggleVisibility(0)}
        >
          <PrepIcon />
          {/* {sliderData[0].visible ? <MySVG1 /> : <MySVG8 />} */}
        </div>
      </OverlayTrigger>
      <div className="fw-light" style={{ fontSize: '12px' }}>{sliderData[0].name}</div>

        
    </div>

    {/* Model 1 */}
    <div className="col-auto d-flex flex-column align-items-center mx-2">

      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Toggle Prep/Anta visibility</Tooltip>}
      >
        <div
          className="rounded-3 d-flex justify-content-center align-items-center"
          id={"toggleVisibilityButton-1"}
          style={{
            width: "40px",
            height: "40px",
            border: "1px solid #1F555A",
            cursor: "pointer",
            backgroundColor: sliderData[1].visible? "rgba(144, 200, 224, 0.9)" :"#ffffff",

            
          }}
          onClick={() => handleToggleVisibility(1)}
        >
          <AntaIcon />
          {/* {sliderData[1].visible ? <MySVG9 /> : <MySVG7 />} */}
        </div>
      </OverlayTrigger>
    <div className="fw-light" style={{ fontSize: '12px' }}>{sliderData[1].name}</div>

    </div>
  </div>
</div>



          {/* <hr style={{ width: "100%", border: "1px solid #000" }} /> */}

          {/* <div className='d-flex flex-column align-items-center justify-content-center p-1 rounded-3' style={{ background: "rgba(255,255,255,1" }}>
            <div>Grid</div>
            <OverlayTrigger

              placement="left"
              overlay={<Tooltip >Show/Hide grid View
              </Tooltip>}
            >
              <button id='grid' className='rounded'
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '40px',
                  height: '40px',
                  backgroundColor: primaryColor,
                  color: '#fff',
                  border: "1px solid #1F555A",
                  transition: 'background-color 0.3s ease',
                }} ><MySVG3 color='#808080' /></button></OverlayTrigger>
            <div>Wireframe</div>
            <OverlayTrigger

              placement="left"
              overlay={<Tooltip >Show/Hide wireframe view
              </Tooltip>}
            >
              <button id='wireframe' className='rounded'
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '40px',
                  height: '40px',
                  backgroundColor: primaryColor,
                  color: '#fff',
                  border: "1px solid #1F555A",
                  transition: 'background-color 0.3s ease',
                }} >
                <MySVG2 />
              </button></OverlayTrigger>
          </div> */}


        </>
        )}




        <hr style={{ width: "100%", border: "1px solid #000" }} />
        {/* <div className='d-flex flex-column align-items-center justify-content-center p-1 rounded-3' style={{ background: "rgba(255,255,255,1" }}>
          <div>Toolbox</div>
          <button
            className='btn '
            style={{
              backgroundColor: primaryColor,
              textDecorationColor: "#ffffff",

              fontFamily: 'Manrope, sans-serif'
            }}
            onClick={() => { setTool(!tool) }}
          >
            <Toolbox />
          </button>
        </div> */}
        {tooling}
        <hr style={{ width: "100%", border: "1px solid #000" }} />

        {brushingView}
              {smoothView}
              {calculatDistanceView}
              {marginmanipultaionView}
              {DeformationView}
              {CrownInfoView}
      </div>
      


    </div>
  )


  return (
    <>
      <div className={` d-flex `} style={{ backgroundColor: "#ffffff", fontFamily: 'Manrope, sans-serif', fontWeight: "bold" }}>

        {loading && (
          <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: '9999',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            padding: '20px',
            borderRadius: '5px',
            textAlign: 'center',
            backdropFilter: 'blur(10px)',
            width: "50vw"
          }}>
            {/* <div className="mb-3">
            <h2 className="card-title mb-4 text-center fw-semibold">Do you know?</h2>
            <p>
              <strong> {randomFact.fact}</strong>
            </p>
          </div> */}
            <ClipLoader size={50} color="#007bff" loading={loading} />
          </div>
        )}

<div className='flex-1' style={{position: 'relative', width: '20vw', zIndex: '2' }}>
        {adjustment}
      </div>


        <div className="flex-1" >
          <div id="canvas-container" ref={containerRef} style={{ position: 'relative',width:"80vw" }}>
            {error && (
              <div className="alert alert-danger alert-dismissible fade show" role="alert" style={{ position: 'absolute', top: '10px', left: '10px', zIndex: '1000', width: "72vw" }}>
                Some Error Occured!
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleAlertClose}></button>
              </div>
            )}



<div className='position-absolute ' style={{
          left: '2%',
          bottom: '2%'
        }}>


          <button
            className='btn btn-primary mx-2 fs-5'
            style={{
              backgroundColor: undoStack.length > 0 ? "rgba(144, 200, 224, 0.9)" : "#808080",
              cursor: undoStack.length > 0 ? "pointer" : "not-allowed",
            }}
            onClick={undoTooling}
          >
            <Undo />
          </button>

          <button
            className='btn btn-primary fs-5 mx-2 '
            style={{
              backgroundColor: redoStack.length > 0 ? "rgba(144, 200, 224, 0.9)" : "#808080",
              cursor: redoStack.length > 0 ? "pointer" : "not-allowed",
            }}
            onClick={redoTooling}
          >
            <Redo />
          </button>


        </div>


            {/* <div style={{ zIndex: 2000, width:"10vw" , position: "absolute", left: "2%", top: "50%", cursor: "pointer" }} >
              {brushingView}
              {smoothView}
              {calculatDistanceView}
              {marginmanipultaionView}
              {DeformationView}
            </div> */}


          </div>
        </div>
        


        <div
  className="position-absolute"
  style={{
    right: "2%",
    bottom: "2%",
  }}
>
  <button
    className="btn"
    style={{
      backgroundColor: "rgba(144, 200, 224, 0.9)",
      fontWeight: "bolder",
      fontFamily: "Manrope, sans-serif",
      fontSize: "14px",
      padding: "10px 15px",
      cursor: "pointer",
      transition: "background-color 0.3s ease, color 0.3s ease",
    }}
    onClick={handlesave}
  >
    Export Crown <Right/>
  </button>
</div>

<div
  className="position-absolute"
  style={{
    right: "15%",
    bottom: "2%",
  }}
>
  <button
    className="btn"
    style={{
      backgroundColor: "rgba(144, 200, 224, 0.9)",
      fontWeight: "bolder",
      fontFamily: "Manrope, sans-serif",
      fontSize: "14px",
      padding: "10px 15px",
      cursor: "pointer",
      transition: "background-color 0.3s ease, color 0.3s ease",
    }}
    onClick={handleGoBack}
  >
   <Left/> Back
  </button>
</div>


<div
  className="position-absolute"
  style={{
    right: "2%",
    top: "8%",
  }}
>
  <div style={{fontFamily: "Manrope, sans-serif",
              fontSize: "16px",
              fontWeight: "500"}}>
    Thickness
  </div>

<div
            style={{
              height: "10px",
              width: "15vw",
              background: "linear-gradient(to right, red, yellow, green, blue)",
              marginTop: "5px",
              borderRadius: "5px",
            }}
          ></div>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
            <span>0.1</span>
            <span>0.3</span>
            <span>0.5</span>
</div>

</div>


      </div>
    </>
  );
};

export default Crown;