import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, Card, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Typography, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { IoAddCircleSharp } from 'react-icons/io5';
import { ClipLoader } from 'react-spinners';
import dentalFacts from './Json/dentalFacts.json';
import { useNavigate } from 'react-router-dom';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {ReactComponent as Info} from '../assets/info.svg';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';


const Fileselect = () => {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [selectedOption, setSelectedOption] = useState('molar');
  const randomFact = dentalFacts[Math.floor(Math.random() * dentalFacts.length)];
  const canvasRef = useRef(null);
  const scene = useRef(null);
  const [crownfile, setCrownfile] = useState([]);
  const [fileValid, setFileValid] = useState(true);
  const primaryColor = '#213F99';
  const debugToggle = false;
  const margintest = true;
  const [prepNumber, setPrepNumber] = useState(null);
  const [numberingSystem, setNumberingSystem] = useState('UNIVERSAL'); // Default: Universal
  const [errorMessage, setErrorMessage] = useState('Some error occured!!!');

  const FDI_TO_UNIVERSAL = {
    11: 8,  12: 7,  13: 6,  14: 5,  15: 4,  16: 3,  17: 2,  18: 1,  
    21: 9,  22: 10, 23: 11, 24: 12, 25: 13, 26: 14, 27: 15, 28: 16,  
    31: 24, 32: 23, 33: 22, 34: 21, 35: 20, 36: 19, 37: 18, 38: 17,  
    41: 25, 42: 26, 43: 27, 44: 28, 45: 29, 46: 30, 47: 31, 48: 32  
};

// Creating FDI Map
const FDI = new Map(Object.entries(FDI_TO_UNIVERSAL).map(([key, value]) => [Number(key), value]));

// Creating UNIVERSAL Map
const UNIVERSAL = new Map(Object.entries(FDI_TO_UNIVERSAL).map(([key, value]) => [value, Number(key)]));



  const handleFileChange = (e, fileType) => {
    e.preventDefault();
    let file;
  
    if (e.dataTransfer && e.dataTransfer.files.length > 0) {
      // If files are dropped (drag and drop)
      file = e.dataTransfer.files[0];
    } else if (e.target && e.target.files.length > 0) {
      // If files are selected (file input)
      file = e.target.files[0];
    }
  
    if (!file) {
      // Handle case where no file is selected or dropped
    
      return;
    }
  
    // Handle the file based on the fileType (e.g., 'file1' or 'file2')
    switch (fileType) {
      case 'file1':
        setFile1(file);
        break;
      case 'file2':
        setFile2(file);
        break;
      case 'crown':
        const crownarr = [...crownfile, file]
        setCrownfile(crownarr)
      default:
       
    }
  };
  const checkIsloggedIn=() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      navigate('/signin');
    }
  }
  
  useEffect(() => {
    checkIsloggedIn();
    if (error) {
      const timer = setTimeout(() => setError(false), 3000);
      return () => clearTimeout(timer); // Cleanup on unmount
    }
  }, [error]);
  

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const prep = async () => {
    const token = sessionStorage.getItem('token');
    setLoading(true);
    if(numberingSystem === 'FDI') {
      if (!FDI.has(Number(prepNumber))) {
        setErrorMessage('Please enter a valid tooth number according to the FDI numbering system');
        setError(true);
        setLoading(false);
        return;
      }
    }
    else if(numberingSystem === 'UNIVERSAL') {
      if (!UNIVERSAL.has(Number(prepNumber))) {
        setError(true);
        setErrorMessage('Please enter a valid tooth number according to the Universal numbering system');
        setLoading(false);
        return
      }
    }
    if (!file1 || !file2 || !prepNumber) {
      setError(true);
      setLoading(false);
      return;
    }
  
    try {
      if (!file1 && !file2) {
        setError(true);
        setLoading(false);
        return;
      }
      
      if (
        file1.name.split('.').pop() !== 'stl' &&
        file1.name.split('.').pop() !== 'pcd' &&
        file2.name.split('.').pop() !== 'stl' &&
        file2.name.split('.').pop() !== 'pcd'
      ) {
        setFileValid(false);
        setLoading(false);
        return;
      }
  
      const formData = new FormData();
      formData.append('file1', file1);
      formData.append('file2', file2);
      formData.append('category', JSON.stringify(selectedOption));
      formData.append('tooth_number', JSON.stringify(prepNumber));
  
      const response = await fetch('http://34.71.126.92:4000/prep/', {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Token ${token}`,  // Ensure userToken contains the correct token
        },
      });
  
      if (response.status === 200) {
        // Get the response as a blob
        const responseBlob = await response.blob();
        const folder_id = response.headers.get('folder-id');
        const upper_lower = response.headers.get('upper_lower');

        console.log(folder_id);
  
        // Now you can use `blobURL` as the `margin` or any other place where needed
        setLoading(false);
        
        // Pass `blobURL` as `points` to the next view
        navigate('/prep', {
          state: {
            file1,
            file2,
            margin_backend: responseBlob,  // Passing the blob URL
            folder_id: folder_id,
            upper_lower: upper_lower,
            selectedOption,
            prepView: true,
            antaView: false,
            tooth_number: prepNumber,
            numberingSystem
          }
        });
      } 
      else if (response.status === 401) {
        setError(true);
        setLoading(false);
      }
      else {
        setLoading(false);

        const folder_id = response.headers.get('folder-id');
        const upper_lower = response.headers.get('upper_lower');
        
        // Pass `blobURL` as `points` to the next view
        navigate('/prep', {
          state: {
            file1,
            file2,
            margin_backend: null,  // Passing the blob URL
            folder_id: folder_id,
            upper_lower: upper_lower,
            selectedOption,
            prepView: true,
            antaView: false,
            tooth_number: prepNumber
          }
        });
      }
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };
  

  const debug = () => {
    navigate('/crown', {
      state: {
        file1,
        file2,
        crown: crownfile,
        crownFirst:null,
        marginCenter:null,
        axis:null,
        thickness:null,
        position: new THREE.Vector3(-42.57305524045056, -53.444090713186604, 9.906063101665028),
        selectedOption:null,
        prepView: true,
        antaView: false
      }
    });}


    const marginGo = () => {
      navigate('/example', {
        state: {
          prep : file1,
          crown : file2,
        }
      });

  }
  

  useEffect(() => {
    if (file1 || file2) {
      const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, antialias: true,alpha: true});
      renderer.shadowMap.enabled = true;
      const aspect = window.innerWidth / window.innerHeight;
      const frustumSize = 100;
      const sceneInstance = new THREE.Scene();
      scene.current = sceneInstance; // Update scene reference
      const camera = new THREE.OrthographicCamera(
            frustumSize * aspect / -2,
            frustumSize * aspect / 2,
            frustumSize / 2,
            frustumSize / -2,
            0.1,
            1000
          );
      camera.position.set(2.6, -17,70);

      const loader = new STLLoader();
      const controls = new TrackballControls(camera,renderer.domElement);
    controls.enableRotate = true;
    controls.noPan = false;
    controls.enablePan = true;
    controls.enableZoom = true;
    controls.enableDamping = true;
    controls.dampingFactor = 0.8;
    controls.rotateSpeed = 2.5;
    controls.zoomSpeed = 3;
    controls.panSpeed = 1.5;
    // this.controls.screenSpacePanning = true;
    controls.minPolarAngle = 0;
    controls.maxPolarAngle = Math.PI;
    controls.minAzimuthAngle = -Infinity;
    controls.maxAzimuthAngle = Infinity;
    controls.maxDistance = 500; // Maximum zoom distance
    controls.minDistance = 5;   // Minimum zoom distance
    controls.enableKeys = true;
    controls.mouseButtons = {
      LEFT: THREE.MOUSE.PAN,
      MIDDLE: THREE.MOUSE.DOLLY,
      RIGHT: THREE.MOUSE.ROTATE,
    };

      // Load and render file1
      if (file1) {
        loader.load(URL.createObjectURL(file1), (geometry) => {
          const material = new THREE.ShaderMaterial({
            uniforms:{
                      diffuse: { value: new THREE.Color(0xecf0f1) }, // Whitish crown, beige base
                      specular: { value: new THREE.Color(0x999999)  }, // Reduced specular for grooves
                      shininess: { value: 150 }, // Softer shininess for crown
                      roughness: { value: 3  }, // Moderate roughness for better groove contrast
                      opacity: { value: 1.0 }, // Fully opaque
                      translucency: { value:  0.1 }, // Minimal translucency for the crown
                      clearcoat: { value: 0.00001 }, // Minimal clearcoat for the crown
                      clearcoatRoughness: { value: 1 }, // Slightly rougher clearcoat for crown
                      ambientLightColor: { value: new THREE.Color(0x707070).multiplyScalar(0 ) }, // Neutral ambient light
                      // lightColor: { value: new THREE.Color(0xffffff).multiplyScalar) }, // Stronger light to enhance grooves
                      // lightPosition: { value: new THREE.Vector3(10, 10, 10) }, // Directional light
                      transparent: true,
                    },
                    vertexShader : `
                    varying vec3 vNormal;
                    varying vec3 vPosition;
                    varying vec3 vLightDirection;
                    varying vec3 vViewPosition; // Declare vViewPosition here
                  
                    uniform vec3 lightPosition;
                  
                    void main() {
                        vNormal = normalize(normalMatrix * normal);
                        vPosition = (modelMatrix * vec4(position, 1.0)).xyz;
                        vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
                        vViewPosition = -mvPosition.xyz; // Pass the view-space position to the fragment shader
                        vLightDirection = normalize(lightPosition - mvPosition.xyz);
                        gl_Position = projectionMatrix * mvPosition;
                    }
                  `,
                  
                  
                  fragmentShader : `uniform vec3 lightColor;
                  uniform vec3 ambientLightColor;
                  uniform vec3 diffuse;
                  uniform vec3 specular;
                  uniform float shininess;
                  uniform float opacity;
                  uniform float roughness;
                  uniform float translucency;
                  
                  uniform float brushradius;
                  uniform vec3 uHoverPosition;
                  
                  varying vec3 vNormal;
                  varying vec3 vPosition;
                  varying vec3 vLightDirection;
                  varying vec3 vViewPosition; // Add view position
                  
                  void main() {
                      vec3 normal = normalize(vNormal);
                      
                      // Flip normal for backfaces
                      if (!gl_FrontFacing) {
                          normal = -normal; // Flip the normal for backfaces
                      }
                  
                      vec3 lightDir = normalize(vLightDirection);
                      vec3 viewDir = normalize(cameraPosition - vPosition);
                  
                      // Diffuse shading (adjusted for both sides)
                      float lambertian = max(dot(normal, lightDir), 0.0); 
                      vec3 diffuseColor = lambertian * diffuse;
                  
                      // Specular shading
                      vec3 halfwayDir = normalize(lightDir + viewDir);
                      float specAngle = max(dot(normal, halfwayDir), 0.0);
                      float specularIntensity = pow(specAngle, shininess);
                      vec3 specularColor = specular * specularIntensity;
                  
                      // Subsurface scattering (approximated)
                      float sss = translucency * max(dot(-viewDir, lightDir), 0.0);
                  
                      // Ambient occlusion (simulated)
                      float ao = max(0.1, dot(normal, viewDir));
                      vec3 ambient = ambientLightColor * diffuse * ao;
                  
                      // Combine all lighting effects
                      vec3 color = ambient + diffuseColor + specularColor + sss * lightColor;
                      gl_FragColor = vec4(color, opacity);
                  }
                  `,
            transparent: true,
            side: THREE.DoubleSide
            
          });
      
          const mesh = new THREE.Mesh(geometry, material);
          scene.current.add(mesh);
        });
      }
      
      if (file2) {
        loader.load(URL.createObjectURL(file2), (geometry) => {
          const material = new THREE.ShaderMaterial({
            uniforms:{
              diffuse: { value: new THREE.Color(0xecf0f1) }, // Whitish crown, beige base
              specular: { value: new THREE.Color(0x999999)  }, // Reduced specular for grooves
              shininess: { value: 150 }, // Softer shininess for crown
              roughness: { value: 3  }, // Moderate roughness for better groove contrast
              opacity: { value: 1.0 }, // Fully opaque
              translucency: { value:  0.1 }, // Minimal translucency for the crown
              clearcoat: { value: 0.00001 }, // Minimal clearcoat for the crown
              clearcoatRoughness: { value: 1 }, // Slightly rougher clearcoat for crown
              ambientLightColor: { value: new THREE.Color(0x707070).multiplyScalar(0 ) }, // Neutral ambient light
              // lightColor: { value: new THREE.Color(0xffffff).multiplyScalar) }, // Stronger light to enhance grooves
              // lightPosition: { value: new THREE.Vector3(10, 10, 10) }, // Directional light
              transparent: true,
            },
            vertexShader : `
            varying vec3 vNormal;
            varying vec3 vPosition;
            varying vec3 vLightDirection;
            varying vec3 vViewPosition; // Declare vViewPosition here
          
            uniform vec3 lightPosition;
          
            void main() {
                vNormal = normalize(normalMatrix * normal);
                vPosition = (modelMatrix * vec4(position, 1.0)).xyz;
                vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
                vViewPosition = -mvPosition.xyz; // Pass the view-space position to the fragment shader
                vLightDirection = normalize(lightPosition - mvPosition.xyz);
                gl_Position = projectionMatrix * mvPosition;
            }
          `,
          
          
          fragmentShader : `uniform vec3 lightColor;
          uniform vec3 ambientLightColor;
          uniform vec3 diffuse;
          uniform vec3 specular;
          uniform float shininess;
          uniform float opacity;
          uniform float roughness;
          uniform float translucency;
          
          uniform float brushradius;
          uniform vec3 uHoverPosition;
          
          varying vec3 vNormal;
          varying vec3 vPosition;
          varying vec3 vLightDirection;
          varying vec3 vViewPosition; // Add view position
          
          void main() {
              vec3 normal = normalize(vNormal);
              
              // Flip normal for backfaces
              if (!gl_FrontFacing) {
                  normal = -normal; // Flip the normal for backfaces
              }
          
              vec3 lightDir = normalize(vLightDirection);
              vec3 viewDir = normalize(cameraPosition - vPosition);
          
              // Diffuse shading (adjusted for both sides)
              float lambertian = max(dot(normal, lightDir), 0.0); 
              vec3 diffuseColor = lambertian * diffuse;
          
              // Specular shading
              vec3 halfwayDir = normalize(lightDir + viewDir);
              float specAngle = max(dot(normal, halfwayDir), 0.0);
              float specularIntensity = pow(specAngle, shininess);
              vec3 specularColor = specular * specularIntensity;
          
              // Subsurface scattering (approximated)
              float sss = translucency * max(dot(-viewDir, lightDir), 0.0);
          
              // Ambient occlusion (simulated)
              float ao = max(0.1, dot(normal, viewDir));
              vec3 ambient = ambientLightColor * diffuse * ao;
          
              // Combine all lighting effects
              vec3 color = ambient + diffuseColor + specularColor + sss * lightColor;
              gl_FragColor = vec4(color, opacity);
          }
          `,
            transparent: true,
              side: THREE.DoubleSide
            
          });
      
          const mesh = new THREE.Mesh(geometry, material);
          scene.current.add(mesh);
        });
      }
      

      const animate = () => {
        requestAnimationFrame(animate);
        if (scene.current && camera) { // Check if scene and camera are defined
          renderer.render(scene.current, camera);
          controls.update();

        }
      };

      animate();

      // Cleanup function to dispose the renderer on component unmount
      return () => {
        renderer.dispose();
        controls.dispose();
      };
    }
  }, [file1, file2,canvasRef.current]);
  return (
    <div style={{ fontFamily: 'Manrope, sans-serif' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', background: "rgba(255, 255, 255, 0.3)" }}>
        <Card sx={{ width: '90%', maxWidth: '60vw', p: 4, borderRadius: '8px', boxShadow: "0 0 4px 4px rgba(144, 200, 224, 0.4)", display: 'flex', flexDirection: "column" }}>
          {/* Error Message */}
          {error && (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '10px',
      padding: '10px',
      backgroundColor: 'rgba(255, 0, 0, 0.1)',
      borderRadius: '8px',
      border: '1px solid red',
    }}
  >
    <Typography variant="body2" color="error">
      { errorMessage}
    </Typography>
    <IconButton onClick={() => setError(false)} size="small">
      <CloseIcon color="error" fontSize="small" />
    </IconButton>
  </Box>
)}


  
          {/* Select Category */}
          <div style={{ background: "#ffffff" }}>
      <Box sx={{ flex: 1, padding: '20px' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="selectLabel">Select category</InputLabel>
              <Select
                labelId="selectLabel"
                sx={{ backgroundColor: '#ffffff' }}
                id="select"
                value={selectedOption}
                onChange={handleOptionChange}
                label="Select category"
              >
                <MenuItem value="molar">Molar</MenuItem>
                <MenuItem value="premolar">Pre Molar</MenuItem>
                <MenuItem value="anterior">Anterior</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Enter tooth number"
                variant="outlined"
                onChange={(e) => setPrepNumber(e.target.value)}
              />
              <Box sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="tooth-numbering"
                    name="tooth-numbering"
                    value={numberingSystem}
                    onChange={(e) => setNumberingSystem(e.target.value)}
                  >
                    <FormControlLabel
                      value="UNIVERSAL"
                      control={<Radio sx={{ p: 0 }} />}
                      label="Universal"
                      sx={{ m: 0 }}
                    />
                    <FormControlLabel
                      value="FDI"
                      control={<Radio sx={{ p: 0 }} />}
                      label="FDI"
                      sx={{ m: 0 }}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>


  
          {/* Left Half - Upload Cards */}
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '20px' }}>
              <Card sx={{ marginBottom: '20px' }}>
                <div style={{ background: "rgba(144, 200, 224, 0.1)" }}>
                  <Box
                    sx={{
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      border: '2px dashed #cccccc',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleFileChange(e, 'file1');
                    }}
                  >
                    <input
                      type="file"
                      id="fileInput1"
                      accept=".stl, .pcd"
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleFileChange(e, 'file1')
                      }}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="fileInput1">
                      <Button sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: primaryColor,
                        color: '#fff',
                        border: `1px solid ${primaryColor}`,
                        transition: 'background-color 0.3s ease',
                      }} component="span" startIcon={<CloudUploadIcon />}>
                        Upload Prep
                      </Button>
                    </label>
                    {file1 && (
                      <>
                        <Typography variant="body2" color="textSecondary">
                          {file1.name} {/* Display the uploaded file name */}
                        </Typography>
                        <Button variant="outlined" style={{ marginTop: '10px', color: primaryColor }} onClick={() => setFile1(null)}>
                          Delete
                        </Button>
                      </>
                    )}
                    {!file1 && (
                      <Typography variant="body2" color="textSecondary" sx={{ marginTop: '10px' }}>
                        Drag and drop files here or click to upload<br />
                        <Typography variant="body2" color="textSecondary" sx={{ marginTop: '10px', justifyContent: "center", textAlign: "center" }}>
                          .STL, .PLY, .OBJ supported
                        </Typography>
                      </Typography>
                    )}
                  </Box>
                </div>
              </Card>
  
              <Card>
                <div style={{ background: "rgba(144, 200, 224, 0.1)" }}>
                  <Box
                    sx={{
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      border: '2px dashed #cccccc',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleFileChange(e, 'file2');
                    }}
                  >
                    <input
                      type="file"
                      id="fileInput2"
                      accept=".stl, .pcd"
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleFileChange(e, 'file2')
                      }}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="fileInput2">
                      <Button sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: primaryColor,
                        color: '#fff',
                        border: "1px solid #213F99",
                        transition: 'background-color 0.3s ease',
                      }} component="span" startIcon={<CloudUploadIcon />}>
                        Upload Anta
                      </Button>
                    </label>
                    {file2 && (
                      <>
                        <Typography variant="body2" color="textSecondary">
                          {file2.name} {/* Display the uploaded file name */}
                        </Typography>
                        <Button variant="outlined" style={{ marginTop: '10px', color: primaryColor }} onClick={() => setFile2(null)}>
                          Delete
                        </Button>
                      </>
                    )}
                    {!file2 && (
                      <Typography variant="body2" color="textSecondary" sx={{ marginTop: '10px' }}>
                        Drag and drop files here or click to upload<br />
                        <Typography variant="body2" color="textSecondary" sx={{ marginTop: '10px', justifyContent: "center", textAlign: "center" }}>
                          .STL, .PLY, .OBJ supported
                        </Typography>
                      </Typography>
                    )}
                  </Box>
                </div>
              </Card>

              <Card>
                <div style={{ background: "rgba(144, 200, 224, 0.1)" ,display: debugToggle ? 'block' : 'none', }}>
                  <Box
                    sx={{
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      border: '2px dashed #cccccc',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleFileChange(e, 'crown');
                    }}
                  >
                    <input
                      type="file"
                      id="fileInput3"
                      accept=".stl, .pcd"
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleFileChange(e, 'crown')
                      }}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="fileInput3">
                      <Button sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: primaryColor,
                        color: '#fff',
                        border: "1px solid #213F99",
                        transition: 'background-color 0.3s ease',
                      }} component="span" startIcon={<CloudUploadIcon />}>
                        Upload Crown
                      </Button>
                    </label>
                    {crownfile && (
                      <>
                        <Typography variant="body2" color="textSecondary">
                          {crownfile.name} {/* Display the uploaded file name */}
                        </Typography>
                        <Button variant="outlined" style={{ marginTop: '10px', color: primaryColor }} onClick={() => setFile2(null)}>
                          Delete
                        </Button>
                      </>
                    )}
                    {!file2 && (
                      <Typography variant="body2" color="textSecondary" sx={{ marginTop: '10px' }}>
                        Drag and drop files here or click to upload<br />
                        <Typography variant="body2" color="textSecondary" sx={{ marginTop: '10px', justifyContent: "center", textAlign: "center" }}>
                          .STL, .PLY, .OBJ supported
                        </Typography>
                      </Typography>
                    )}
                  </Box>
                </div>
              </Card>
            </Box>
  
            {/* Right Half - Canvas */}
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', padding: '20px' }}>
              <canvas
                ref={canvasRef}
                width="400"
                height="300"
                style={{
                  border: '1px solid black',
                  backgroundColor: file1 || file2 ? '#ffffff' : '#ffffff', // Change background color based on file upload
                }}
              />
              {!file1 && !file2 && (
                <Typography variant="body1" color="textSecondary" sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                  Upload files to preview
                </Typography>
              )}
            </Box>
          </Box>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <Info /></div>
              <Typography className='fw-semibold' variant="body2" color="#000000" sx={{ marginLeft: '10px', justifyContent: 'center' }}>
                Easily upload your scans for upper and lower teeth by either dragging and dropping the files into the designated areas above or manually selecting them for upload.
              </Typography>
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={debugToggle ? debug : prep}
              // onClick={marginGo}
              disabled={loading}
              sx={{
                cursor: 'pointer',
                backgroundColor: primaryColor,
                color: '#fff',
                border: '1px solid #213F99',
                transition: 'background-color 0.3s ease',
                marginLeft: 2,
                paddingLeft: 2,
                paddingright: 2,
              }}
              startIcon={loading ? <CircularProgress size={24} color="inherit" /> : null}
            >
              {loading ? null : 'Next'}
            </Button>
          </Grid>
          {!fileValid && (
          <Typography
            variant="body2"
            color="error"
            sx={{
              textAlign: 'center',
              marginTop: '10px',
              padding: '10px',
              backgroundColor: 'rgba(255, 0, 0, 0.1)',
              borderRadius: '8px',
              border: '1px solid red',
            }}
          >
            Please Select A Valid File
          </Typography>
        )}
        </Card>
      </Box>
    </div>
  );
  
};

export default Fileselect;