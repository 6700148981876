// ThreeJSManager.js
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {TrackballControls} from 'three/examples/jsm/controls/TrackballControls';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { CSG } from 'three-csg-ts';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import {toCreasedNormals} from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import * as U from './utils';
import { debounce } from 'lodash';
const { kdTree } = require('kd-tree-javascript');


const vertexShaderCrown = `attribute vec3 thickness_pair; // Declare thickness_pair as an attribute
attribute vec3 thickness_anta;
varying vec3 vNormal;
varying vec3 vPosition;
varying vec3 vLightDirection;
varying vec3 vViewPosition;
varying vec3 vThicknessPair;  // Pass thickness_pair to fragment shader
varying vec3 vThicknessAnta;
varying vec3 ConstNormal;
uniform vec3 lightPosition;

void main() {
    vNormal = normalize(normalMatrix * normal);
    vPosition = (modelMatrix * vec4(position, 1.0)).xyz;
    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
    vViewPosition = -mvPosition.xyz;
    vLightDirection = normalize(lightPosition - mvPosition.xyz);
    ConstNormal = normal;
    
    // Pass the thickness_pair attribute to the fragment shader
    vThicknessPair = thickness_pair;  // Assign the attribute to a varying
    vThicknessAnta = thickness_anta;
    
    gl_Position = projectionMatrix * mvPosition;
}
`;
const fragmentShaderCrown = `
    uniform vec3 lightColor;
    uniform vec3 ambientLightColor;
    uniform vec3 diffuse;
    uniform vec3 specular;
    uniform float shininess;
    uniform float opacity;
    uniform float roughness;
    uniform float translucency;
    uniform vec3 crownAxis;
    
    uniform float brushradius;
    uniform vec3 uHoverPosition;
    
    varying vec3 vNormal;
    varying vec3 vPosition;
    varying vec3 vLightDirection;
    varying vec3 vViewPosition;
    varying vec3 vThicknessPair;
    varying vec3 vThicknessAnta;
    varying vec3 ConstNormal;
    
    float distance_space(vec3 p0, vec3 p1) {
        return length(p1 - p0);
    }

    vec3 getCustomColor(float dist) {
        if (dist < 0.3) return vec3(1.0, 0.0, 0.0); // Red
        else if (dist < 0.4) return vec3(1.0, 1.0, 0.0); // Yellow
        else if (dist < 0.5) return vec3(0.0, 1.0, 0.0); // Green
        else if (dist < 0.6) return vec3(0.0, 0.0, 1.0); // Blue
    }

    vec3 getCustomColorIntrusion(float dist) {
        if (dist > 0.4) return vec3(1.0, 0.0, 0.0); // Red
        else if (dist > 0.3) return vec3(1.0, 1.0, 0.0); // Yellow
        else if (dist > 0.2) return vec3(0.0, 1.0, 0.0); // Green
        else if (dist > 0.1) return vec3(0.0, 0.0, 1.0); // Blue
    }

    void main() {
    vec3 normal = normalize(vNormal);
    if (!gl_FrontFacing) normal = -normal;

    vec3 lightDir = normalize(vLightDirection);
    vec3 viewDir = normalize(cameraPosition - vPosition);
    
    float lambertian = max(dot(normal, lightDir), 0.2); // Prevent complete darkness
    vec3 diffuseColor = lambertian * diffuse;
    
    vec3 halfwayDir = normalize(lightDir + viewDir);
    float specAngle = max(dot(normal, halfwayDir), 0.2);
    float specularIntensity = pow(specAngle, shininess) + 0.1; // Ensure highlights
    vec3 specularColor = specular * specularIntensity;
    
    float sss = translucency * max(dot(-viewDir, lightDir), 0.1);
    vec3 ambient = ambientLightColor * diffuse * 0.5; // Ensure ambient light
    vec3 color = ambient + diffuseColor + specularColor + sss * lightColor;
    
    float dist = distance_space(vPosition, vThicknessPair);

    if (dist < 0.6 ) {
        color = getCustomColor(dist);
    }

    float distanceFromHover = distance_space(vPosition, uHoverPosition);
    if (brushradius > distanceFromHover && distanceFromHover > (brushradius - brushradius / 15.0)) {
        color = mix(color, vec3(1.0, 0.0, 0.0), 0.5);
    }

    color = max(color, vec3(0.1)); // Prevent completely dark areas

    gl_FragColor = vec4(color, opacity);
}
`;




const vertexShader = `
  varying vec3 vNormal;
  varying vec3 vPosition;
  varying vec3 vLightDirection;
  varying vec3 vViewPosition; // Declare vViewPosition here


  uniform vec3 lightPosition;


  void main() {
      vNormal = normalize(normalMatrix * normal);
      vPosition = (modelMatrix * vec4(position, 1.0)).xyz;
      vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
      vViewPosition = -mvPosition.xyz; // Pass the view-space position to the fragment shader
      vLightDirection = normalize(lightPosition - mvPosition.xyz);
      gl_Position = projectionMatrix * mvPosition;
  }
`;

const fragmentShader = `uniform vec3 lightColor;
uniform vec3 ambientLightColor;
uniform vec3 diffuse;
uniform vec3 specular;
uniform float shininess;
uniform float opacity;
uniform float roughness;
uniform float translucency;

uniform float brushradius;
uniform vec3 uHoverPosition;

varying vec3 vNormal;
varying vec3 vPosition;
varying vec3 vLightDirection;
varying vec3 vViewPosition; // Add view position

void main() {
    vec3 normal = normalize(vNormal);
    
    // Flip normal for backfaces
    if (!gl_FrontFacing) {
        normal = -normal;
    }

    vec3 lightDir = normalize(vLightDirection);
    vec3 viewDir = normalize(cameraPosition - vPosition);

    // **Diffuse Lighting**
    float lambertian = max(dot(normal, lightDir), 0.2); // Prevent complete darkness
    vec3 diffuseColor = lambertian * diffuse;

    // **Specular Highlights**
    vec3 halfwayDir = normalize(lightDir + viewDir);
    float specAngle = max(dot(normal, halfwayDir), 0.2);
    float specularIntensity = pow(specAngle, shininess) + 0.1; // Avoid black specular areas
    vec3 specularColor = specular * specularIntensity;

    // **Subsurface Scattering Approximation**
    float sss = translucency * max(dot(-viewDir, lightDir), 0.1);

    // **Ambient Lighting with Minimum Brightness**
    vec3 ambient = ambientLightColor * diffuse * 0.5; // Ensure base ambient light

    // **Final Lighting Calculation**
    vec3 color = ambient + diffuseColor + specularColor + sss * lightColor;

    // **Minimum Brightness Constraint**
    color = max(color, vec3(0.1)); // Ensure no fully black regions

    // **Hover Brush Effect**
    float distanceFromHover = distance(vPosition, uHoverPosition);
    if (brushradius > distanceFromHover && distanceFromHover > (brushradius - brushradius / 15.0)) {
        color = mix(color, vec3(1.0, 0.0, 0.0), 0.5); // Apply red tint near hover
    }

    gl_FragColor = vec4(color, opacity);
}
`;

export default class ThreeJSManager {
  constructor(containerId, initialCameraPosition, color, sliderData, wire, crownarray, file1, file2, crown, prepView, antaView, scene, renderer, camera, controls,inner_surface,allowed_points,crown_axis) {
    this.containerId = containerId;
    this.initialCameraPosition = initialCameraPosition;
    this.color = color;
    this.sliderData = sliderData;
    this.wire = wire;
    this.crownarray = crownarray;
    this.file1 = file1;
    this.file2 = file2;
    this.crown = crown;
    this.prepView = prepView;
    this.inner_surface = inner_surface;
    this.allowed_points = allowed_points;
    this.crown_axis = crown_axis;
    this.antaView = antaView;
    this.scene = scene;
    this.renderer = renderer;
    this.camera = camera;
    this.controls = controls;
    this.raycasterSphereRef = null;
    this.controlsChanging = false;
    this.controlsChangeTimeout = null;
    this.gridScene = null;
  this.gridCamera = null
  }
  setupFixedGrid(color = 0x333333, opacity = 0.5) {
    // Create a separate scene for the grid
    this.gridScene = new THREE.Scene();
  
    // Create an orthographic camera for the grid
    const aspect = window.innerWidth / window.innerHeight;
    this.gridCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, -1000, 1000);
    this.gridCamera.position.z = 10;
  
    // Create the grid
    const size = 2;  // This will now represent the full width of the screen
    const divisions = 20;
    const gridHelper = new THREE.GridHelper(size, divisions, color, color);
    gridHelper.rotation.x = Math.PI / 2;
    gridHelper.material.opacity = opacity;
    gridHelper.material.transparent = true;
    gridHelper.material.depthWrite = false; // Important for proper rendering order
  
    // Scale the grid to fill the screen
    gridHelper.scale.set(1, aspect, 1);
  
    // Add the grid to the separate scene
    gridHelper.name = 'gridhelper'; // Assigning the name 'grid' to the gridHelper
    this.gridScene.add(gridHelper);
  
    // Store a reference to the gridHelper for resizing
    this.gridHelper = gridHelper;
  }

  initRenderer() {
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setSize(window.innerWidth * 0.8, window.innerHeight * 0.94);
    this.renderer.setClearColor(0x000000, 0); // Set clear color to transparent
    this.renderer.autoClear = false; // Important for manual control of rendering order
    document.getElementById(this.containerId).appendChild(this.renderer.domElement);
    console.log("renderer", this.renderer);
  }

  initScene() {
    this.scene = new THREE.Scene();
    // this.scene.add(new THREE.AmbientLight(0xffffff, 1.4));
  }

  initCamera() {
    const aspect = window.innerWidth / window.innerHeight;
    const frustumSize = 100;  // Adjust this value to control the visible area
  
    this.camera = new THREE.OrthographicCamera(
      frustumSize * aspect / -2,
      frustumSize * aspect / 2,
      frustumSize / 2,
      frustumSize / -2,
      0.1,
      1000
    );

    // this.camera = new THREE.PerspectiveCamera(75, aspect, 0.1, 1000);
  
    this.camera.up.set(0, 0, 1);
    this.camera.position.copy(this.initialCameraPosition);
    this.scene.add(this.camera);
  }

  initControls() {
    this.controls = new TrackballControls(this.camera, this.renderer.domElement);
    this.controls.enableRotate = true;
    this.controls.noPan = false;
    this.controls.enablePan = true;
    this.controls.enableZoom = true;
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.8;
    this.controls.rotateSpeed = 2.5;
    this.controls.zoomSpeed = 3;
    this.controls.panSpeed = 3.5;
    // this.controls.screenSpacePanning = true;
    this.controls.minPolarAngle = 0;
    this.controls.maxPolarAngle = Math.PI;
    this.controls.minAzimuthAngle = -Infinity;
    this.controls.maxAzimuthAngle = Infinity;
    this.controls.maxDistance = 500; // Maximum zoom distance
    this.controls.minDistance = 5;   // Minimum zoom distance
    this.controls.enableKeys = true;
    this.controls.mouseButtons = {
      LEFT: THREE.MOUSE.PAN,
      MIDDLE: THREE.MOUSE.DOLLY,
      RIGHT: THREE.MOUSE.ROTATE,
    };
    
  }
  



  toggleAxes() {
    this.showAxis = !this.showAxis;

    if (this.showAxis) {
      this.setupFixedGrid();
    } else {
      const axesHelper = this.gridScene.getObjectByName('gridhelper');
      if (axesHelper) {
        console.log('Removing grid');
        this.gridScene.remove(axesHelper);
      }
    }
  }

  toggleWireframe() {
    this.showWireframe = !this.showWireframe;

    this.scene.traverse(object => {
      if (object instanceof THREE.Mesh) {
        object.material.wireframe = this.showWireframe;
      }
    });
  }
  defaultView() {
    const startPosition = this.camera.position.clone();
    const startTarget = this.controls.target.clone();
    const targetPosition = new THREE.Vector3().copy(this.initialCameraPosition);
    const targetCenter = new THREE.Vector3(this.centerPoint);

    const duration = 500;
    const interval = 16;

    const totalSteps = Math.ceil(duration / interval);
    let currentStep = 0;

    const transitionStep = () => {
      currentStep++;

      const progress = currentStep / totalSteps;
      const newPosition = startPosition.clone().lerp(targetPosition, progress);
      const newTarget = startTarget.clone().lerp(targetCenter, progress);

      this.camera.position.copy(newPosition);
      this.controls.target.copy(newTarget);
      this.controls.update();

      if (currentStep < totalSteps) {
        requestAnimationFrame(transitionStep);
      }
    };

    transitionStep();
  }

  handleOpacityChange(index) {
    const fileNames = [...this.crownarray.map((_, crownIndex) => `crown_${crownIndex}`)];
    const fileName = fileNames[index];
    const existingMesh = this.scene.getObjectByName(fileName);
  
    if (existingMesh) {
      console.log(`Changing opacity for ${fileName}`);
      const opacityValue = parseFloat(document.getElementById(`opacitySlider-${index}`).value);
      console.log(`Opacity value: ${opacityValue}`);
  
      // Dynamically adjust renderOrder based on opacity
        existingMesh.renderOrder = 1;
      
  
      existingMesh.material.uniforms.opacity.value = opacityValue;
      existingMesh.material.transparent = opacityValue < 1;
      existingMesh.material.needsUpdate = true;
  
      this.renderer.render(this.scene, this.camera);
    }
  }

  createOpacitySlider(index) {
    const slider = document.getElementById(`opacitySlider-${index}`);

    const handleSliderChange = () => {
      this.handleOpacityChange(index);
    };

    slider.addEventListener('input', handleSliderChange);

    return () => {
      slider.removeEventListener('input', handleSliderChange);
    };
  }

  createAllOpacitySliders() {
    const crownsliders = this.crown.map((_, index) => this.createOpacitySlider(index));
    return crownsliders;
  }


  cleanup() {
    this.scene.traverse(object => {
      if (object instanceof THREE.Mesh) {
        object.geometry.dispose();
        object.material.dispose();
      }
    });
    this.renderer.domElement.remove();
    this.renderer.dispose();
    this.controls.dispose();
  }
  setrender() {
    return this.renderer;
  }
  animate() {
  requestAnimationFrame(this.animate.bind(this));
  this.controls.update();

  // Clear the renderer
  this.renderer.clear();

  // Render the grid scene first (behind everything else)
  if (this.gridScene && this.gridCamera) {
    this.renderer.render(this.gridScene, this.gridCamera);
  }

  // Render the main scene on top of the grid
  this.renderer.render(this.scene, this.camera);
}



  loadSTL(loader, file, materialOptions, fileName) {
    function generateIndex(geometry,inner_surface,allowed_points) {
      const positionAttr = geometry.attributes.position;
      const positions = positionAttr.array; // Flattened array of vertex positions
      const uniqueVertices = [];
      const uniqueNormals = [];
      const index = [];
      const vertexMap = new Map(); // To track unique vertices  
      // Helper function to generate a key for a vertex
      const vertexKey = (x, y, z) => `${x.toFixed(6)},${y.toFixed(6)},${z.toFixed(6)}`;
  
      for (let i = 0; i < positions.length; i += 3) {
          const x = positions[i];
          const y = positions[i + 1];
          const z = positions[i + 2];
          const key = vertexKey(x, y, z);
  
          if (vertexMap.has(key)) {
              // If vertex already exists, push its index
              index.push(vertexMap.get(key));
          } else {
              // Otherwise, add it to the unique vertices and map
              uniqueVertices.push(x, y, z);
              uniqueNormals.push(geometry.attributes.normal.array[i], geometry.attributes.normal.array[i + 1], geometry.attributes.normal.array[i + 2]);
              const newIndex = uniqueVertices.length / 3 - 1; // Calculate index
              vertexMap.set(key, newIndex);
              index.push(newIndex);
          }
      }

  
      // Update geometry attributes
      geometry.setAttribute(
          'position',
          new THREE.Float32BufferAttribute(uniqueVertices, 3)
      );
      geometry.setAttribute(
          'normal',
          new THREE.Float32BufferAttribute(uniqueNormals, 3))
      geometry.setIndex(index);

      const vert_pair = U.Heatmapverts(inner_surface,geometry,allowed_points);


      geometry.setAttribute("thickness_pair",new THREE.Float32BufferAttribute(vert_pair, 3));


      return geometry;
  }
    return new Promise((resolve, reject) => {
      loader.load(
        URL.createObjectURL(file),
        (geometry) => {
        geometry = toCreasedNormals(geometry, (90 / 180) * Math.PI);
        if(fileName.startsWith("crown")){
          geometry = generateIndex(geometry,this.inner_surface,this.allowed_points);
          geometry.computeVertexNormals();

            // const geo = U.parseGeometry(geometry);
            // console.log("geo", geo)
        }
        const materialUniforms = {
          diffuse: { value: new THREE.Color(fileName.startsWith("crown") ? 0xecf0f1: 0xd6c5b5) }, // Whitish crown, beige base
          specular: { value: new THREE.Color(fileName.startsWith("crown") ? 0x999999 : 0xaaaaaa) }, // Reduced specular for grooves
          shininess: { value: fileName.startsWith("crown") ? 150 : 50 }, // Softer shininess for crown
          roughness: { value: fileName.startsWith("crown") ? 3 : 0.8 }, // Moderate roughness for better groove contrast
          opacity: { value: 1.0 }, // Fully opaque
          translucency: { value: fileName.startsWith("crown") ? 0.1 : 0.4 }, // Minimal translucency for the crown
          clearcoat: { value: fileName.startsWith("crown") ? 0.00001 : 0.1 }, // Minimal clearcoat for the crown
          clearcoatRoughness: { value: fileName.startsWith("crown") ? 1 : 0.2 }, // Slightly rougher clearcoat for crown
          ambientLightColor: { value: new THREE.Color(0x707070).multiplyScalar(fileName.startsWith("crown") ? 0 : 1) }, // Neutral ambient light
          // lightColor: { value: new THREE.Color(0xffffff).multiplyScalar) }, // Stronger light to enhance grooves
          // lightPosition: { value: new THREE.Vector3(10, 10, 10) }, // Directional light
          transparent: true,
          brushradius: { value: 0.0 },
          crownAxis: { value: fileName.startsWith("crown") ? new THREE.Vector3(this.crown_axis[0],this.crown_axis[1],this.crown_axis[2]) : new THREE.Vector3(0, 0, 0) },
          uHoverPosition: { value: new THREE.Vector3(-1, -1, -1) },
          // isThick: { value: true },
        };
        
        
          
          const material = new THREE.ShaderMaterial({
            vertexShader: fileName.startsWith("crown")? vertexShaderCrown :vertexShader,
            fragmentShader: fileName.startsWith("crown")? fragmentShaderCrown :  fragmentShader,
            uniforms: materialUniforms,
            transparent: true,
  side: THREE.DoubleSide,
  vertexColors: true,
          });

          const mesh = new THREE.Mesh(geometry, material);
          const anta_mesh = this.scene.getObjectByName('anta');
            // if((fileName.startsWith("crown")) && anta_mesh){
            //   const verts = U.upper_heatmap(geometry, anta_mesh.geometry);
            //   geometry.setAttribute('thickness_anta', new THREE.Float32BufferAttribute(verts[0], 3));
            // }
          mesh.scale.set(1, 1, 1);
          mesh.castShadow = true;
          mesh.receiveShadow = true;
          mesh.name = fileName;
          this.scene.add(mesh);

          if (file === this.file1) {
            mesh.visible = this.sliderData[0].visible;
            mesh.material.depthWrite = true;
            mesh.renderOrder = 0;
            
          } else if (file === this.file2) {
            mesh.visible = this.sliderData[1].visible;
            mesh.material.depthWrite = true;
            mesh.renderOrder = 0;
          } else if (file === this.crownarray[0]) {
            mesh.visible = this.sliderData[2].visible;
            const boundingBoxFile1 = new THREE.Box3().setFromObject(mesh);
            const centerPoint = new THREE.Vector3();
            boundingBoxFile1.getCenter(centerPoint);
            this.controls.target.copy(centerPoint);
          }

          if (fileName.startsWith('crown_')) {
            // Existing changes
            mesh.material.depthWrite = true;
            mesh.renderOrder = -1;
          }
          resolve(mesh);
        },
        undefined,
        (error) => {
          reject(error);
        }
      );
    });
  }

  async loadAllSTLs() {
    const loader = new STLLoader();
    const loadPromises = [];

    if (this.file1 && this.prepView) {
      loadPromises.push(this.loadSTL(loader, this.file1, {
      }, "prep"));
    }

    if (this.file2 && this.antaView) {
      loadPromises.push(this.loadSTL(loader, this.file2, {
        color: 0xf2f2e8,
        roughness: 1,
        reflectivity: 1.0,
        transmission: 0.0,
        side: THREE.DoubleSide,
        wireframe: this.wire,
        transparent: true,
        wireframeLinewidth: 0.1,
        wireframeLinecap: 'round',
        wireframeLinejoin: 'round',
      }, 'anta'));
    }

  }

  loadcrowns(newCrownArray, callback) {
    // Remove existing crown meshes
    if (Array.isArray(this.crownarray)) {
      this.crownarray.forEach((_, index) => {
        const existingMesh = this.scene.getObjectByName(`crown_${index}`);
        if (existingMesh) {
          this.scene.remove(existingMesh);
          existingMesh.geometry.dispose();
          existingMesh.material.dispose();
        }
      });
    }
  
    // Update crown array
    this.crownarray = newCrownArray;
  
    // Load new crowns
    const loader = new STLLoader();
    const loadPromises = [];
    if (Array.isArray(this.crownarray) && this.crownarray.length > 0) {
      this.crownarray.forEach((crownFile, index) => {
        loadPromises.push(this.loadSTL(loader, crownFile, {
          color: 0xFFDAB9,
        }, `crown_${index}`));
      });
    }
  
    // Wait for all promises to resolve
    Promise.all(loadPromises).then(() => {
      console.log('All crowns loaded successfully');
      // Execute the callback function if it's provided
      if (typeof callback === 'function') {
        callback();
      }
    }).catch(error => {
      console.error('Error loading crowns:', error);
      // You might want to call the callback even in case of an error,
      // depending on your error handling strategy
      if (typeof callback === 'function') {
        callback(error);
      }
    });
  }
  

  handleToggleVisibilityClick(index) {
    let fileNameToRemove;
    let newFile;
    let isCrown = false;
  
    if (index === 0) {
      fileNameToRemove = 'prep';
      newFile = this.file1;
    } else if (index === 1) {
      fileNameToRemove = 'anta';
      newFile = this.file2;
    } else if (index >= 2 && index < 2 + this.crownarray.length) {
      const crownIndex = index - 2;
      fileNameToRemove = `crown_${crownIndex}`;
      newFile = this.crownarray[crownIndex];
      isCrown = true;
    } else {
      return; // Invalid index, do nothing
    }
  
    const objectToToggle = this.scene.getObjectByName(fileNameToRemove);
  
    if (objectToToggle) {
      // Object exists, remove it
      console.log(`Removing ${fileNameToRemove}`);
      this.scene.remove(objectToToggle);
      objectToToggle.geometry.dispose();
      objectToToggle.material.dispose();
    } else {
      // Object doesn't exist, load it
      console.log(`Loading ${fileNameToRemove}`);
      const loader = new STLLoader();
      const crownColor = isCrown ? 0xFFDAB9 : 0xf2f2e8;
  
      this.loadSTL(loader, newFile, {
        color: crownColor,
      }, fileNameToRemove).then(mesh => {
        // Ensure mesh is named correctly and added to the scene
        mesh.name = fileNameToRemove;
        this.scene.add(mesh);
      }).catch(error => {
        console.error(`Error loading ${fileNameToRemove}:`, error);
      });
    }
  }
  
  
}