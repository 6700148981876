import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, Card, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Typography, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { IoAddCircleSharp } from 'react-icons/io5';
import { ClipLoader } from 'react-spinners';
import dentalFacts from './Json/dentalFacts.json';
import { useNavigate } from 'react-router-dom';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {ReactComponent as Info} from '../assets/info.svg';
import {ReactComponent as Switch} from '../assets/new icons/switch-horizontal.svg';
import { ReactComponent as Wand } from '../assets/new icons/wand.svg';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader.js';
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';

const Fileselect = () => {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [loading, setLoading] = useState(false);
  const [prepFile, setPrepFile] = useState(null);
  const [antaFile, setAntaFile] = useState(null);
  const [upperFile, setUpperFile] = useState(null);
  const [lowerFile, setLowerFile] = useState(null);
  const [LUclassifier, setLUclassifier] = useState(null);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [selectedOption, setSelectedOption] = useState('molar');
  const randomFact = dentalFacts[Math.floor(Math.random() * dentalFacts.length)];
  const canvasRef = useRef(null);
  const canvasRef1 = useRef(null);
  const canvasRef2 = useRef(null);
  const [errormessage, setErrorMessage] = useState('Some error occurred. Please try again.');

  const scene = useRef(null);
  const [crownfile, setCrownfile] = useState([]);
  const [fileValid, setFileValid] = useState(true);
  const primaryColor = '#213F99';
  const debugToggle = false;
  const margintest = true;
  const [prepNumber, setPrepNumber] = useState(null);
  const [files, setFiles] = useState([]);


  const handleFileChange = async (event) => {
    event.preventDefault();
    event.stopPropagation();
  
    const uploadedItems = event.dataTransfer?.items || event.target.files;
    
    let stlFiles = [];
    let processingErrors = [];
  
    // Function to convert PLY file to STL
    const convertPlyToStl = async (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        
        reader.onload = (event) => {
          try {
            const plyLoader = new PLYLoader();
            const geometry = plyLoader.parse(event.target.result);
            
            const mesh = new THREE.Mesh(geometry);
            
            const exporter = new STLExporter();
            // Use binary format for better compatibility
            const stlData = exporter.parse(mesh, { binary: true });
            
            const stlBlob = new Blob([stlData], { type: 'application/octet-stream' });
            const stlFile = new File(
              [stlBlob], 
              file.name.replace(/\.ply$/i, '.stl'), 
              { type: 'application/octet-stream' }
            );
            
            resolve(stlFile);
          } catch (error) {
            console.error("Error converting PLY to STL:", error);
            reject(error);
          }
        };
        
        reader.onerror = reject;
        
        // Read PLY file as ArrayBuffer
        reader.readAsArrayBuffer(file);
      });
    };
  
    // Function to convert OBJ file to STL
    const convertObjToStl = async (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        
        reader.onload = (event) => {
          try {
            const objLoader = new OBJLoader();
            const object = objLoader.parse(event.target.result);
            
            // OBJLoader returns a Group, we need to work with all geometries
            const meshes = [];
            object.traverse((child) => {
              if (child.isMesh) {
                meshes.push(child);
              }
            });
            
            // If there are no meshes, reject
            if (meshes.length === 0) {
              reject(new Error("No valid meshes found in OBJ file"));
              return;
            }
            
            // Create a single mesh if there are multiple
            let finalMesh;
            if (meshes.length === 1) {
              finalMesh = meshes[0];
            } else {
              // Merge all geometries into one
              const mergedGeometry = new THREE.BufferGeometry();
              const materials = [];
              
              meshes.forEach((mesh) => {
                const positions = mesh.geometry.attributes.position.array;
                const normals = mesh.geometry.attributes.normal?.array;
                const uvs = mesh.geometry.attributes.uv?.array;
                
                // Create a new geometry
                const geometry = new THREE.BufferGeometry();
                
                // Set attributes
                geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
                if (normals) {
                  geometry.setAttribute('normal', new THREE.BufferAttribute(normals, 3));
                }
                if (uvs) {
                  geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
                }
                
                // Copy indices if they exist
                if (mesh.geometry.index) {
                  geometry.setIndex(mesh.geometry.index);
                }
                
                // Apply the mesh's transformation
                geometry.applyMatrix4(mesh.matrixWorld);
                
                // Merge with the final geometry
                mergedGeometry.merge(geometry);
                materials.push(mesh.material);
              });
              
              // Create a new mesh with all geometries
              finalMesh = new THREE.Mesh(mergedGeometry, materials[0]);
            }
            
            const exporter = new STLExporter();
            const stlData = exporter.parse(finalMesh, { binary: true });
            
            const stlBlob = new Blob([stlData], { type: 'application/octet-stream' });
            const stlFile = new File(
              [stlBlob], 
              file.name.replace(/\.obj$/i, '.stl'), 
              { type: 'application/octet-stream' }
            );
            
            resolve(stlFile);
          } catch (error) {
            console.error("Error converting OBJ to STL:", error);
            reject(error);
          }
        };
        
        reader.onerror = reject;
        
        // Read OBJ file as text
        reader.readAsText(file);
      });
    };
  
    const traverseEntries = async (entry) => {
      return new Promise((resolve) => {
        if (entry.isFile) {
          entry.file(async (file) => {
            try {
              const fileName = file.name.toLowerCase();
              
              if (fileName.endsWith(".stl")) {
                stlFiles.push(file);
                resolve();
              } else if (fileName.endsWith(".ply")) {
                try {
                  const stlFile = await convertPlyToStl(file);
                  stlFiles.push(stlFile);
                  resolve();
                } catch (error) {
                  processingErrors.push(`Failed to convert ${file.name}: ${error.message}`);
                  resolve();
                }
              } else if (fileName.endsWith(".obj")) {
                try {
                  const stlFile = await convertObjToStl(file);
                  stlFiles.push(stlFile);
                  resolve();
                } catch (error) {
                  processingErrors.push(`Failed to convert ${file.name}: ${error.message}`);
                  resolve();
                }
              } else {
                resolve(); // Not a supported file type, just resolve
              }
            } catch (error) {
              processingErrors.push(`Error processing ${file.name}: ${error.message}`);
              resolve();
            }
          });
        } else if (entry.isDirectory) {
          const reader = entry.createReader();
          reader.readEntries(async (entries) => {
            const subPromises = entries.map(subEntry => traverseEntries(subEntry));
            await Promise.all(subPromises);
            resolve();
          });
        } else {
          resolve();
        }
      });
    };
  
    const processItems = async () => {
      try {
        // Handle both drag-and-drop and file input cases
        if (uploadedItems) {
          if (uploadedItems instanceof FileList) {
            // Handle normal file input
            const filePromises = [];
            
            for (let i = 0; i < uploadedItems.length; i++) {
              const file = uploadedItems[i];
              const fileName = file.name.toLowerCase();
              
              if (fileName.endsWith(".stl")) {
                stlFiles.push(file);
              } else if (fileName.endsWith(".ply")) {
                filePromises.push((async () => {
                  try {
                    const stlFile = await convertPlyToStl(file);
                    stlFiles.push(stlFile);
                  } catch (error) {
                    processingErrors.push(`Failed to convert ${file.name}: ${error.message}`);
                  }
                })());
              } else if (fileName.endsWith(".obj")) {
                filePromises.push((async () => {
                  try {
                    const stlFile = await convertObjToStl(file);
                    stlFiles.push(stlFile);
                  } catch (error) {
                    processingErrors.push(`Failed to convert ${file.name}: ${error.message}`);
                  }
                })());
              }
            }
            
            await Promise.all(filePromises);
          } else {
            // Handle drag and drop from directory
            const promises = [];
            
            for (let i = 0; i < uploadedItems.length; i++) {
              const item = uploadedItems[i];
              // Support for directory API
              const entry = item.webkitGetAsEntry ? item.webkitGetAsEntry() : null;
              
              if (entry) {
                promises.push(traverseEntries(entry));
              } else if (item.getAsFile) {
                // Fallback for browsers without directory API
                const file = item.getAsFile();
                if (file) {
                  const fileName = file.name.toLowerCase();
                  
                  if (fileName.endsWith(".stl")) {
                    stlFiles.push(file);
                  } else if (fileName.endsWith(".ply")) {
                    promises.push((async () => {
                      try {
                        const stlFile = await convertPlyToStl(file);
                        stlFiles.push(stlFile);
                      } catch (error) {
                        processingErrors.push(`Failed to convert ${file.name}: ${error.message}`);
                      }
                    })());
                  } else if (fileName.endsWith(".obj")) {
                    promises.push((async () => {
                      try {
                        const stlFile = await convertObjToStl(file);
                        stlFiles.push(stlFile);
                      } catch (error) {
                        processingErrors.push(`Failed to convert ${file.name}: ${error.message}`);
                      }
                    })());
                  }
                }
              }
            }
            
            await Promise.all(promises);
          }
        }
        
        // Report any processing errors
        if (processingErrors.length > 0) {
          console.error("File processing errors:", processingErrors);
          // Optionally show errors to the user
          if (processingErrors.length === uploadedItems.length) {
            setError(true);
            setErrorMessage("Failed to process all files. Check console for details.");
            return;
          }
        }
  
        // If no STL files found, handle error
        if (stlFiles.length < 2) {
          setError(true);
          setErrorMessage("No enough valid STL, PLY, or OBJ files found.");
          return;
        }
  
        // Limit to max 2 files
        if (stlFiles.length > 2) {
          setError(true);
          setErrorMessage("Only 2 files are allowed. Found " + stlFiles.length + " files.");
          return;
        }
  
        // Update state with the converted/collected files
        setFiles(stlFiles);
        setError(false);
        setErrorMessage("");
        
        // Optional: Log success
        console.log("Successfully processed files:", stlFiles.map(f => f.name));
        
      } catch (error) {
        console.error("Fatal error processing files:", error);
        setError(true);
        setErrorMessage("An unexpected error occurred while processing files: " + error.message);
      }
    };
  
    await processItems();
  };
  




const changeUpperLower = () => {
  const temp = upperFile;
  setUpperFile(lowerFile);
  setLowerFile(temp);
}

useEffect(() => {
    if (files.length > 0) {
        classifier();
    }
}, [files]); // Run when `files` is updated

  const checkIsloggedIn=() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      navigate('/signin');
    }
  }
  
  useEffect(() => {
    checkIsloggedIn();
    if (error) {
      const timer = setTimeout(() => setError(false), 3000);
      return () => clearTimeout(timer); // Cleanup on unmount
    }
  }, [error]);



  

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const prepantaSet = () => {
    return new Promise((resolve) => {
      if(prepNumber < 30 && prepNumber > 0) {
        setPrepFile(upperFile);
        setAntaFile(lowerFile);
      } else {
        setPrepFile(lowerFile);
        setAntaFile(upperFile);
      }
      // Use setTimeout to ensure state updates have completed
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  };
  
  const prep = async () => {
    const token = sessionStorage.getItem('token');
    setLoading(true);
    
    if (!prepNumber) {
      setError(true);
      setErrorMessage("Please enter a valid tooth number");
      setLoading(false);
      return;
    }
  
    try {
      // Wait for file setting to complete
      await prepantaSet();
      
      // Verify files are set properly before proceeding
      if (!prepFile || !antaFile) {
        setError(true);
        setErrorMessage("Files not properly set");
        setLoading(false);
        return;
      }
  
      const formData = new FormData();
      formData.append('file1', prepFile);
      formData.append('file2', antaFile);
      formData.append('category', JSON.stringify(selectedOption));
      formData.append('tooth_number', JSON.stringify(prepNumber));
  
      const response = await fetch('http://34.71.126.92:4000/prep/', {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Token ${token}`,
        },
      });
  
      if (response.status === 200) {
        const responseBlob = await response.blob();
        const folder_id = response.headers.get('folder-id');
        const upper_lower = response.headers.get('upper_lower');
  
        console.log(folder_id);
        setLoading(false);
        
        navigate('/prep', {
          state: {
            file1: prepFile,
            file2: antaFile,
            margin_backend: responseBlob,
            folder_id: folder_id,
            upper_lower: upper_lower,
            selectedOption,
            prepView: true,
            antaView: false,
            tooth_number: prepNumber
          }
        });
      } 
      else if (response.status === 401) {
        setError(true);
        setLoading(false);
      }
      else {
        setLoading(false);
        const folder_id = response.headers.get('folder-id');
        const upper_lower = response.headers.get('upper_lower');
        
        navigate('/prep', {
          state: {
            file1: prepFile,
            file2: antaFile,
            margin_backend: null,
            folder_id: folder_id,
            upper_lower: upper_lower,
            selectedOption,
            prepView: true,
            antaView: false,
            tooth_number: prepNumber
          }
        });
      }
    } catch (error) {
      console.error("Error in prep function:", error);
      setError(true);
      setLoading(false);
    }
  };


  const classifier = async () => {
    const token = sessionStorage.getItem('token');
    setLoading(true);
  
    try {
  
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
      const response = await fetch('http://34.71.126.92:4000/classify/', {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Token ${token}`,  // Ensure userToken contains the correct token
        },
      });

      if(response.status === 200) {
        const data = await response.json();
        setLUclassifier(data);
        if(data){
          setUpperFile(files[1]);
          setLowerFile(files[0]);
        }
        else{
          setUpperFile(files[0]);
          setLowerFile(files[1]);
        }
        setLoading(false);
      }
      else if (response.status === 401) {
        setError(true);
        setLoading(false);
      }
      else {
        setError(true);
        setLoading(false);
      }


      
  }
  catch (error) {
    setError(true);
    setLoading(false);
  }
  };
  

  const debug = () => {
    navigate('/crown', {
      state: {
        file1,
        file2,
        crown: crownfile,
        crownFirst:null,
        marginCenter:null,
        axis:null,
        thickness:null,
        position: new THREE.Vector3(-42.57305524045056, -53.444090713186604, 9.906063101665028),
        selectedOption:null,
        prepView: true,
        antaView: false
      }
    });}


    const marginGo = () => {
      navigate('/example', {
        state: {
          prep : file1,
          crown : file2,
        }
      });

  }
  

 // For the Upper File (using canvasRef1)
useEffect(() => {
  if (upperFile && canvasRef1.current) {
    // Create a WebGL renderer for the upper canvas
    const renderer = new THREE.WebGLRenderer({ 
      canvas: canvasRef1.current, 
      antialias: true,
      alpha: true 
    });
    renderer.shadowMap.enabled = true;

    // Setup camera and scene
    const aspect = window.innerWidth / window.innerHeight;
    const frustumSize = 100;
    const sceneInstance = new THREE.Scene();
    const camera = new THREE.OrthographicCamera(
      (frustumSize * aspect) / -2,
      (frustumSize * aspect) / 2,
      frustumSize / 2,
      frustumSize / -2,
      0.1,
      1000
    );
    camera.position.set(2.6, -17, 70);

    // Setup controls
    const controls = new TrackballControls(camera, renderer.domElement);
    controls.enableRotate = true;
    controls.noPan = false;
    controls.enablePan = true;
    controls.enableZoom = true;
    controls.enableDamping = true;
    controls.dampingFactor = 0.8;
    controls.rotateSpeed = 2.5;
    controls.zoomSpeed = 3;
    controls.panSpeed = 1.5;
    controls.minPolarAngle = 0;
    controls.maxPolarAngle = Math.PI;
    controls.minAzimuthAngle = -Infinity;
    controls.maxAzimuthAngle = Infinity;
    controls.maxDistance = 500;
    controls.minDistance = 5;
    controls.enableKeys = true;
    controls.mouseButtons = {
      LEFT: THREE.MOUSE.PAN,
      MIDDLE: THREE.MOUSE.DOLLY,
      RIGHT: THREE.MOUSE.ROTATE,
    };

    // Load and render the upper file
    const loader = new STLLoader();
    loader.load(URL.createObjectURL(upperFile), (geometry) => {
      const material = new THREE.ShaderMaterial({
        uniforms: {
          diffuse: { value: new THREE.Color(0xecf0f1) },
          specular: { value: new THREE.Color(0x999999) },
          shininess: { value: 150 },
          roughness: { value: 3 },
          opacity: { value: 1.0 },
          translucency: { value: 0.1 },
          clearcoat: { value: 0.00001 },
          clearcoatRoughness: { value: 1 },
          ambientLightColor: { value: new THREE.Color(0x707070).multiplyScalar(0) },
        },
        vertexShader: `
          varying vec3 vNormal;
          varying vec3 vPosition;
          varying vec3 vLightDirection;
          varying vec3 vViewPosition;
          uniform vec3 lightPosition;
          void main() {
            vNormal = normalize(normalMatrix * normal);
            vPosition = (modelMatrix * vec4(position, 1.0)).xyz;
            vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
            vViewPosition = -mvPosition.xyz;
            vLightDirection = normalize(lightPosition - mvPosition.xyz);
            gl_Position = projectionMatrix * mvPosition;
          }
        `,
        fragmentShader: `
          uniform vec3 lightColor;
          uniform vec3 ambientLightColor;
          uniform vec3 diffuse;
          uniform vec3 specular;
          uniform float shininess;
          uniform float opacity;
          uniform float roughness;
          uniform float translucency;
          varying vec3 vNormal;
          varying vec3 vPosition;
          varying vec3 vLightDirection;
          varying vec3 vViewPosition;
          void main() {
            vec3 normal = normalize(vNormal);
            if (!gl_FrontFacing) {
              normal = -normal;
            }
            vec3 lightDir = normalize(vLightDirection);
            vec3 viewDir = normalize(cameraPosition - vPosition);
            float lambertian = max(dot(normal, lightDir), 0.0); 
            vec3 diffuseColor = lambertian * diffuse;
            vec3 halfwayDir = normalize(lightDir + viewDir);
            float specAngle = max(dot(normal, halfwayDir), 0.0);
            float specularIntensity = pow(specAngle, shininess);
            vec3 specularColor = specular * specularIntensity;
            float sss = translucency * max(dot(-viewDir, lightDir), 0.0);
            float ao = max(0.1, dot(normal, viewDir));
            vec3 ambient = ambientLightColor * diffuse * ao;
            vec3 color = ambient + diffuseColor + specularColor + sss * lightColor;
            gl_FragColor = vec4(color, opacity);
          }
        `,
        transparent: true,
        side: THREE.DoubleSide,
      });
      const mesh = new THREE.Mesh(geometry, material);
      sceneInstance.add(mesh);
    });

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      renderer.render(sceneInstance, camera);
      controls.update();
    };
    animate();

    // Cleanup on unmount
    return () => {
      renderer.dispose();
      controls.dispose();
    };
  }
}, [upperFile, canvasRef1.current]);

// For the Lower File (using canvasRef2)
useEffect(() => {
  if (lowerFile && canvasRef2.current) {
    // Create a WebGL renderer for the lower canvas
    const renderer = new THREE.WebGLRenderer({ 
      canvas: canvasRef2.current, 
      antialias: true,
      alpha: true 
    });
    renderer.shadowMap.enabled = true;

    // Setup camera and scene
    const aspect = window.innerWidth / window.innerHeight;
    const frustumSize = 100;
    const sceneInstance = new THREE.Scene();
    const camera = new THREE.OrthographicCamera(
      (frustumSize * aspect) / -2,
      (frustumSize * aspect) / 2,
      frustumSize / 2,
      frustumSize / -2,
      0.1,
      1000
    );
    camera.position.set(2.6, -17, 70);

    // Setup controls
    const controls = new TrackballControls(camera, renderer.domElement);
    controls.enableRotate = true;
    controls.noPan = false;
    controls.enablePan = true;
    controls.enableZoom = true;
    controls.enableDamping = true;
    controls.dampingFactor = 0.8;
    controls.rotateSpeed = 2.5;
    controls.zoomSpeed = 3;
    controls.panSpeed = 1.5;
    controls.minPolarAngle = 0;
    controls.maxPolarAngle = Math.PI;
    controls.minAzimuthAngle = -Infinity;
    controls.maxAzimuthAngle = Infinity;
    controls.maxDistance = 500;
    controls.minDistance = 5;
    controls.enableKeys = true;
    controls.mouseButtons = {
      LEFT: THREE.MOUSE.PAN,
      MIDDLE: THREE.MOUSE.DOLLY,
      RIGHT: THREE.MOUSE.ROTATE,
    };

    // Load and render the lower file
    const loader = new STLLoader();
    loader.load(URL.createObjectURL(lowerFile), (geometry) => {
      const material = new THREE.ShaderMaterial({
        uniforms: {
          diffuse: { value: new THREE.Color(0xecf0f1) },
          specular: { value: new THREE.Color(0x999999) },
          shininess: { value: 150 },
          roughness: { value: 3 },
          opacity: { value: 1.0 },
          translucency: { value: 0.1 },
          clearcoat: { value: 0.00001 },
          clearcoatRoughness: { value: 1 },
          ambientLightColor: { value: new THREE.Color(0x707070).multiplyScalar(0) },
        },
        vertexShader: `
          varying vec3 vNormal;
          varying vec3 vPosition;
          varying vec3 vLightDirection;
          varying vec3 vViewPosition;
          uniform vec3 lightPosition;
          void main() {
            vNormal = normalize(normalMatrix * normal);
            vPosition = (modelMatrix * vec4(position, 1.0)).xyz;
            vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
            vViewPosition = -mvPosition.xyz;
            vLightDirection = normalize(lightPosition - mvPosition.xyz);
            gl_Position = projectionMatrix * mvPosition;
          }
        `,
        fragmentShader: `
          uniform vec3 lightColor;
          uniform vec3 ambientLightColor;
          uniform vec3 diffuse;
          uniform vec3 specular;
          uniform float shininess;
          uniform float opacity;
          uniform float roughness;
          uniform float translucency;
          varying vec3 vNormal;
          varying vec3 vPosition;
          varying vec3 vLightDirection;
          varying vec3 vViewPosition;
          void main() {
            vec3 normal = normalize(vNormal);
            if (!gl_FrontFacing) {
              normal = -normal;
            }
            vec3 lightDir = normalize(vLightDirection);
            vec3 viewDir = normalize(cameraPosition - vPosition);
            float lambertian = max(dot(normal, lightDir), 0.0); 
            vec3 diffuseColor = lambertian * diffuse;
            vec3 halfwayDir = normalize(lightDir + viewDir);
            float specAngle = max(dot(normal, halfwayDir), 0.0);
            float specularIntensity = pow(specAngle, shininess);
            vec3 specularColor = specular * specularIntensity;
            float sss = translucency * max(dot(-viewDir, lightDir), 0.0);
            float ao = max(0.1, dot(normal, viewDir));
            vec3 ambient = ambientLightColor * diffuse * ao;
            vec3 color = ambient + diffuseColor + specularColor + sss * lightColor;
            gl_FragColor = vec4(color, opacity);
          }
        `,
        transparent: true,
        side: THREE.DoubleSide,
      });
      const mesh = new THREE.Mesh(geometry, material);
      sceneInstance.add(mesh);
    });

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      renderer.render(sceneInstance, camera);
      controls.update();
    };
    animate();

    // Cleanup on unmount
    return () => {
      renderer.dispose();
      controls.dispose();
    };
  }
}, [lowerFile, canvasRef2.current]);

  

return (
  <div style={{ fontFamily: 'Manrope, sans-serif' }}>
    {loading && (
      <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '9999',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        padding: '20px',
        borderRadius: '10px',
        textAlign: 'center',
        width: "50vw",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        animation: "fadeIn 0.5s ease-in-out"
      }}>
        <div className="mb-3">
          {/* Wand Icon with Smooth Scaling Animation */}
          <div className="d-flex align-items-center justify-content-center">
            <Wand style={{
              fontSize: "24px",
              marginRight: "10px",
              animation: "pulse 1.5s infinite ease-in-out"
            }} />
          </div>
          <p className="mt-3">
            <strong> Please wait while we analyze your data </strong>
          </p>
        </div>
    
        {/* Inline Styles for Smooth Animations */}
        <style>
          {`
            @keyframes fadeIn {
              from { opacity: 0; transform: translate(-50%, -55%); }
              to { opacity: 1; transform: translate(-50%, -50%); }
            }
            @keyframes pulse {
              0% { transform: scale(1); }
              50% { transform: scale(1.3); }
              100% { transform: scale(1); }
            }
          `}
        </style>
      </div>
    )}
    <Box
      sx={{
        display: !loading ?'flex': 'none',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        background: "rgba(255, 255, 255, 0.3)"
      }}
    >
      <Card
        sx={{
          width: '90%',
          maxWidth: '50vw',
          p: 4,
          borderRadius: '8px',
          boxShadow: "0 0 4px 4px rgba(144, 200, 224, 0.4)",
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {/* Error Message */}
        {error && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: '10px',
              padding: '10px',
              backgroundColor: 'rgba(255, 0, 0, 0.1)',
              borderRadius: '8px',
              border: '1px solid red',
            }}
          >
            <Typography variant="body2" color="error" >
              {errormessage}
            </Typography>
            <IconButton onClick={() => setError(false)} size="small">
              <CloseIcon color="error" fontSize="small" />
            </IconButton>
          </Box>
        )}

        {/* Top Section: Select Category */}
        <Box sx={{ background: "#ffffff", width: '100%'}}>
          <Grid container alignItems="center">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Enter tooth number"
                variant="outlined"
                onChange={(e) => setPrepNumber(e.target.value)}
              />
          </Grid>
        </Box>

        {/* Main Layout: Left Upload Card & Right Canvases */}
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mt: 2 }}>
          {/* Left Side: Upload Files (25% width) */}
          <Box
            sx={{ display: files.length === 0 ? "block" : "none", width: '100%' }}
          >
            <Card>
              <div style={{ background: "rgba(144, 200, 224, 0.1)" }}>
                <Box
                  sx={{
                    p: 2,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    border: '2px dashed #cccccc',
                    borderRadius: '8px',
                    cursor: 'pointer'
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onDrop={handleFileChange}
                >
                  <input
                    type="file"
                    id="fileInput"
                    accept=".stl, .pcd"
                    multiple
                    webkitdirectory=""
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  <label htmlFor="fileInput">
                    <Button
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#2196F3',
                        color: '#fff',
                        border: '1px solid #2196F3',
                        transition: 'background-color 0.3s ease'
                      }}
                      component="span"
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload Files/Folders
                    </Button>
                  </label>
                  {files.length > 0 ? (
                    <>
                      {files.map((file, index) => (
                        <Typography key={index} variant="body2" color="textSecondary">
                          {file.name.split('.')[0]}
                        </Typography>
                      ))}
                      <Button
                        variant="outlined"
                        sx={{ mt: 1, color: '#2196F3' }}
                        onClick={() => setFiles([])}
                      >
                        Clear All
                      </Button>
                    </>
                  ) : (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ mt: 1, textAlign: 'center' }}
                    >
                      Drag and drop files or folders here, or click to upload.
                      <br />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ mt: 1, textAlign: 'center' }}
                      >
                        .STL, .PLY, .OBJ supported
                      </Typography>
                    </Typography>
                  )}
                </Box>
              </div>
            </Card>
          </Box>

          {/* Right Side: Two Canvases with captions and Switch between */}
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: files.length !== 0 ? 'flex' : "none",
              flexDirection: 'row',
              gap: 2,
            }}
          >
            {/* Left Canvas Column */}
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                border: '1px solid black',
                backgroundColor: '#ffffff',
                height: '100%',
              }}
            >
              <Box sx={{ flex: 1, width: '100%', position: 'relative' }}>
                <canvas
                  ref={canvasRef1}
                  style={{ width: '100%', height: '100%' }}
                />
                {files.length === 0 && (
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)'
                    }}
                  >
                    Upload files to preview
                  </Typography>
                )}
              </Box>
              <Typography variant="body1" align="center" sx={{ mt: 1 }}>
                Upper Jaw {upperFile ?  `: ${upperFile.name.split('.')[0]}` : ''}
              </Typography>
            </Box>

            {/* Switch Column */}
            <Box
  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px'
  }}
>
  <IconButton
    size="small"
    sx={{
      width: 28,
      height: 28,
      borderRadius: '50%',
      p: 0,
      backgroundColor: 'grey.200',
      '&:hover': { backgroundColor: 'grey.300' },
    }}
    onClick={changeUpperLower}
  >
    <Switch size="small" sx={{ transform: 'scale(0.5)', m: 0 }} />
  </IconButton>
</Box>




            {/* Right Canvas Column */}
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                border: '1px solid black',
                backgroundColor: '#ffffff',
                height: '100%',
              }}
            >
              <Box sx={{ flex: 1, width: '100%', position: 'relative' }}>
                <canvas
                  ref={canvasRef2}
                  style={{ width: '100%', height: '100%' }}
                />
                {files.length === 0 && (
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)'
                    }}
                  >
                    Upload files to preview
                  </Typography>
                )}
              </Box>
              <Typography variant="body1" align="center" sx={{ mt: 1 }}>
                Lower Jaw {lowerFile ? `: ${lowerFile.name.split('.')[0]}` : ''}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Footer Section */}
        <Grid container alignItems="center" justifyContent="space-between" sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Grid item>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Info />
              <Typography
                className="fw-semibold"
                variant="body2"
                color="#000000"
                sx={{ ml: 1 }}
              >
                Easily upload your scans for upper and lower teeth by either dragging and dropping the files into the designated areas above or manually selecting them for upload.
              </Typography>
            </Box>
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={debugToggle ? debug : prep}
              disabled={loading}
              sx={{
                display: files.length === 0 ? 'none' : 'block',
                cursor: 'pointer',
                backgroundColor: primaryColor,
                color: '#fff',
                border: '1px solid #213F99',
                transition: 'background-color 0.3s ease',
                ml: 2,
                px: 2,
                width: '100%'
              }}
              startIcon={loading ? <CircularProgress size={24} color="inherit" /> : null}
            >
              {loading ? null : 'Next'}
            </Button>
          </Grid>
        </Grid>
        {!fileValid && (
          <Typography
            variant="body2"
            color="error"
            sx={{
              textAlign: 'center',
              mt: 2,
              p: 1,
              backgroundColor: 'rgba(255, 0, 0, 0.1)',
              borderRadius: '8px',
              border: '1px solid red'
            }}
          >
            Please Select A Valid File
          </Typography>
        )}
      </Card>
    </Box>
  </div>
);



  
};

export default Fileselect;
