import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as THREE from 'three';
import { ClipLoader } from 'react-spinners';
import { ReactComponent as MySVG5 } from '../assets/delete.svg';
import {ReactComponent as MySVG10} from '../assets/Check.svg';
import {ReactComponent as Delete} from '../assets/Close Icon.svg';

import { ReactComponent as PrepIcon } from '../assets/new icons/letter-p-small.svg';
import { ReactComponent as AntaIcon } from '../assets/new icons/letter-a-small.svg';
import { ReactComponent as Margin } from '../assets/new icons/vector-spline.svg';
import { ReactComponent as Toolbox } from '../assets/new icons/repair.svg';
import { ReactComponent as Undo } from '../assets/new icons/undo.svg';
import { ReactComponent as Left } from '../assets/new icons/arrow-left.svg';
import { ReactComponent as Right } from '../assets/new icons/arrow-right.svg';
import { ReactComponent as Redo } from '../assets/new icons/redo.svg';
import { ReactComponent as Wand } from '../assets/new icons/wand.svg';

import JSZip, { folder } from 'jszip'; 
import ThreeJSManager from "./Crown Component/ThreeD"
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader.js';
import {HigherOrderBezierCurve} from "./Crown Component/HigherCurves"
import { acceleratedRaycast, computeBoundsTree, disposeBoundsTree } from "three-mesh-bvh";
import { CircularProgress } from '@mui/material';



const Prepmodule = () => {
  const [undoSt, setUndoSt] = useState([]);
  const [redoSt, setRedoSt] = useState([]);
  const location = useLocation();
  const { file1, file2, margin_backend, folder_id,upper_lower ,points,selectedOption,prepView,antaView,position,tooth_number,numberingSystem } = location.state || {};
  const navigate = useNavigate();
  const [initialCameraPosition, setInitialCameraPosition] = useState(position ? position : new THREE.Vector3(-42.57305524045056, -53.444090713186604, 9.906063101665028));
  const [isRaycasterActive, setIsRaycasterActive] = useState(false);
  const [poi, setPoi] = useState(null);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [uuid, setUuid] = useState(folder_id);
  const [showAlert, setShowAlert] = useState(false);
  const raycasterSphereRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [margin, setmargin] = useState(margin_backend);
  const [error, setError] = useState(null);
  const [prepview, setPrepview] = useState(false);
  const [Adjustmentpanel, setAdjustmentPanel] = useState(true);
  const primarycolor = "#213F99";
  const [highlightSpheres, setHighlightSpheres] = useState([]);
  const [marginTool, setMarginTool] = useState(false);
  const [TextMessage, setTextMessage] = useState('Analyzing...');
  const [sliderData, setSliderData] = useState([
    
    { name: 'Prep', opacity: 1, visible: prepView },
    { name: 'Anta', opacity: 1, visible: antaView },
  ]);
  const containerRef = useRef(null);
  const [threeJSManager, setThreeJSManager] = useState(null);
  const [prepNumber, setPrepNumber] = useState(tooth_number);

  const [renderer, setRenderer] = useState(null);
  const [camera, setCamera] = useState(null);
  const [controls, setControls] = useState(null);
  const [scene, setScene] = useState(null);

  const curveRef = useRef(null);
  const raycaster = new THREE.Raycaster();
  const mouseRef = useRef(new THREE.Vector2());
  let isDragging = useRef(false);
  const selectedPoints = useRef([]); // To store the points for the line
  const [meshRef, setMeshRef] = useState(null);
  const splineMaterial = new THREE.MeshStandardMaterial({
    color: !marginTool ? 0xff0000 : 0x800080, // Bright orange for a vibrant appearance
    metalness: 0.5,  // Increase metalness for a slight shine
    roughness: 0.3,  // Moderate roughness for some light reflection
    emissive: !marginTool ? 0xff0000 : 0x800080, // Bright yellow emissive for contrast
    emissiveIntensity: 0.7 // Higher intensity for a more noticeable glow
  });

  const checkIsloggedIn=() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      navigate('/signin');
    }
  }
  
  
  useEffect(() => { 
    checkIsloggedIn();
    if (containerRef.current ) {
      const manager = new ThreeJSManager(
        containerRef.current.id,
        initialCameraPosition, null, sliderData, false,null, file1, file2, null, prepView, antaView, scene, camera, controls
      );
      manager.initRenderer();
      manager.initScene();
      manager.initCamera();
      manager.initControls();
      manager.animate();
      manager.loadAllSTLs();
      
      setScene(manager.scene);
      setRenderer(manager.renderer);
      setCamera(manager.camera);
      setControls(manager.controls);
      setThreeJSManager(manager);

      return () => {
        manager.cleanup();
      };
    }
  }, []);
  useEffect(() => {
    const axisButton = document.getElementById('grid');
    const wireframeButton = document.getElementById('wireframe');
    const opacitySliders = document.querySelectorAll('[id^="opacitySlider-"]');
    // const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
  
  
  if(prepview){
  setIsRaycasterActive(true)
  }
  if(!prepview){
  setIsRaycasterActive(false)
  }
  const handleMouseDown = (event) => {
    if(event.button === 2){
      return;
    }
  const rect = renderer.domElement.getBoundingClientRect();
  mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
  mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

  raycaster.setFromCamera(mouse, camera);
  const intersects = raycaster.intersectObjects(scene.children, true);

  if (intersects.length > 0) {
    const point = intersects[0].point;
   
    setSelectedCoordinates(point);
    if (raycasterSphereRef.current) {
      scene.remove(raycasterSphereRef.current);
    }

    // Create a new raycaster sphere
    const raycasterMaterial = new THREE.MeshBasicMaterial({ color: 0x0000ff });
    const raycasterSphereGeometry = new THREE.SphereGeometry(0.16); // Adjust the size as needed
    const newRaycasterSphere = new THREE.Mesh(raycasterSphereGeometry, raycasterMaterial);
    newRaycasterSphere.name = 'samllpoint';
    newRaycasterSphere.position.copy(point);
    raycasterSphereRef.current = newRaycasterSphere;

    // Add the new raycaster sphere to the scene
    scene.add(newRaycasterSphere);
  }}

  const highlightMaterial = new THREE.MeshPhysicalMaterial({ 
color: 0x4caf50,
opacity: 0.5,
transparent: true,
roughness: 0.5,
clearcoat: 1.0,
clearcoatRoughness: 0.1,
reflectivity: 1.0,
transmission: 0.0,
});

if(poi){
    const prevpoi = scene.getObjectByName('poi');
    if (prevpoi) {
      removeMesh('poi');
    }
    const highlightGeometry = new THREE.SphereGeometry(4.5, 32, 32);
    const highlightMesh = new THREE.Mesh(highlightGeometry, highlightMaterial);
    highlightMesh.material.depthWrite = false;
    highlightMesh.name = "poi"
    highlightMesh.renderOrder = 1;
    highlightMesh.position.set(poi[0], poi[1], poi[2]);
    scene.add(highlightMesh);
    }

    if(threeJSManager && prepview)
      {
      if(isRaycasterActive){
    renderer.domElement.addEventListener('mousedown', handleMouseDown);}
  }
  
    const toggleAxes = () => {
      if (threeJSManager) {
        threeJSManager.toggleAxes();
      }
    };
  
    const toggleWireframe = () => {
      if (threeJSManager) {
        threeJSManager.toggleWireframe();
      }
    };

  
    const handleOpacityChange = (index) => {
      if (threeJSManager) {
        threeJSManager.handleOpacityChange(index);
      }
    };

  
    if (axisButton) {
      axisButton.addEventListener('click', toggleAxes);
    }
  
    if (wireframeButton) {
      wireframeButton.addEventListener('click', toggleWireframe);
    }
  
    opacitySliders.forEach((slider, index) => {
      slider.addEventListener('input', () => {
        handleOpacityChange(index);
      });
    });
  
    return () => {
  
      if (axisButton) {
        axisButton.removeEventListener('click', toggleAxes);
      }
  
      if (wireframeButton) {
        wireframeButton.removeEventListener('click', toggleWireframe);
      }
      if(isRaycasterActive){
        const smallpoint = scene.getObjectByName('samllpoint');
        if (smallpoint) {
          removeMesh('samllpoint');
        }
        renderer.domElement.removeEventListener('mousedown', handleMouseDown);
      }
  
      opacitySliders.forEach((slider, index) => {
        slider.removeEventListener('input', () => {
          handleOpacityChange(index);
        });
      });
    };
  }, [threeJSManager,isRaycasterActive,poi,prepview]);

  useEffect(() => {
    if(threeJSManager && scene){
      let newSplinecurve
      const prepMesh = scene.getObjectByName('prep');
      let marginRadius = 0.05
      let splineMaterial1 = new THREE.MeshBasicMaterial({ color: 0x000000 });
      let tubeGeometry 
      let tubeMesh 
      THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

if(prepMesh){
  prepMesh.geometry.computeBoundsTree();
}

// Compute the BVH for prepMesh geometry

      
      const updateTubeGeometry = () => {
        if (selectedPoints.current.length >= 6) {
          if(scene.getObjectByName('redSpline')){
            removeMesh('redSpline');
          }
          const beizer = createHigherOrderBezierSegments(selectedPoints.current,5);
          newSplinecurve = new THREE.CurvePath();
          beizer.forEach((curve) => {
              newSplinecurve.add(curve);
          });
          tubeGeometry = new THREE.TubeGeometry(newSplinecurve, 1000, 0.03, 16, false);
          tubeMesh.geometry = tubeGeometry;
          tubeMesh.name = 'redSpline';
          scene.add(tubeMesh);
        }
      };

      const closedCurve = (pointArray, numControlPoints) => {
        const controlPoints = [];
        const stepSize = (pointArray.length - 1) / (numControlPoints - 1);
    
        for (let i = 0; i < numControlPoints; i++) {
            const index = i * stepSize;
            const lowIndex = Math.min(Math.floor(index), pointArray.length - 1);
            const highIndex = Math.min(Math.ceil(index), pointArray.length - 1);
    
            if (lowIndex === highIndex) {
                controlPoints.push(pointArray[lowIndex]);
            } else {
                // Interpolate between lowIndex and highIndex
                const t = Math.max(0, Math.min(1, index - lowIndex));
                const interpolated = pointArray[lowIndex].clone().lerp(pointArray[highIndex], t);
                controlPoints.push(interpolated);
            }
        }
    
        // Close the curve by adding the first point at the end
        controlPoints.push(controlPoints[0].clone());
    
        return controlPoints;
    };
    
    

      const update = (maincurve, newcurve) => {
        if (!newcurve) return;
    
        // Get evenly spaced points from the entire main curve
        const getEvenlySpacedPoints = (curve, numPoints) => {
            const points = [];
            for (let i = 0; i <= numPoints; i++) {
                const t = i / numPoints; // Normalized interval
                points.push(curve.getPointAt(t));
            }
            return points;
        };
    
        const numMainPoints = 400; // Adjust as needed for smoothness
        const maincurvePoints = getEvenlySpacedPoints(maincurve, numMainPoints);
    
        const start = newcurve.getPoint(0);
        const end = newcurve.getPoint(1);
    
        let startind = 0,
            endind = 0;
        let minstartDist = Infinity,
            minendDist = Infinity;
    
        // Find the closest indices for the start and end points of the new curve
        maincurvePoints.forEach((point, index) => {
            const startDist = point.distanceTo(start);
            const endDist = point.distanceTo(end);
            if (startDist < minstartDist) {
                minstartDist = startDist;
                startind = index;
            }
            if (endDist < minendDist) {
                minendDist = endDist;
                endind = index;
            }
        });
    
        // Reverse the curve if start index is greater than end index
        const reverseCurve = (curve) => {
          // Get evenly spaced points and reverse them
          const reversedPoints = getEvenlySpacedPoints(curve, 400).reverse();
      
          // Create new curve segments from the reversed points
          const reversedSegments = createHigherOrderBezierSegments(reversedPoints,5);
      
          // Construct a new CurvePath from the reversed segments
          const reversedCurve = new THREE.CurvePath();
          reversedSegments.forEach((segment) => reversedCurve.add(segment));
      
          return reversedCurve;
      };
      
    
        if (startind > endind) {
            [startind, endind] = [endind, startind];
            newcurve = reverseCurve(newcurve);
        }
        const smoothfactor = 1;
        startind = Math.max(0, startind - smoothfactor);
        endind = Math.min(maincurvePoints.length - 1, endind + smoothfactor);
    
        // Determine if first segment is smaller
        let mid = startind + Math.floor((endind - startind) / 2);
        const firstsegdist = newcurve.getPoint(0.5).distanceTo(maincurvePoints[mid]);
        const lastsegdist = newcurve.getPoint(0.5).distanceTo(maincurvePoints[0]);
        const isFirstSegmentSmaller = firstsegdist < lastsegdist;
        const numPoint = isFirstSegmentSmaller ? endind - startind : numMainPoints - (endind - startind);
        const newcurvePoints = getEvenlySpacedPoints(newcurve, numPoint);

        const isSmaller = reverseCurve(newcurve)
        let isSmallerPoints = getEvenlySpacedPoints(isSmaller,numPoint);

    
        // Create new curve by replacing points in the smaller segment
        let newmaincurve = isFirstSegmentSmaller
            ? [
                  ...maincurvePoints.slice(0, startind),
                  ...newcurvePoints,
                  ...maincurvePoints.slice(endind),
              ]
            : [
                  ...isSmallerPoints,
                  ...maincurvePoints.slice(startind+(2*smoothfactor), endind-(2*smoothfactor)),
                  
              ];

          newmaincurve = closedCurve(newmaincurve, 400);
        
        return newmaincurve;
    };
    
    
      
    const onMouseDown = (event) => {
      if(event.button === 2){
        return;
      }
      if(event.button===0 && event.ctrlKey){
      event.preventDefault();
      isDragging.current = true;
      selectedPoints.current = []; // Reset points for a new spline
      tubeMesh = new THREE.Mesh(tubeGeometry, splineMaterial1);
      renderer.domElement.style.cursor = 'crosshair';
      const obj = scene.getObjectByName('blackSpline');
      if(obj){
      setUndoSt((prevUndo) => [...prevUndo, obj.geometry ]);
      setRedoSt([]);
      }
    };
  }

    // Mouse move event to add points while dragging
    const onMouseMove = (event) => {
      if(event.button === 2){
        return;
      }
      if (event.button === 0 && isDragging.current && event.ctrlKey) {
        controls.noPan = true;
        controls.enablePan = false;
        // Update mouse vector with normalized device coordinates
        const rect = renderer.domElement.getBoundingClientRect();
        mouseRef.current.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        mouseRef.current.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
        // Cast ray and check for intersections with mesh
        raycaster.setFromCamera(mouseRef.current, camera);

        const intersects = raycaster.intersectObject(prepMesh,true);

    
        if (intersects.length > 0) {
          const point = intersects[0].point.clone();
          selectedPoints.current.push(point);
          updateTubeGeometry();
        }
      }
    };
    
    const onMouseUp = event => {
      try {

        if (!newSplinecurve || selectedPoints.current.length < 4 || event.button === 2) {
          return;
        }
    
        const newmaincurve = update(curveRef.current, newSplinecurve);
        if (!newmaincurve) return;
    
        const segments = createHigherOrderBezierSegments(newmaincurve,5);
        const curv = new THREE.CurvePath();
        segments.forEach((curve) => curv.add(curve));
        curveRef.current = curv
    
        // Remove old splines and add the updated one
        const orignalSplineobj = scene.getObjectByName('blackSpline');
    
        const newSpline = scene.getObjectByName('redSpline');
        if (newSpline) {
          removeMesh('redSpline');
        }
    
        const tube = new THREE.TubeGeometry(curv, 1000, 0.05, 1000, false);
        orignalSplineobj.geometry = tube;
        orignalSplineobj.geometry.needsUpdate = true;
        isDragging.current = false;
      } catch (error) {
        isDragging.current = false;
        console.error('Error updating spline:', error);
        controls.noPan = false;
        controls.enablePan = true;

      }
      finally{
        isDragging.current = false;
        controls.noPan = false;
        controls.enablePan = true;
      }
    };


      const createHigherOrderBezierSegments = (points, degree=5) => {
        const curves = [];
        for (let i = 0; i <= points.length - (degree + 1); i += degree) {
          const segmentPoints = points.slice(i, i + degree + 1);
          const curve = new HigherOrderBezierCurve(segmentPoints);
          curves.push(curve);
        }
        return curves;
      };

    

    if (threeJSManager && margin && !curveRef.current) {
      const pcdLoader = new PCDLoader();
      const pcdBlob = new Blob([margin], { type: 'application/octet-stream' });
      const pcdUrl = URL.createObjectURL(pcdBlob);
      
      pcdLoader.load(pcdUrl, (points) => {
        const positions = points.geometry.attributes.position.array;
    
        // Sample control points evenly
        const generateControlPoints = (positions, numControlPoints) => {
          // Convert flat position array into THREE.Vector3 objects
          const pointArray = [];
          for (let i = 0; i < positions.length; i += 3) {
              pointArray.push(new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2]));
          }
      
          // Ensure there are enough points
          if (pointArray.length <= numControlPoints) {
              console.warn('Insufficient points. Returning available points.');
              return pointArray.slice(); // Return all points if less than requested
          }
      
          // Calculate step size for even distribution
          const controlPoints = [];
          const stepSize = (pointArray.length - 1) / (numControlPoints - 1);
      
          for (let i = 0; i < numControlPoints; i++) {
              const index = i * stepSize;
              const lowIndex = Math.floor(index);
              const highIndex = Math.ceil(index);
      
              if (lowIndex === highIndex) {
                  controlPoints.push(pointArray[lowIndex]);
              } else {
                  // Interpolate between lowIndex and highIndex
                  const t = index - lowIndex;
                  const interpolated = pointArray[lowIndex].clone().lerp(pointArray[highIndex], t);
                  controlPoints.push(interpolated);
              }
          }
      
          return controlPoints;
      };
      
      const numControlPoints = 401;
      const controlPoints = generateControlPoints(positions, numControlPoints);
      // controlPoints = closedCurve(controlPoints, 400);

        // Generate cubic Bézier segments
        const bezierSegments = createHigherOrderBezierSegments(controlPoints,5);

    
        // Create a continuous geometry using CurvePath
        const fullCurve = new THREE.CurvePath();
        bezierSegments.forEach((curve) => fullCurve.add(curve));
    
        curveRef.current = fullCurve;
    
        // Generate TubeGeometry
        const tube = new THREE.TubeGeometry(fullCurve, 1000, marginRadius, 1000, false); // Set 'closed' to true
        const originalMesh = new THREE.Mesh(tube, splineMaterial);
        originalMesh.name = 'blackSpline';
    
        scene.add(originalMesh);

      });
    }
    
if(!marginTool){
      renderer.domElement.style.cursor = 'auto';
      

}
    if(marginTool){
      renderer.domElement.style.cursor = 'auto';

      const marginSpline=scene.getObjectByName('blackSpline');
      if(marginSpline){
        marginSpline.material.color.set(0x800080);
        marginSpline.material.emissive.set(0x800080); 
        marginSpline.material.transparent = true;
      }
      renderer.domElement.addEventListener('mousedown', onMouseDown);
    renderer.domElement.addEventListener('mousemove', onMouseMove);
    renderer.domElement.addEventListener('mouseup', onMouseUp);
  }

    return () => {
      if(marginTool){

        const marginSpline=scene.getObjectByName('blackSpline');
      if(marginSpline){
        marginSpline.material.color.set(0xff0000);
        marginSpline.material.emissive.set(0xff0000); 
        marginSpline.material.transparent = true;
      }

        renderer.domElement.removeEventListener('mousedown', onMouseDown);
      renderer.domElement.removeEventListener('mousemove', onMouseMove);
      renderer.domElement.removeEventListener('mouseup', onMouseUp);
}

    if(prepMesh){
      prepMesh.geometry.disposeBoundsTree();
    }
    }}
  }, [threeJSManager,scene,marginTool,margin]);

  useEffect(() => {
    if (loading) {
      const messages = [
        'Analyzing...',
        'Deep thinking...',
        'Designing Crown...',
        'Finalizing crown...',
      ];
  
      let messageIndex = 0;
      setTextMessage(messages[messageIndex]); // Set initial message
  
      const changeMessage = () => {
        if (messageIndex < messages.length - 1) {
          messageIndex += 1;
          setTextMessage(messages[messageIndex]);
        } else {
          clearInterval(intervalId); // Stop the interval when reaching the last message
        }
      };
  
      const intervalId = setInterval(changeMessage, 15000);
  
      return () => clearInterval(intervalId);
    }
  }, [loading]);
  

  
  const getNewMargin = () => {
    const numPoints = 1500;
    const points = [];
    const curveLength = curveRef.current.getLength();
    const segmentLength = curveLength / numPoints; 
    let currentLength = 0;
  
    for (let i = 0; i < numPoints; i++) {
      // Find the point at the desired arc length
      const t = curveRef.current.getUtoTmapping(currentLength / curveLength);
      const point = curveRef.current.getPoint(t);
      points.push(point);
      currentLength += segmentLength;
    }
  
    // Add the final point explicitly to close the curve
    points.push(curveRef.current.getPoint(1));
  
    // Create PCD header
    let header = `# .PCD v0.7 - Point Cloud Data\n`;
    header += `VERSION 0.7\n`;
    header += `FIELDS x y z\n`;
    header += `SIZE 4 4 4\n`;
    header += `TYPE F F F\n`;
    header += `COUNT 1 1 1\n`;
    header += `WIDTH ${points.length}\n`;
    header += `HEIGHT 1\n`;
    header += `VIEWPOINT 0 0 0 1 0 0 0\n`;
    header += `POINTS ${points.length}\n`;
    header += `DATA ascii\n`;
  
    // Create points data
    let pointsData = '';
    points.forEach((point) => {
      const x = point.x.toFixed(6);
      const y = point.y.toFixed(6);
      const z = point.z.toFixed(6);
      pointsData += `${x} ${y} ${z}\n`;
    });
  
    // Combine header and points data
    const pcdData = header + pointsData;
    return pcdData;
  };
  const removeMargins = () => {
    const marginSpline = scene.getObjectByName('blackSpline');
    if (marginSpline) {
      removeMesh('blackSpline');
    }
    const redSpline = scene.getObjectByName('redSpline');
    if (redSpline) {
      removeMesh('redSpline');
    }
    const poiMesh = scene.getObjectByName('poi');
    if (poiMesh) {
      removeMesh('poi');
    }
  }


  const capturePoint= () => {
      setPoi([selectedCoordinates.x, selectedCoordinates.y, selectedCoordinates.z]);
  }

  const prep_failed = async () => {
    setLoading(true);
  
    try {
      const formData = new FormData();
      formData.append('file1', file1);
      formData.append('category', JSON.stringify(selectedOption));
      formData.append('folder_id', JSON.stringify(uuid));
      formData.append('poi', JSON.stringify(poi));
  
      const response = await fetch('https://api.dentalai.ai/prep_failed/', {
        method: 'POST',
        body: formData,
      });
  
      if (response.status === 200) {
        // Get the response as a blob
        const responseBlob = await response.blob();
        setmargin(responseBlob);
        curveRef.current = null;
        removeMargins();
        setPoi(null);
        // Now you can use `blobURL` as the `margin` or any other place where needed
        setLoading(false);
        
      } else {
        setError(true);
        setLoading(false);
      }
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };
  
  

  const removeMesh = (name) => {
    const mesh = scene.getObjectByName(name);
    if (mesh) {
      if (mesh.geometry) mesh.geometry.dispose();
      if (mesh.material) mesh.material.dispose();
      scene.remove(mesh);
    }
  };
  const handleUndo = () => {
    if (undoSt.length > 0) {
      const lastCurvegeo = undoSt[undoSt.length - 1];
      const updatedUndoSt = undoSt.slice(0, undoSt.length - 1);
      setUndoSt(updatedUndoSt);
      const obj = scene.getObjectByName('blackSpline');
      if(obj){
        setRedoSt((prevRedo) => [...prevRedo, obj.geometry]);
        obj.geometry = lastCurvegeo;
        obj.geometry.needsUpdate = true;
        renderer.render(scene, camera); // Assuming `renderer` and `camera` are in scope
        const higherCirves = lastCurvegeo.parameters.path.curves
        const curv = new THREE.CurvePath();
        higherCirves.forEach((curve) => curv.add(curve));
        curveRef.current = curv;
      }
    }
  };
  // const handleRedo = () => {
  //   if (redoSt.length > 0) {
  //     const lastCurvegeo = redoSt[redoSt.length - 1];
  //     const updatedRedoSt = redoSt.slice(0, redoSt.length - 1);
  //     setRedoSt(updatedRedoSt);
  //     const obj = scene.getObjectByName('blackSpline');
  //     if(obj){
  //       setUndoSt((prevUndo) => [...prevUndo, obj.geometry]);
  //       obj.geometry = lastCurvegeo;

  //       obj.geometry.needsUpdate = true;
  //       renderer.render(scene, camera); // Assuming `renderer` and `camera` are in scope
  //       const higherCirves = lastCurvegeo.parameters.path.curves
  //       const curv = new THREE.CurvePath();
  //       higherCirves.forEach((curve) => curv.add(curve));
  //       curveRef.current = curv;
  //     }
  //   }
  // }

  const handleToggleVisibility = (index) => {
    if (threeJSManager) {
      const updatedSliderData = [...sliderData];
    updatedSliderData[index].visible = !updatedSliderData[index].visible;
    setSliderData(updatedSliderData);
      threeJSManager.handleToggleVisibilityClick(index);
    }
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };
 const showPrep = ()=>{
  setPrepview(!prepview);
  setMarginTool(false);
 }
 const toggleMarginTool = () => {
  setMarginTool(!marginTool);
  setPrepview(false);
 }

 const handleGoBack= () => {
  navigate("/select")
}

  const anatomicalCrown = async () => {
    const pcdData = getNewMargin();
    const MarginPCD = new Blob([pcdData], { type: 'text/plain' });
    const userToken = sessionStorage.getItem('token');
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('file1', file1);
      formData.append('file2', file2);
      formData.append('margin', MarginPCD);
      formData.append("category", JSON.stringify(selectedOption))
      formData.append('folder_id', JSON.stringify(uuid));
      formData.append('upper_lower', JSON.stringify(upper_lower));
      formData.append('numbering_system', JSON.stringify( numberingSystem));
      
      formData.append('tooth_number', JSON.stringify(prepNumber));
  
      const response = await fetch('http://34.71.126.92:4000/gen_crown_anatomical/', {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Token ${userToken}`,  // Ensure userToken contains the correct token
        },
      });
  
      if (response.ok) {
        const contentType = response.headers.get('content-type');
  
        if (contentType === 'application/json') {
          // Parse the JSON response
          const responseData = await response.json();
  
          // Extract data from the JSON response
          const {
            zip_file,
            margin_center: marginCenter,
            axis,
            thickness: thicknessData,
            allowed_points,
            inner_surface,
            thickness_allowed_points,
            crown_axis

          } = responseData;
  
          const thickness = thicknessData === true;
          const crownFirst = true;
  
          // Convert base64 zip data to a Blob
          const zipBlob = new Blob([Uint8Array.from(atob(zip_file), c => c.charCodeAt(0))], { type: 'application/zip' });
  
          // Process the ZIP blob to create separate File objects for each mesh
          const zip = await JSZip.loadAsync(zipBlob);
  
          const meshFiles = [];
          const promises = [];
  
          zip.forEach((relativePath, file) => {
            if (file.dir) return; // Ignore directories
            const promise = file.async('uint8array').then((data) => {
              const meshBlob = new Blob([data], { type: 'application/octet-stream' });
              const mesh = new File([meshBlob], file.name, { type: 'application/octet-stream' });
              meshFiles.push(mesh);
            });
            promises.push(promise);
          });
  
          // Wait for all promises to resolve
          await Promise.all(promises);
  
          navigate('/crown', {
            state: {
              file1,
              file2,
              crown: meshFiles,
              marginCurve: MarginPCD ,
              uuid_crown:uuid,
              crownFirst,
              marginCenter,
              allowed_points,
              axis,
              thickness,
              position: initialCameraPosition,
              selectedOption,
              prepView: sliderData[0].visible,
              antaView: sliderData[1].visible,
              inner_surface,
              thickness_allowed_points,
              crown_axis,
              prepNumber,
              selectedOption,
              numberingSystem
            }
          });
  
          try {
            const formData = new FormData();
            formData.append('prep', file1);
            formData.append('anta', file2);
            formData.append("crown", meshFiles[0])
            formData.append("category", JSON.stringify(selectedOption))
            
            const token = sessionStorage.getItem('token');
      
            const response1 = await fetch('https://api.dentalai.ai/saves3/', {
              method: 'POST',
              body: formData,
              headers: {
                'Authorization': `Token ${token}`
              }
            });
      
            if (response1.ok) {
              console.log('Mesh Saved successfully');
            }
          }
          catch (error) {
            console.error('Error adding mesh:', error.message);
          }
        } else {
          setError(true);
          setLoading(false);
        }
      }
      else {
        setError(true);
        setLoading(false);
      }
    } catch (error) {
      setError(true);
      setLoading(false);
      console.error('Error generating crown:', error);
    }
    
  }


  const prepselection = (
    <div
      className="p-3 text-center rounded-4"
      style={{
        display: prepview ? 'block' : 'none',
        background: "rgba(255, 255, 255, 1)",
        fontSize: "16px",
              fontWeight: "500",
      }}
    >
      {/* Header Row */}
      <div
        className="mb-1 d-flex justify-content-between align-items-center"
        style={{
          color: 'black',
          fontFamily: "Manrope, sans-serif",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        <div>Prep Properties</div>
        <div onClick={showPrep}><Delete /></div>
      </div>
  
      <ul className="list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3" style={{ background: "rgba(144, 200, 224, 0.3)" }}>
        {/* Prep point handling */}
        <div className="d-flex justify-content-between">
          <div className="mx-3 ">Prep point</div>
          {(!poi) ? (
            <div className="mx-3" onClick={capturePoint}>
              <MySVG10 />
            </div>
          ) : (
            <div className="mx-3" onClick={() => {
              removeMesh('poi');
              setPoi(null);
            }}>
              <MySVG5 />
            </div>
          )}
        </div>
      </ul>
  
      <button
        className="btn btn-primary rounded fw-bold my-2"
        style={{
          backgroundColor: primarycolor,
              color: "#ffffff",
              fontWeight: "bolder",
              fontFamily: "Manrope, sans-serif",
              fontSize: "14px",
              border: "none",
              padding: "8px 12px",
              cursor: "pointer",
              transition: "background-color 0.3s ease, color 0.3s ease",
        }}
        onClick={prep_failed}
      >
        Generate margin
      </button>
    </div>
  );
  
  const marginView = (
    <>
      <div
          className="p-3 text-center rounded-4"
          style={{
            display: marginTool ? 'block' : 'none',
            background: "rgba(255, 255, 255, 1)",
          }}
        >
          {/* Header Row */}
          <div
            className="mb-1 d-flex justify-content-between align-items-center"
            style={{
              color: 'black',
              fontFamily: "Manrope, sans-serif",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
          <div>Margin</div>
          <div onClick={()=>{
            setMarginTool(false)

          }}><Delete /></div>
        </div>
        <button
                    className='btn btn-primary mx-2 fs-5'
                    style={{
                      backgroundColor: undoSt.length > 0 ? "rgba(144, 200, 224, 0.9)" : "#808080",
                      cursor: undoSt.length > 0 ? "pointer" : "not-allowed",
                    }}
                    onClick={handleUndo}
                  >
                    <Undo />
                  </button>
                  {/* <button
                    className='btn btn-primary fs-5 mx-2 '
                    style={{
                      backgroundColor: redoSt.length > 0 ? "rgba(144, 200, 224, 0.9)" : "#808080",
                      cursor: redoSt.length > 0 ? "pointer" : "not-allowed",
                    }}
                    onClick={handleRedo}
                  >
                    <Redo />
                  </button> */}
      </div>
    </>
  )

  const adjustment = (
    <div className="p-3 text-center rounded-4" style={{
      zIndex: "2", 
      background: "rgba(144, 200, 224, 0.3)", 
      height:"94vh", 
      boxShadow: "0 2px 4px rgba(144, 200, 224, 0.4)",
    }}>
  
      <div className="d-flex flex-column align-items-center justify-content-center p-1 rounded-3" style={{
        cursor: 'pointer', 
        transition: "transform 1s ease"
      }}>
      </div>
      
      {/* Move prepview && prepselection inside the adjustment panel */}
  
      {Adjustmentpanel && (
  <>
     <div
  className="d-flex flex-row align-items-center justify-content-center p-2 rounded-3"
  style={{ background: "rgba(255, 255, 255, 1)" }}
>
  {/* First Row */}
  <div className="row justify-content-center">
    {/* Model 0 */}
    <div className="col-auto d-flex flex-column align-items-center mx-2">
        <div
          className="rounded-3 d-flex justify-content-center align-items-center"
          id={"toggleVisibilityButton-0"}
          style={{
            width: "40px",
            height: "40px",
            border: "1px solid #1F555A",
            backgroundColor: sliderData[0].visible? "rgba(144, 200, 224, 0.9)" :"#ffffff",
            cursor: "pointer",
          }}
          onClick={() => handleToggleVisibility(0)}
        >
          <PrepIcon />
          {/* {sliderData[0].visible ? <MySVG1 /> : <MySVG8 />} */}
        </div>
      <div className="fw-light" style={{ fontSize: '12px' }}>{sliderData[0].name}</div>

        
    </div>

    {/* Model 1 */}
    <div className="col-auto d-flex flex-column align-items-center mx-2">
        <div
          className="rounded-3 d-flex justify-content-center align-items-center"
          id={"toggleVisibilityButton-1"}
          style={{
            width: "40px",
            height: "40px",
            border: "1px solid #1F555A",
            cursor: "pointer",
            backgroundColor: sliderData[1].visible? "rgba(144, 200, 224, 0.9)" :"#ffffff",

            
          }}
          onClick={() => handleToggleVisibility(1)}
        >
          <AntaIcon />
          {/* {sliderData[1].visible ? <MySVG9 /> : <MySVG7 />} */}
        </div>
    <div className="fw-light" style={{ fontSize: '12px' }}>{sliderData[1].name}</div>

    </div>
  </div>
</div>

    <hr style={{ width: "100%", border: "1px solid #000" }} />


    {/* Prep and Margin Tool Panel */}

    <div
  className="d-flex flex-row align-items-center justify-content-center p-2 rounded-3"
  style={{ background: "rgba(255, 255, 255, 1)" }}
>
  {/* First Row */}
  <div className="row justify-content-center">
    {/* Model 0 */}
    <div className="col-auto d-flex flex-column align-items-center mx-2">
        <div
          className="rounded-3 d-flex justify-content-center align-items-center"
          onClick={showPrep}
          style={{
            cursor: "pointer",

            border: "1px solid #1F555A",
            height: "40px",
            width: "40px",

            backgroundColor: prepview ? "rgba(144, 200, 224, 0.9)" : "#ffffff",
          }}
        >
            <Toolbox style={{ width: '20px', height: '20px' }} />
        </div>
      <div className="fw-light" style={{ fontSize: '12px' }}>Prep panel</div>

        
    </div>

    {/* Model 1 */}
    <div className="col-auto d-flex flex-column align-items-center mx-2">
        <div
          className="rounded-3 d-flex justify-content-center align-items-center"
            onClick={toggleMarginTool}
            style={{
              border: "1px solid #1F555A",
            cursor: "pointer",

              height: "40px",
              width: "40px",
              backgroundColor: marginTool ? "rgba(144, 200, 224, 0.9)" : "#ffffff",
            }}
        >
<Margin style={{ width: '20px', height: '20px' }} />
        </div>
    <div className="fw-light" style={{ fontSize: '12px' }}>Margin</div>

    </div>
  </div>
</div>

    {/* Prep and Margin Tool Panel */}
    

    <hr style={{ width: "100%", border: "1px solid #000" }} />
    {prepselection}
    {marginView}

  </>
)}

    </div>
  );
  
  


  
  

  

  return (
    <div className="d-flex " style={{ overflow: 'hidden', backgroundColor: "#ffffff",fontFamily: 'Manrope, sans-serif',fontWeight:"bold" }}>
      
      {loading && (
  <div style={{
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '9999',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
    width: "50vw",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
    animation: "fadeIn 0.5s ease-in-out"
  }}>
    <div className="mb-3">
      {/* Wand Icon with Smooth Scaling Animation */}
      <div className="d-flex align-items-center justify-content-center">
        <Wand style={{
          fontSize: "24px",
          marginRight: "10px",
          animation: "pulse 1.5s infinite ease-in-out"
        }} />
      </div>
      <p className="mt-3">
        <strong> {TextMessage} </strong>
      </p>
    </div>

    {/* Inline Styles for Smooth Animations */}
    <style>
      {`
        @keyframes fadeIn {
          from { opacity: 0; transform: translate(-50%, -55%); }
          to { opacity: 1; transform: translate(-50%, -50%); }
        }
        @keyframes pulse {
          0% { transform: scale(1); }
          50% { transform: scale(1.3); }
          100% { transform: scale(1); }
        }
      `}
    </style>
  </div>
)}





<div className='flex-1' style={{position: 'relative', width: '20vw', zIndex: '2' }}>
        {adjustment}
      </div>
      <div className="flex-1 " style={{ position: 'relative' }}>
      <div id="canvas-container" ref={containerRef} style={{ position: 'relative',width:"80vw" }}>
          {showAlert && (
            <div className="alert alert-danger alert-dismissible fade show" role="alert" style={{ position: 'absolute', top: '10px', left: '10px', zIndex: 9999,width:"72vw" }}>
             Your prep selection is incomplete, either select a point or remove the last prep point before proceeding.
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleAlertClose}></button>
            </div>
          )}
          {error && (
            <div className="alert alert-danger alert-dismissible fade show" role="alert" style={{ position: 'absolute', top: '10px', left: '10px', zIndex: 9999,width:"72vw" }}>
              Some Error Occured!
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleAlertClose}></button>
            </div>
          )}

          
        </div>
      </div>

      <div
        className="position-absolute"
        style={{
          right: "15%",
          bottom: "2%",
        }}
      >
        <button
          className="btn"
          style={{
            backgroundColor: "rgba(144, 200, 224, 0.9)",
            fontWeight: "bolder",
            fontFamily: "Manrope, sans-serif",
            fontSize: "14px",
            padding: "10px 15px",
            cursor: "pointer",
            transition: "background-color 0.3s ease, color 0.3s ease",
          }}
          onClick={handleGoBack}
        >
         <Left/> Back
        </button>
      </div>

      <div
        className="position-absolute"
        style={{
          right: "2%",
          bottom: "2%",
        }}
      >
        <button
          className="btn"
          style={{
            backgroundColor: "rgba(144, 200, 224, 0.9)",
            fontWeight: "bolder",
            fontFamily: "Manrope, sans-serif",
            fontSize: "14px",
            padding: "10px 15px",
            cursor: "pointer",
            transition: "background-color 0.3s ease, color 0.3s ease",
          }}
          onClick={anatomicalCrown}
        >
          Generate Crown <Right/>
        </button>
      </div>

      {/* <div className='position-absolute ' style={{left: '5%',
      bottom: '3%'}}>
          
      
      </div> */}
    </div>
  );
};

export default Prepmodule;
