import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, Checkbox, Divider, FormControl, Grid, Typography } from '@mui/material';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import {ReactComponent as Info} from '../assets/info.svg';
import {ReactComponent as Upload} from '../assets/upload.svg';
import {ReactComponent as PrepIcon} from '../assets/Prep.svg';
import {ReactComponent as CrownIcon} from '../assets/Magic.svg';
import {ReactComponent as ExportIcon} from '../assets/Export.svg';

function Export() {
    const location = useLocation();
    const {file1, file2, crown,marginCurve,uuid_crown,crownFirst, marginCenter,allowed_points ,thickness, position, axis, prepView, antaView, inner_surface,exportelements,thickness_allowed_points,crown_axis,prepNumber,
        selectedOption, numberingSystem} = location.state || {};
    const navigate = useNavigate();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [credits, setCredits] = useState(0);
    
    
    const handleCheckboxChange = (event, fileName) => {
        if (event.target.checked) {
            setSelectedFiles([...selectedFiles, fileName]);
        } else {
            setSelectedFiles(selectedFiles.filter(file => file !== fileName));
        }
    };
    useEffect(() => {
        get_credits();
    }, []);


    const get_credits = async () => {
        const authToken = sessionStorage.getItem('token');
        try {
            const response = await fetch('https://api.dentalai.ai/get_credits/', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${authToken}`,
              },
            });
      
            
      
            if (response.status === 200) {
                const data = await response.json();
                setCredits(data.credits);
            } else {
              
             
              
            }
          } catch (error) {
            
            
          }
    }
    
    const handleDownload = async () => {

        try {
            const authToken = sessionStorage.getItem('token');
            const response = await fetch('https://api.dentalai.ai/deduct_credits/', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${authToken}`,
              },
              body: JSON.stringify({ "used_credits": selectedFiles.length }),
            });
      
            
      
            if (response.status === 200) {
                const selectedFilesData = exportelements.filter(file => selectedFiles.includes(file.name));
        const zip = new JSZip();
    
        // Add each selected file to the ZIP archive
        selectedFilesData.forEach((file, index) => {
            zip.file(`crown_${index + 1}.stl`, file);
        });
    
        // Generate the ZIP file
        zip.generateAsync({ type: 'blob' }).then((blob) => {
            // Create a URL for the blob
            const url = URL.createObjectURL(blob);
            // Create a link element
            const a = document.createElement('a');
            // Set the href attribute of the link to the URL
            a.href = url;
            // Set the download attribute to specify the file name
            a.download = 'crown_files.zip';
            // Append the link to the document body
            document.body.appendChild(a);
            // Simulate a click on the link to trigger the download
            a.click();
            // Remove the link from the document body
            document.body.removeChild(a);
            // Revoke the URL to free up resources
            URL.revokeObjectURL(url);
            get_credits();
        });
            } else {
              
             
              
            }
          } catch (error) {
            
            
            
          }
        
    };
    

      
    
      return (
        <div>

            <div style={{ display: "fixed", top: "50%", left: "50%", fontFamily: 'Manrope, sans-serif' }}>
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', background: "rgba(255, 255, 255, 0.3)" }}>
                    <div className="card" style={{ width: '50%', maxWidth: '50vw', borderRadius: '8px', boxShadow: "0 0 4px 4px rgba(144, 200, 224, 0.4)", display: 'flex', flexDirection: "column" }}>
                        <h5 className="card-title fw-bold fs-4 px-4 py-4 mt-2 mb-0 font-weight-bold">
                            Export Crown
                        </h5>
                        <hr className="m-0" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} />
    
                        <div className="d-flex px-4 justify-content-between align-items-center mt-4 mb-4 px-2">
                            <div>
                                <h6>Crown Left:</h6>
                                <div className="d-flex align-items-center">
                                    <Typography style={{ color: "#E91E63" }}>{selectedFiles.length}</Typography>
                                    <span>/</span>
                                    <span className="body1">{credits}</span>
                                </div>
                                { selectedFiles.length>credits &&(<div>
                                <Typography style={{ color: "#E91E63" }}>You don't have enough credits</Typography>
                                </div>)}
                            </div>
                            <div>
                                <button className='btn btn-primary'
                                    style={{
                                        display:"none",
                                        backgroundColor: "#213F99",
                                        margin: '10px',
                                        fontFamily: 'Manrope, sans-serif',
                                        width: '100%'
                                    }}>Purchase Credits</button>
                            </div>
                        </div>
    
                        <div className=" mb-4 px-4">
                            <p>{`Select Crown(s)  (${selectedFiles.length} out of ${exportelements.length})`}</p>
                            {exportelements.map((file, index) => (
                                <div className="d-flex align-items-center mb-1" key={file.name}>
                                    <div className="col-1 ">
                                    <input
    type="checkbox"
    checked={selectedFiles.includes(file.name)}
    onChange={(e) => handleCheckboxChange(e, file.name)}
    className="form-check-input"
    style={{ backgroundColor: selectedFiles.includes(file.name) ? '#213F99' : 'initial' }}
/>

                                    </div>
                                    <div className="col-11 fw-bold">
                                        <p className="m-0">Crown {index + 1}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
    
                        <div className="d-flex flex-row pt-2 ps-2 pe-2">
                            <div>
                                <Info />
                            </div>
                            <p>
                                When exporting multiple crowns, they will be conveniently bundled into a compressed zip file for easy downloading. Simply unzip the file to access your individual crown files.
                            </p>
                        </div>
    
                        <div className="d-flex justify-content-center p-3" style={{ width: "100%" }}>
    <div style={{ flex: "1" }}>
        <button className='btn btn-outline p-3' style={{ color: "#213F99", fontFamily: 'Manrope, sans-serif', width: '100%' }} onClick={() => { navigate("/crown", { state: { file1, file2, crown,marginCurve,uuid_crown,crownFirst, marginCenter,allowed_points ,thickness, position, axis, prepView, antaView, inner_surface,crown:exportelements,thickness_allowed_points,crown_axis,prepNumber,
    selectedOption, numberingSystem } }) }}>Close</button>
    </div>
    <div style={{ flex: "1" }}>
        <button className='btn btn-primary p-3' style={{ backgroundColor: "#213F99", fontFamily: 'Manrope, sans-serif', width: '100%' }} onClick={handleDownload}>Export <Upload /></button>
    </div>
</div>

                    </div>
                </div>
            </div>
        </div>
    );
    
    
}

export default Export;
